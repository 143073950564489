import tw from 'twin.macro';

import { Input } from '../base';
import { FormElementProps } from './types';

interface TextFieldProps extends FormElementProps<HTMLInputElement> {
  description?: React.ReactNode | string;
}

const TextField: React.FC<TextFieldProps> = (props) => {
  const { label, name, description, register, validations, type, ...textFieldProps } = props;
  const { ref, ...rest } = register?.(name, { ...validations }) ?? {};

  return (
    <label htmlFor={name} tw="space-y-1">
      <span tw="font-semibold">{label}</span>
      {description ? (
        <div
          css={[
            tw`text-sm text-gray-600`,
            type === 'file' && tw`text-blue-600 duration-100 hover:text-blue-800`,
          ]}
          dangerouslySetInnerHTML={{ __html: description as string }}
        />
      ) : null}
      <Input type={type} id={name} {...textFieldProps} {...rest} ref={ref} />
    </label>
  );
};

export default TextField;
