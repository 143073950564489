import { useEffect, useRef, useState } from 'react';

export enum StatusMessages {
  IDLE = 'idle',
  INIT_FAILED = 'initializeMediaStream failed',
  INITIALIZING = 'initializing',
  PERMISSION_DENIED = 'permission denied',
  RECORDING = 'recording',
  STOPPING = 'stopping',
  STOPPED = 'stopped',
  RECORDING_FAILED = 'recording failed',
}

type useMediaRecorderProps = {
  audio?: boolean | MediaTrackConstraints;
  video?: boolean | MediaTrackConstraints;
  blobPropertyBag?: BlobPropertyBag;
  onStop?: (blob: Blob, company: string, sessionId: string, fileName?: string) => void;
  mediaRecorderOptions?: MediaRecorderOptions | undefined;
  stopStreamsOnStop?: boolean;
  askPermissionOnMount?: boolean;
  uploadProperty?: { company: string; sessionId: string; fileName?: string };
  audioInputDeviceId?: string;
  videoInputDeviceId?: string;
};

export const useMediaRecorder = (props: useMediaRecorderProps) => {
  const {
    audio = true,
    video = true,
    onStop = () => null,
    blobPropertyBag,
    mediaRecorderOptions = undefined,
    stopStreamsOnStop = true,
    askPermissionOnMount = false,
    uploadProperty,
    audioInputDeviceId,
    videoInputDeviceId,
  } = props;

  const mediaChunks = useRef<Blob[]>([]);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const [mediaBlobUrl, setMediaBlobUrl] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<StatusMessages>();

  const initializeMediaStream = async () => {
    setStatus(StatusMessages.INITIALIZING);
    try {
      const stream = await window.navigator.mediaDevices.getUserMedia({
        video: {
          width: 480,
          height: 360,
          deviceId: videoInputDeviceId ? { exact: videoInputDeviceId } : undefined,
        },
        audio: {
          deviceId: audioInputDeviceId ? { exact: audioInputDeviceId } : undefined,
        },
      });
      mediaStreamRef.current = stream;
      setStatus(StatusMessages.IDLE);
    } catch (error) {
      console.error(error);
      setStatus(StatusMessages.INIT_FAILED);
    }
  };

  useEffect(() => {
    if (!window.MediaRecorder) {
      throw new Error('Unsupported browser');
    }

    const checkConstraints = (mediaType: MediaTrackConstraints) => {
      const supportedMediaConstraints = navigator.mediaDevices.getSupportedConstraints();
      const unSupportedConstraints = Object.keys(mediaType).filter(
        (constraint) => !(supportedMediaConstraints as { [key: string]: any })[constraint],
      );

      if (unSupportedConstraints.length > 0) {
        console.error(
          `The constraints ${unSupportedConstraints.join(
            ',',
          )} doesn't support on this browser. Please check your ReactMediaRecorder component.`,
        );
      }
    };

    if (typeof audio === 'object') {
      checkConstraints(audio);
    }
    if (typeof video === 'object') {
      checkConstraints(video);
    }

    if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
      if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
        console.error(
          `The specified MIME type you supplied for MediaRecorder doesn't support this browser`,
        );
      }
    }

    if (!mediaStreamRef.current && askPermissionOnMount) {
      initializeMediaStream();
    }

    return () => {
      if (mediaStreamRef.current) {
        const tracks = mediaStreamRef.current.getTracks();
        tracks.forEach((track) => track.clone().stop());
      }
    };
  }, [audio, screen, video, initializeMediaStream, mediaRecorderOptions, askPermissionOnMount]);

  const startRecording = async () => {
    if (!mediaStreamRef.current) {
      await initializeMediaStream();
    }
    if (mediaStreamRef.current) {
      const isStreamEnded = mediaStreamRef.current
        .getTracks()
        .some((track) => track.readyState === 'ended');
      if (isStreamEnded) {
        await initializeMediaStream();
      }

      // User blocked the permissions (getMediaStream errored out)
      if (!mediaStreamRef.current.active) {
        return;
      }
      mediaRecorderRef.current = new MediaRecorder(
        mediaStreamRef.current,
        mediaRecorderOptions || undefined,
      );
      mediaRecorderRef.current.ondataavailable = onRecordingActive;
      mediaRecorderRef.current.onstop = onRecordingStop;
      mediaRecorderRef.current.onerror = () => {
        setStatus(StatusMessages.RECORDING_FAILED);
      };
      mediaRecorderRef?.current?.start();
      setStatus(StatusMessages.RECORDING);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      if (mediaRecorderRef.current.state !== 'inactive') {
        setStatus(StatusMessages.STOPPING);
        mediaRecorderRef.current.stop();
        if (stopStreamsOnStop) {
          mediaStreamRef.current &&
            mediaStreamRef.current.getTracks().forEach((track: MediaStreamTrack) => track.stop());
        }
        mediaChunks.current = [];
      }
    }
  };

  const onRecordingActive = ({ data }: BlobEvent) => {
    mediaChunks.current.push(data);
  };

  const onRecordingStop = () => {
    const [chunk] = mediaChunks.current;
    const blobProperty: BlobPropertyBag = Object.assign(
      { type: chunk.type },
      blobPropertyBag || (video ? { type: 'video/mp4' } : { type: 'audio/wav' }),
    );
    const blob = new Blob(mediaChunks.current, blobProperty);
    console.log('blob size', blob.size);
    if (blob.size === 0) {
      setStatus(StatusMessages.RECORDING_FAILED);
      return;
    }

    const url = URL.createObjectURL(blob);
    setStatus(StatusMessages.STOPPED);
    setMediaBlobUrl(url);
    if (!uploadProperty || Object.keys(uploadProperty).length === 0) {
      return;
    } else {
      const { company, sessionId, fileName } = uploadProperty;
      onStop?.(blob, company, sessionId, fileName);
    }
  };

  const clearBlobUrl = async () => {
    if (mediaBlobUrl) {
      URL.revokeObjectURL(mediaBlobUrl);
    }
    setMediaBlobUrl(undefined);
    setStatus(StatusMessages.IDLE);
    await initializeMediaStream();
  };

  return {
    initializeMediaStream,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    status,
    previewStream: mediaStreamRef.current
      ? new MediaStream(mediaStreamRef.current.getVideoTracks())
      : null,
    clearBlobUrl,
  };
};
