import tw from 'twin.macro';

const IntervieweeCardSkeleton = () => {
  return (
    <div tw="flex w-full animate-pulse gap-5 rounded-2xl border bg-white p-4 sm:w-fit">
      <div tw="hidden rounded-full bg-slate-100 sm:(block h-20 w-20)" />
      <div>
        <div tw="flex flex-col gap-2">
          <div tw="h-6 w-3/4 rounded-lg bg-slate-100" />
          <div tw="h-4 w-1/3 rounded-lg bg-slate-100" />
        </div>
        <div tw="mt-7 flex flex-col gap-2 sm:(flex-row gap-6)">
          <div tw="flex w-28 flex-col gap-2">
            <div tw="h-4 w-full rounded-lg bg-slate-100" />
            <div tw="h-4 w-full rounded-lg bg-slate-100" />
          </div>
          <div tw="flex w-32 flex-col gap-2">
            <div tw="h-4 w-full rounded-lg bg-slate-100" />
            <div tw="h-4 w-full rounded-lg bg-slate-100" />
          </div>
          <div tw="flex w-36 flex-col gap-2">
            <div tw="h-4 w-full rounded-lg bg-slate-100" />
            <div tw="h-4 w-full rounded-lg bg-slate-100" />
          </div>
        </div>
        <div tw="mt-4 w-full space-y-1">
          <div tw="h-6 w-1/3 rounded-lg bg-slate-100" />
          <div tw="h-[42px] w-full rounded-lg bg-slate-100" />
        </div>
        <div tw="mt-4 w-full space-y-1">
          <div tw="h-6 w-1/3 rounded-lg bg-slate-100" />
          <div tw="h-[42px] w-full rounded-lg bg-slate-100" />
        </div>
      </div>
    </div>
  );
};

export default IntervieweeCardSkeleton;
