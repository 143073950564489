type SingleInterviewFormValues = {
  intervieweeName: string;
  intervieweeEmail: string;
  position: string;
};

type BatchInterviewFormValues = {
  intervieweeDetails: string;
};

export type FormValues = {
  questionTemplateID: string;
  duration: number;
  expiredAt: Date;
  shouldSendEmailToInterviewee: boolean;
} & SingleInterviewFormValues &
  BatchInterviewFormValues;

export enum FormInputType {
  TEXT = 'text',
  SELECT = 'select',
  NUMBER = 'number',
  DATE = 'date',
  TEXTAREA = 'textarea',
  FILE = 'file',
  CHECKBOX = 'checkbox',
}

export enum CreateSessionMode {
  SINGLE = 'single',
  BATCH = 'batch',
}

export enum ModalState {
  CONFIRM_SINGLE = 'confirm_single',
  CONFIRM_BATCH = 'confirm_batch',
  SUCCESS_SINGLE = 'success_single',
  SUCCESS_BATCH = 'success_batch',
  ERROR_SINGLE = 'error_single',
}
