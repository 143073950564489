import {
  GoogleAuthProvider,
  fetchSignInMethodsForEmail as fetchSignInMethodsForEmailFromFirebase,
  getAuth,
  signInWithEmailAndPassword as signInWithEmailAndPasswordFromFirebase,
  signInWithCustomToken as signInWithFirebaseCustomToken,
  signInWithPopup,
  signOut as signOutFromFirebase,
} from 'firebase/auth';

import firebaseApp from './firebase';

const auth = getAuth(firebaseApp);

const signInWithGoogle = async () => await signInWithPopup(auth, new GoogleAuthProvider());

const signInWithCustomToken = async (token: string) =>
  await signInWithFirebaseCustomToken(auth, token);

const signOut = async () => await signOutFromFirebase(auth);

const signInWithEmailAndPassword = async (email: string, password: string) => {
  await signInWithEmailAndPasswordFromFirebase(auth, email, password);
};

const fetchSignInMethodsForEmail = async (email: string) => {
  return await fetchSignInMethodsForEmailFromFirebase(auth, email);
};

export {
  fetchSignInMethodsForEmail,
  signInWithGoogle,
  signInWithEmailAndPassword,
  signOut,
  signInWithCustomToken,
};
export default auth;
