import tw from 'twin.macro';

import { Button as BaseButton } from '@/components/base';
import { VideoUploadStatus } from '@/libs/graphql';
import { StatusMessages } from '@/utils/useMediaRecorder';
import throttle from 'lodash.throttle';
import { useCallback } from 'react';

interface ThrottleButtonProps {
  throttleTime?: number; 
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: 'primary' | 'error' | 'info' | 'success' | 'outlined';
  disabled?: boolean;
  children: React.ReactNode;
}

const Button: React.FC<ThrottleButtonProps> = ({ throttleTime = 200, onClick, ...props }) => {
  const handleClick = useCallback(
    throttle((event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }
    }, throttleTime),
    [onClick, throttleTime]
  );

  return (
    <BaseButton onClick={handleClick} {...props}>
      {props.children}
    </BaseButton>
  );
};


type SessionButtonProps = {
  initializeMediaStream?: () => Promise<void>;
  nextQuestion: () => void;
  status?: StatusMessages;
  startRecording: () => Promise<void>;
  stopRecording: () => void;
  isLastQuestion: boolean;
  endSession: () => void;
  disabled: boolean;
  videoUploadStatus: VideoUploadStatus;
};

enum INTERVIEW_QUESTION_STATE {
  QUESTION_PREPARATION = 'QUESTION_PREPARATION',
  QUESTION_RECORDING = 'QUESTION_RECORDING',
  QUESTION_UPLOADING = 'QUESTION_UPLOADING',
  QUESTION_UPLOADED = 'QUESTION_UPLOADED',
  ERROR = 'ERROR',
  INITIALIZING = 'INITIALIZING',
}

const getInterviewState = (
  status: StatusMessages | undefined,
  videoUploadStatus: VideoUploadStatus,
) => {
  if (status === undefined || status === StatusMessages.INITIALIZING) {
    return INTERVIEW_QUESTION_STATE.INITIALIZING;
  }
  if (
    [
      StatusMessages.INIT_FAILED,
      StatusMessages.PERMISSION_DENIED,
      StatusMessages.RECORDING_FAILED,
    ].includes(status)
  ) {
    return INTERVIEW_QUESTION_STATE.ERROR;
  }
  if (status === StatusMessages.RECORDING) {
    return INTERVIEW_QUESTION_STATE.QUESTION_RECORDING;
  }
  if ([StatusMessages.STOPPED, StatusMessages.STOPPING].includes(status)) {
    if (status === StatusMessages.STOPPING || videoUploadStatus === VideoUploadStatus.Uploading) {
      return INTERVIEW_QUESTION_STATE.QUESTION_UPLOADING;
    }
    if (videoUploadStatus === VideoUploadStatus.Uploaded) {
      return INTERVIEW_QUESTION_STATE.QUESTION_UPLOADED;
    }
  }
  if (status === StatusMessages.IDLE) {
    return INTERVIEW_QUESTION_STATE.QUESTION_PREPARATION;
  }
  return INTERVIEW_QUESTION_STATE.INITIALIZING;
};

const SessionButton: React.FC<SessionButtonProps> = (props) => {
  const {
    initializeMediaStream,
    nextQuestion,
    startRecording,
    status,
    stopRecording,
    isLastQuestion,
    endSession,
    disabled,
    videoUploadStatus,
  } = props;

  const questionStatus = getInterviewState(status, videoUploadStatus);

  const shouldDisableButton =
    disabled ||
    [INTERVIEW_QUESTION_STATE.INITIALIZING, INTERVIEW_QUESTION_STATE.QUESTION_UPLOADING].includes(
      questionStatus,
    );

  switch (questionStatus) {
    case INTERVIEW_QUESTION_STATE.QUESTION_UPLOADING:
      return (
        <Button variant="primary" onClick={nextQuestion} disabled={shouldDisableButton}>
          กำลังบันทึกวิดีโอ
        </Button>
      );

    case INTERVIEW_QUESTION_STATE.QUESTION_UPLOADED: {
      if (isLastQuestion) {
        return (
          <Button variant="primary" onClick={endSession} disabled={shouldDisableButton}>
            จบการสัมภาษณ์
          </Button>
        );
      }
      return (
        <Button variant="primary" onClick={nextQuestion} disabled={shouldDisableButton}>
          เริ่มคำถามถัดไป
        </Button>
      );
    }

    case INTERVIEW_QUESTION_STATE.QUESTION_PREPARATION:
      return (
        <Button variant="primary" onClick={startRecording} disabled={shouldDisableButton}>
          เริ่มตอบคำถาม
        </Button>
      );

    case INTERVIEW_QUESTION_STATE.QUESTION_RECORDING:
      return (
        <Button variant="error" onClick={stopRecording} disabled={status === StatusMessages.STOPPING}>
          จบการตอบคำถาม
        </Button>
      );

    case INTERVIEW_QUESTION_STATE.ERROR:
      return (
        <Button variant="error" onClick={initializeMediaStream}>
          ลองใหม่อีกครั้ง
        </Button>
      );

    default:
      return (
        <Button variant="primary" disabled>
          กำลังเตรียมคำถาม
        </Button>
      );
  }
};

export default SessionButton;
