import { RouterProvider } from 'react-router-dom';

import AppProvider from '@/providers';

import TailwindIndicator from './components/TailwindIndicator';
import router from './routes';

const App = () => (
  <AppProvider>
    <RouterProvider router={router} />
    <TailwindIndicator />
  </AppProvider>
);

export default App;
