import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next, useTranslation } from 'react-i18next';

import config from '@/utils/config';

const TRANSLATION_LOAD_PATH = config.theme.i18nLoadPath;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // resources: {
    //   en: {
    //     translation: {
    //       "Welcome to React": "Welcome to React and react-i18next"
    //     }
    //   }
    // },
    debug: true,
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: TRANSLATION_LOAD_PATH,
    },
    lng: 'th', // if you're using a language detector, do not define the lng option
    fallbackLng: 'th',
    ns: ['common'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

console.log('init i18n');

export default i18n;
