import tw from 'twin.macro';

import { Button } from '@/components/base';
import { useAuthContext } from '@/providers/Auth';
import { AUTH_STATE, AUTH_TYPE } from '@/providers/types';

const PageNotFound = () => {
  const { authState, authType } = useAuthContext();
  let redirectUrl;
  if (authState === AUTH_STATE.LOGGED_IN && authType === AUTH_TYPE.BACKOFFICE) {
    redirectUrl = '/backoffice/dashboard';
  } else if (authState === AUTH_STATE.LOGGED_IN && authType === AUTH_TYPE.INTERVIEWEE) {
    redirectUrl = '/interviewee/home';
  } else {
    redirectUrl = '';
  }

  return (
    <section tw="grid min-h-screen place-items-center">
      <div tw="mx-auto max-w-screen-xl py-8 px-4 lg:(py-16 px-6)">
        <div tw="mx-auto max-w-screen-sm text-center">
          {!redirectUrl ? (
            <>
              <div tw="mx-auto max-w-screen-sm text-center">
                <h1 tw="mb-4 font-extrabold md:tracking-tight">ไม่พบการสัมภาษณ์</h1>
                <p tw="mb-8 font-light text-lg text-gray-500">ไม่สามารถเข้าสู่ระบบได้</p>
              </div>
            </>
          ) : (
            <>
              <h1 tw="mb-4 font-extrabold text-7xl tracking-tight lg:text-9xl">404</h1>
              <p tw="mb-4 font-bold text-3xl text-gray-900 tracking-tight md:text-4xl">
                Something's missing.
              </p>
              <p tw="mb-8 font-light text-lg text-gray-500">Sorry, we can't find that page.</p>
            </>
          )}
          {redirectUrl && (
            <Button as="a" href={redirectUrl}>
              Back to Homepage
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
