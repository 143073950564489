import { useState } from 'react';
import tw from 'twin.macro';

import { ChevronDownIcon } from '@heroicons/react/24/outline';

type AccordionProps = {
  detail: JSX.Element;
  index: number;
  summary: JSX.Element;
};

const Accordion: React.FC<AccordionProps> = (props) => {
  const { detail, index, summary } = props;
  const [isActive, setActive] = useState<boolean>(false);

  const toggleIsActive = () => setActive((prev) => !prev);

  return (
    <>
      <div
        css={[
          tw`flex cursor-pointer items-center justify-between gap-8 border bg-white p-4`,
          isActive && tw`bg-primary-50`,
          index === 0 && tw`rounded-tr-lg rounded-tl-lg`,
        ]}
        onClick={toggleIsActive}
      >
        {summary}
        <ChevronDownIcon
          css={[tw`hidden h-5 w-5 rotate-0 lg:block`, isActive && tw`rotate-180 duration-150`]}
        />
      </div>
      {isActive ? (
        <div tw="flex w-full justify-center border-l border-r border-b bg-white px-6 py-8">
          {detail}
        </div>
      ) : null}
    </>
  );
};

export default Accordion;
