import tw, { styled } from 'twin.macro';

import Button from './Button';

type ModalProps = {
  loading?: boolean;
  title?: string;
  description?: string | React.ReactNode;
  primaryButtonText?: string;
  primaryButtonAction?: any;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  footer?: React.ReactNode;
};

const Modal: React.FC<ModalProps> = (props) => {
  const {
    loading,
    title,
    description,
    primaryButtonAction,
    primaryButtonText,
    secondaryButtonAction,
    secondaryButtonText,
    footer,
  } = props;
  return (
    <div tw="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div tw="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-75"></div>
      <div tw="fixed inset-0 z-10 overflow-y-auto">
        <div tw="flex min-h-full items-center justify-center p-4 text-center sm:(items-center p-0)">
          <div tw="relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all sm:(my-8 w-full max-w-lg)">
            <div tw="bg-white px-4 pt-5 pb-4 sm:(p-6 pb-4)">
              <div tw="mt-3 sm:(mt-0 ml-4 text-left)">
                <h5 tw="text-gray-900 leading-6" id="modal-title">
                  {title}
                </h5>
                <div tw="mt-2">
                  {typeof description === 'string' ? (
                    <p tw="text-gray-500">{description}</p>
                  ) : (
                    description
                  )}
                </div>
              </div>
            </div>
            {footer ?? (
              <div tw="flex flex-col gap-3 bg-gray-50 px-4 py-3 sm:(flex-row-reverse px-6)">
                <Button
                  disabled={loading}
                  css={[tw`w-full sm:w-fit`, secondaryButtonText && tw`w-full sm:w-fit`]}
                  onClick={primaryButtonAction}
                >
                  {primaryButtonText}
                </Button>
                {secondaryButtonText && secondaryButtonAction ? (
                  <Button
                    disabled={loading}
                    variant="info"
                    onClick={() => secondaryButtonAction?.()}
                  >
                    {secondaryButtonText}
                  </Button>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
