import tw from 'twin.macro';

type CreateSessionFormSkeletonProps = {
  lines?: number;
};

const CreateSessionFormSkeleton: React.FC<CreateSessionFormSkeletonProps> = ({ lines = 2 }) => {
  return (
    <div tw="flex animate-pulse flex-col space-y-4">
      {[...Array(lines)].map((_, index) => (
        <div key={`CreateSessionFormSkeleton.${index}`} tw="flex flex-col gap-2">
          <div tw="h-4 w-1/3 rounded-lg bg-slate-200"></div>
          <div tw="h-11 w-full rounded-lg bg-slate-200"></div>
        </div>
      ))}
      <div tw="h-10 w-full rounded-lg bg-slate-200 sm:w-1/6" />
    </div>
  );
};

export default CreateSessionFormSkeleton;
