import React from 'react';

import AuthProvider from './Auth';
import I18nProvider from './I18next';
import QueryClientProvider from './QueryClient';

const AppProvider = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <I18nProvider>
    <React.Suspense fallback={<div></div>}>
      <QueryClientProvider>
        <AuthProvider>{children}</AuthProvider>
      </QueryClientProvider>
    </React.Suspense>
  </I18nProvider>
);

export default AppProvider;
