import { useMemo, useState } from 'react';
import tw from 'twin.macro';

import { DocumentIcon, FaceFrownIcon, PlusIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from '@/components/base';
import {
  useCreateIntervieweeSessionsReportMutation,
  useGetAllSessionsQuery,
} from '@/domains/session';
import { Roles } from '@/libs/graphql';
import { useBackofficeContext } from '@/providers/Backoffice';

import SessionDataTable from './components/SessionDataTable';
import SessionDataTableSkeleton from './components/SessionDataTableSkeleton';
import { MODAL_STATE } from './types';

const SpinnerLoader = () => (
  <div tw="relative">
    <div tw="h-10 w-10 animate-spin rounded-full border-primary border-4 border-t-transparent border-solid"></div>
  </div>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, loading } = useBackofficeContext();
  const [url, setUrl] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalState, setModalState] = useState<MODAL_STATE>();

  const handleOnClick = () => {
    navigate('/backoffice/create-session');
  };

  const {
    data: allSessionsData,
    loading: allSessionsLoading,
    error: allSessionsError,
  } = useGetAllSessionsQuery({ fetchPolicy: 'network-only' });

  const filteredSessions = allSessionsData?.getAllSessions?.filter(
    (data) => !!data.intervieweeName,
  );

  const [createIntervieweeSessionsReport, { loading: createInterviewSessionsReportLoading }] =
    useCreateIntervieweeSessionsReportMutation({
      onCompleted(data) {
        if (
          data.createIntervieweeSessionsReport.__typename ===
          'CreateIntervieweeSessionsReportResultSuccess'
        ) {
          setUrl(data.createIntervieweeSessionsReport.createdUrl);
          setModalState(MODAL_STATE.CREATED_REPORT);
        }
      },
    });

  const isLoading = useMemo(
    () => loading || allSessionsLoading || createInterviewSessionsReportLoading,
    [loading, allSessionsLoading, createInterviewSessionsReportLoading],
  );

  const handleCreateReport = async () => {
    setIsOpen(true);
    setModalState(MODAL_STATE.CREATING_REPORT);
    await createIntervieweeSessionsReport();
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDownload = () => {
    window.open(url, '_blank');
  };

  const MODAL_CONTENT = {
    [MODAL_STATE.CREATED_REPORT]: {
      title: 'สร้างรายงานผู้สมัครเรียบร้อย',
      description: (
        <div tw="flex flex-col gap-2">
          <p tw="text-gray-500">รายงานพร้อมแล้ว กดที่ปุ่ม "ดาวน์โหลดรายงาน" ได้้เลย</p>
          <CheckCircleIcon tw="place-self-center h-10 w-10 text-primary" />
        </div>
      ),
    },
    [MODAL_STATE.CREATING_REPORT]: {
      title: 'กำลังสร้างรายงานผู้สมัคร',
      description: (
        <div tw="my-2 mt-5 flex w-full justify-center text-center">
          <SpinnerLoader />
        </div>
      ),
    },
  };

  return (
    <>
      {isOpen ? (
        <Modal
          {...(modalState ? MODAL_CONTENT[modalState] : {})}
          footer={
            <div tw="flex flex-col gap-3 bg-gray-50 px-4 py-3 sm:(flex-row-reverse px-6)">
              <Button onClick={handleDownload} disabled={!url || isLoading}>
                ดาวน์โหลดรายงาน
              </Button>
              <Button variant="info" onClick={closeModal}>
                ปิดหน้าต่างนี้
              </Button>
            </div>
          }
        />
      ) : null}
      <div tw="m-auto flex min-h-screen flex-col space-y-5 px-4 py-6 lg:(px-20 py-14)">
        <div tw="flex w-full flex-col items-start justify-between lg:(flex-row items-center)">
          <h3>แดชบอร์ด</h3>
          <div tw="flex w-full flex-col gap-2 lg:(w-fit flex-row)">
            <Button
              disabled={user?.roles?.includes(Roles.ViewOnly) || isLoading}
              tw="flex w-full items-center justify-center gap-1 lg:w-fit"
              onClick={handleOnClick}
            >
              <PlusIcon tw="h-5 w-5" />
              สร้างการสัมภาษณ์
            </Button>
            <Button
              disabled={isLoading || filteredSessions?.length === 0}
              tw="flex w-full items-center justify-center gap-1 lg:w-fit"
              onClick={handleCreateReport}
              variant="success"
            >
              <DocumentIcon tw="h-5 w-5" />
              ดาวน์โหลดรายงานผู้สมัครทั้งหมด
            </Button>
          </div>
        </div>
        {allSessionsLoading ? (
          <SessionDataTableSkeleton lines={20} />
        ) : (
          <>
            {Array.isArray(filteredSessions) &&
            !allSessionsLoading &&
            filteredSessions.length === 0 ? (
              <div tw="flex w-full flex-col items-center justify-center gap-2 rounded-2xl bg-white p-14">
                <FaceFrownIcon tw="h-6 w-6" />
                <h5>ไม่พบการสัมภาษณ์</h5>
              </div>
            ) : (
              <SessionDataTable sessions={filteredSessions} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
