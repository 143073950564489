import tw, { css, styled } from 'twin.macro';

const Video = styled.video(({ mirror, fullWidth }: { mirror?: boolean; fullWidth?: boolean }) => [
  /* Base style */
  tw`flex w-full rounded-2xl object-cover   aspect-[4/3]`,
  fullWidth ? '' : tw`lg:max-w-[500px] xl:w-[500px]`,
  /* Style: Flip the video horizontally */
  mirror &&
    css`
      transform: rotateY(3.142rad);
    `,
]);

export default Video;
