import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Firebase } from '@/libs';

export default function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    const logout = async () => {
      try {
        await Firebase.auth.default.signOut();
        navigate('/backoffice/login');
      } catch (error) {
        console.error(error);
      }
    };
    logout();
    return () => {};
  }, []);

  return <div>Logging out...</div>;
}
