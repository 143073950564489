import tw from 'twin.macro';

import { FormElementProps } from './types';

interface TextareaProps extends FormElementProps<HTMLTextAreaElement> {}

const Textarea: React.FC<TextareaProps> = (props) => {
  const { label, name, register, validations, ...textareaProps } = props;
  const { ref, ...rest } = register?.(name, { ...validations }) ?? {};

  return (
    <label htmlFor={name}>
      <span tw="font-semibold">{label}</span>
      <textarea
        tw="rounded-lg border bg-gray-50 px-3 py-2 focus:(bg-white outline-none)"
        id={name}
        {...textareaProps}
        {...rest}
        ref={ref}
      />
    </label>
  );
};

export default Textarea;
