import tw from 'twin.macro';

const QuestionTitleSkeleton = tw.div`h-8 w-full rounded-md bg-slate-200`;

const QuestionDetailsSkeleton: React.FC = () => {
  return (
    <div tw="flex animate-pulse items-start gap-6">
      <p tw="select-none whitespace-nowrap rounded-full bg-slate-200 px-4 py-1 font-medium text-slate-200">
        คำถามข้อที่ -
      </p>
      <QuestionTitleSkeleton />
    </div>
  );
};

export default QuestionDetailsSkeleton;
