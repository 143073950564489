import tw from 'twin.macro';

import { Button } from '@/components/base';
import { StatusMessages } from '@/utils/useMediaRecorder';

type PreparationButtonsProps = {
  clearBlobUrl: () => void;
  duration: number;
  status?: StatusMessages;
  initializeMediaStream?: () => Promise<void>;
  loading: boolean;
  nextSession?: () => void;
  startRecording: () => Promise<void>;
  stopRecording: () => void;
};

const PreparationButtons: React.FC<PreparationButtonsProps> = (props) => {
  const {
    clearBlobUrl,
    duration,
    initializeMediaStream,
    loading,
    nextSession,
    startRecording,
    status,
    stopRecording,
  } = props;

  const disableButtonConditions =
    !status ||
    (status &&
      [
        StatusMessages.INIT_FAILED,
        StatusMessages.INITIALIZING,
        StatusMessages.PERMISSION_DENIED,
      ].includes(status)) ||
    loading;

  if (status === StatusMessages.STOPPED) {
    return (
      <div tw="flex flex-col gap-2 sm:flex-row">
        <Button onClick={nextSession} disabled={disableButtonConditions} tw="w-full">
          เริ่มการสัมภาษณ์จริง
        </Button>
        <Button
          variant="info"
          onClick={clearBlobUrl}
          disabled={disableButtonConditions}
          tw="w-full"
        >
          ทดสอบระบบอีกครั้ง
        </Button>
      </div>
    );
  } else if (status === StatusMessages.RECORDING) {
    return (
      <>
        <Button variant="error" onClick={stopRecording}>
          จบการตอบคำถาม
        </Button>
      </>
    );
  } else if (
    status &&
    [StatusMessages.INIT_FAILED, StatusMessages.PERMISSION_DENIED].includes(status)
  ) {
    return (
      <Button variant="error" onClick={() => initializeMediaStream?.()}>
        ลองใหม่อีกครั้ง
      </Button>
    );
  }
  return (
    <>
      <Button variant="primary" onClick={startRecording} disabled={disableButtonConditions}>
        เริ่มทดสอบระบบ
      </Button>
    </>
  );
};

export default PreparationButtons;
