export enum Browser {
  Chrome = 'chrome',
  Firefox = 'firefox',
  Safari = 'safari',
  Opera = 'opera',
  Edge = 'edge',
  NoBrowserDetection = 'No browser detection',
}

export const useDetectBrowser = () => {
  let userAgent = navigator.userAgent;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return Browser.Chrome;
  } else if (userAgent.match(/firefox|fxios/i)) {
    return Browser.Firefox;
  } else if (userAgent.match(/safari/i)) {
    return Browser.Safari;
  } else if (userAgent.match(/opr\//i)) {
    return Browser.Opera;
  } else {
    return Browser.NoBrowserDetection;
  }
};
