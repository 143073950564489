import tw from 'twin.macro';

import QuestionDetailsSkeleton from './BackofficeQuestionDetailsSkeleton';

type AccordionSkeletonProps = {
  lines?: number;
};

const AccordionSkeleton: React.FC<AccordionSkeletonProps> = ({ lines = 2 }) => {
  return (
    <div tw="animate-pulse">
      {[...Array(lines)].map((_, index) => (
        <div
          key={`Accordion.${index}`}
          css={[
            tw`flex items-center justify-between gap-8 border bg-white p-4`,
            index === 0 && tw`rounded-tr-lg rounded-tl-lg`,
          ]}
        >
          <QuestionDetailsSkeleton />
          <div tw="hidden h-5 w-5 rounded-full bg-slate-100 xl:block" />
        </div>
      ))}
    </div>
  );
};

export default AccordionSkeleton;
