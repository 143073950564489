import { TFunction } from 'i18next';
import { UseTranslationResponse } from 'react-i18next';

import i18next from '@/libs/i18n';
import config from '@/utils/config';
import { EMAIL_REGEX_PATTERN } from '@/utils/regex';

import { FormInputType } from './types';

const { batchInterviewCreationTemplateUrl } = config;

export const SINGLE_INTERVIEW_FORM = (t: TFunction) => [
  {
    id: 'refId',
    name: 'refId',
    label: t('intervieweeProfiles.refId.label'),
    placeholder: t('intervieweeProfiles.refId.placeholder'),
    required: false,
    type: FormInputType.TEXT,
  },
  {
    id: 'intervieweeName',
    name: 'intervieweeName',
    label: t('intervieweeProfiles.intervieweeName.label'),
    placeholder: t('intervieweeProfiles.intervieweeName.placeholder'),
    required: true,
    type: FormInputType.TEXT,
  },
  {
    id: 'intervieweeEmail',
    name: 'intervieweeEmail',
    label: t('intervieweeProfiles.intervieweeEmail.label'),
    placeholder: t('intervieweeProfiles.intervieweeEmail.placeholder'),
    required: true,
    type: FormInputType.TEXT,
    validation: { pattern: { value: EMAIL_REGEX_PATTERN, message: 'กรุณากรอกอีเมลให้ถูกต้อง' } },
  },
  {
    id: 'position',
    name: 'position',
    label: t('intervieweeProfiles.position.label'),
    placeholder: t('intervieweeProfiles.position.placeholder'),
    required: true,
    type: FormInputType.TEXT,
  },
  {
    id: 'refUrl',
    name: 'refUrl',
    label: t('intervieweeProfiles.refUrl.label'),
    placeholder: t('intervieweeProfiles.refUrl.placeholder'),
    required: false,
    type: FormInputType.TEXT,
  },
];

export const BATCH_INTERVIEW_FORM = (t: TFunction) => [
  {
    id: 'intervieweeDetails',
    name: 'intervieweeDetails',
    label: 'รายชื่อผู้สมัครทั้งหมด (.csv)',
    placeholder:
      'กรุณากรอกมาในรูปแบบ ชื่อ สกุล, อีเมล, ตำแหน่งที่สมัคร คั่นแต่ละคนการขึ้นบรรทัดใหม่',
    required: { value: true, message: 'กรุณาอัพโหลดไฟล์ที่มีรายชื่อผู้สมัครทั้งหมด' },
    type: FormInputType.FILE,
    description: `<a href="${batchInterviewCreationTemplateUrl}" target="_blank"> สามารถดูตัวอย่างได้ที่นี่ </a>`,
  },
];
