import { useMemo } from 'react';
import tw from 'twin.macro';

import { HomeIcon } from '@heroicons/react/24/outline';
import { Link, useLocation } from 'react-router-dom';

import { Sidebar } from '@/components/base';
import { Firebase } from '@/libs';
import { useBackofficeContext } from '@/providers/Backoffice';
import { useToggle } from '@/utils/useToggle';

const BO_SIDEBAR_MENU = [{ title: 'แดชบอร์ด', path: '/backoffice/dashboard', icon: <HomeIcon /> }];

const Menu = ({
  isActive = false,
  title,
  path,
  icon,
}: {
  isActive?: boolean;
  title: string;
  path: string;
  icon: JSX.Element;
}) => {
  return (
    <div tw="flex w-full flex-col">
      <li>
        <Link
          css={[
            tw`flex items-center gap-2 rounded-lg p-2 duration-200 hover:bg-gray-100`,
            isActive &&
              tw`cursor-default bg-primary bg-opacity-20 hover:(bg-primary bg-opacity-20)`,
          ]}
          to={path}
        >
          <div css={[tw`h-5 w-5`, isActive && tw`stroke-2`]}>{icon}</div>
          <p css={[tw`font-normal`, isActive && tw`font-medium`]}>{title}</p>
        </Link>
      </li>
    </div>
  );
};

const BackofficeSidebarContainer = () => {
  const location = useLocation();
  const { user } = useBackofficeContext();

  const [value, toggle] = useToggle([true, false]);

  const signOut = async () => {
    try {
      await Firebase.auth.default.signOut();
    } catch (error) {
      console.error(error);
    }
  };

  const content = useMemo(
    () =>
      BO_SIDEBAR_MENU.map(({ path, title, icon }, index) => (
        <Menu
          path={path}
          key={`Menu.${index}`}
          isActive={location.pathname === path}
          title={title}
          icon={icon}
        />
      )),
    [location.pathname],
  );
  return (
    <Sidebar show={value} toggle={toggle} name={user?.name} content={content} signOut={signOut} />
  );
};

export default BackofficeSidebarContainer;
