import { AUTH_ERROR } from './types';

export const LOGIN_ERROR_MESSAGES = {
  [AUTH_ERROR.WRONG_PASSWORD]: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองอีกครั้ง',
  [AUTH_ERROR.USER_NOT_FOUND]: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองอีกครั้ง',
  [AUTH_ERROR.WRONG_PROVIDER]:
    'อีเมลนี้ไม่ได้ถูกเข้าระบบด้วยอีเมล กรุณาลองเข้าสู่ระบบด้วยช่องทางอื่น',
  [AUTH_ERROR.TOO_MANY_REQUESTS]:
    'คุณพยายามเข้าสู่ระบบเกินจำนวนครั้งที่กำหนด กรุณาลองอีกครั้งในภายหลัง',
};
