import { useEffect, useMemo, useRef, useState } from 'react';
import tw from 'twin.macro';

import { MicrophoneIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import Card from '@/components/Card';
import CardSkeleton from '@/components/CardSkeleton';
import Progressbar from '@/components/Progressbar';
import QuestionDetails from '@/components/QuestionDetails';
import QuestionDetailsSkeleton from '@/components/QuestionDetailsSkeleton';
import { InterviewSessionCard, VideoInterviewContainer } from '@/containers';
import { useGetQuestionSnapshotMutation } from '@/domains/session';
import { QuestionFragment, SessionStatus, VideoUploadStatus } from '@/libs/graphql';
import { useInterviewSession } from '@/providers/InterviewSession';
import { useBeforeUnload } from '@/utils/useBeforeUnload';
import { useGetMediaDevices } from '@/utils/useGetMediaDevices';
import { StatusMessages, useMediaRecorder } from '@/utils/useMediaRecorder';

import InputSelect from '../preparation/components/InputSelect';
import SessionButton from './components/SessionButton';

const Session = () => {
  const navigate = useNavigate();
  const {
    nextQuestion,
    endSession,
    currentQuestion: current,
    totalQuestions,
    loading,
    videoUploadProgress,
    videoUploadStatus,
    uploadFileToFirebase,
    interviewee,
    startQuestion,
  } = useInterviewSession();

  const [question, setQuestion] = useState<QuestionFragment>();

  const [
    getQuestionSnapshot,
    { data: questionSnapshotData, loading: questionSnapshotLoading, error: questionSnapshotError },
  ] = useGetQuestionSnapshotMutation({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getQuestionSnapshot?.__typename === 'GetQuestionSnapshotResultSuccess') {
        setQuestion(data.getQuestionSnapshot.question);
      }
    },
  });

  const isLoading = useMemo(
    () => loading || questionSnapshotLoading,
    [loading, questionSnapshotLoading],
  );

  const [audioInputDeviceId, setAudioInputDeviceId] = useState<string>(() => {
    const savedValue = localStorage.getItem('audioInputDeviceId');
    return savedValue ? JSON.parse(savedValue) : '';
  });
  const [videoInputDeviceId, setVideoInputDeviceId] = useState<string>(() => {
    const savedValue = localStorage.getItem('videoInputDeviceId');
    return savedValue ? JSON.parse(savedValue) : '';
  });

  const {
    clearBlobUrl,
    initializeMediaStream,
    mediaBlobUrl,
    previewStream,
    status,
    startRecording,
    stopRecording,
  } = useMediaRecorder({
    audio: true,
    video: true,
    blobPropertyBag: { type: 'video/mp4' },
    onStop: uploadFileToFirebase,
    uploadProperty: {
      company: interviewee?.company as string,
      sessionId: interviewee?.uid as string,
    },
    audioInputDeviceId,
    videoInputDeviceId,
  });
  const { audioInput, videoInput } = useGetMediaDevices({ status });
  const timeout = useRef<NodeJS.Timeout>();

  const stopTimeout = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }

  const setTimeoutDuration = (duration: number) => {
    stopTimeout();
    timeout.current = setTimeout(stopRecording, duration);
  }

  useEffect(() => {
    initializeMediaStream();
    stopTimeout();
  }, [current]);

  useEffect(() => {
    if (interviewee && current !== 0) {
      getQuestionSnapshot({ variables: { order: current as number, sessionId: interviewee.uid } });
    }
  }, [current, interviewee]);

  useBeforeUnload({
    when: videoUploadStatus === VideoUploadStatus.Uploading || status === StatusMessages.RECORDING,
    message: 'Are you sure you want to leave?',
  });

  const currentQuestion = current ? +current : 0;

  const handleStopRecording = () => {
    stopTimeout();
    stopRecording();
  };

  const handleStartRecording = async () => {
    await startRecording();
    setTimeoutDuration((question?.duration ?? 120) * 1000);
  };

  const handleNextQuestion = () => {
    stopTimeout();
    clearBlobUrl();
    nextQuestion();
  };

  const handleStartQuestion = async (time: number) => {
    await startQuestion(time);
  };

  const endCurrentSession = () => {
    endSession();
    navigate(`/interviewee/completed`);
  };

  useEffect(() => {
    if (currentQuestion === 0) {
      navigate(`/interviewee/home`);
    }
    return () => {};
  }, []);

  const handleSetAudioInputDeviceId = (value: string) => {
    setAudioInputDeviceId(value);
    localStorage.setItem('audioInputDeviceId', JSON.stringify(value));
  };

  const handleSetVideoInputDeviceId = (value: string) => {
    setVideoInputDeviceId(value);
    localStorage.setItem('videoInputDeviceId', JSON.stringify(value));
  };

  const isLastQuestion = currentQuestion >= (totalQuestions ?? 1);
  const disableButton = isLoading || videoUploadStatus === 'UPLOADING';

  return (
    <div tw="m-auto flex max-w-screen-xl flex-col space-y-8 px-4 py-6 lg:(px-20 py-14)">
      <Progressbar current={currentQuestion} total={totalQuestions} />
      {isLoading ? (
        <QuestionDetailsSkeleton />
      ) : (
        <QuestionDetails title={question?.title ?? ''} number={currentQuestion} />
      )}

      <div tw="flex w-full flex-col items-center justify-center gap-6 lg:(flex-row items-start justify-between)">
        <div tw="space-y-4">
          <VideoInterviewContainer
            isInterviewSession
            blobUrl={mediaBlobUrl}
            status={status}
            mediaStream={previewStream}
          />
          {status === StatusMessages.IDLE ? (
            <div tw="flex gap-2">
              <InputSelect
                containerStyle="w-1/2"
                icon={<MicrophoneIcon tw="w-4 h-4" />}
                value={
                  audioInput.length !== 0 && !audioInputDeviceId
                    ? audioInput[0].id
                    : audioInputDeviceId
                }
                onChange={handleSetAudioInputDeviceId}
                availableInputs={audioInput}
              />
              <InputSelect
                containerStyle="w-1/2"
                icon={<VideoCameraIcon tw="w-4 h-4" />}
                value={
                  videoInput.length !== 0 && !videoInputDeviceId
                    ? videoInput[0].id
                    : videoInputDeviceId
                }
                onChange={handleSetVideoInputDeviceId}
                availableInputs={videoInput}
              />
            </div>
          ) : null}
        </div>
        <div tw="flex w-full flex-col gap-4">
          {isLoading ? (
            <>
              <CardSkeleton tw="h-fit" lines={4} />
              <CardSkeleton tw="h-fit" lines={2} />
            </>
          ) : (
            <InterviewSessionCard
              isInterviewSession
              duration={question?.duration ?? 120}
              status={status}
              startRecording={handleStartRecording}
              currentQuestion={currentQuestion}
              isLastQuestion={currentQuestion === totalQuestions}
              videoUploadStatus={videoUploadStatus}
              uploadProgress={videoUploadProgress}
              startQuestion={handleStartQuestion}
            />
          )}

          {isLoading ? (
            <CardSkeleton lines={3} />
          ) : (
            <>
              {question?.tips &&
                question?.tips.length !== 0 &&
                status !== StatusMessages.STOPPED && (
                  <Card
                    title="คำแนะนำ"
                    description={
                      <ol tw="list-decimal justify-start pl-4 font-jamjuree">
                        {question?.tips.map((text: string) => (
                          <li key={text} tw="[list-style: decimal]">
                            {text}
                          </li>
                        ))}
                      </ol>
                    }
                  />
                )}
            </>
          )}
          <SessionButton
            disabled={disableButton}
            endSession={endCurrentSession}
            startRecording={handleStartRecording}
            stopRecording={handleStopRecording}
            status={status}
            nextQuestion={handleNextQuestion}
            initializeMediaStream={initializeMediaStream}
            isLastQuestion={isLastQuestion}
            videoUploadStatus={videoUploadStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default Session;
