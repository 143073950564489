import tw from 'twin.macro';

import { useTranslation } from 'react-i18next';

import { SessionStatus } from '@/libs/graphql';
import config from '@/utils/config';
import { getSessionStatusText } from '@/utils/getSessionStatusText';

import ClipboardCopyInput from './ClipboardCopyInput';
import ExpiryLinkCopyInput from './ExpiryLinkCopyInput';

const { baseUrl } = config;

type IntervieweeCardProps = {
  id: string;
  intervieweeName: string;
  intervieweeEmail: string;
  refId?: string;
  refUrl?: string;
  status?: SessionStatus;
  position: string;
  company: string;
  expiredAt: string;
  availableUntil: string | null;
  viewer?: boolean;
  intervieweeUrl: string;
  backofficeInterviewerUrl: string;
  viewerUrl?: string;
  viewerUrlExpiredAt?: string;
  onRequestSessionAccessToken?: () => void;
  requestSessionAccessTokenLoading?: boolean;
};

const IntervieweeCard: React.FC<IntervieweeCardProps> = (props) => {
  const {
    intervieweeName,
    intervieweeEmail,
    status,
    position,
    id,
    company,
    refId,
    refUrl,
    expiredAt,
    availableUntil,
    viewer = false,
    intervieweeUrl,
    backofficeInterviewerUrl,
    viewerUrl,
    viewerUrlExpiredAt,
    onRequestSessionAccessToken = () => {},
    requestSessionAccessTokenLoading,
  } = props;

  const { t } = useTranslation();

  const statusText = getSessionStatusText(
    status as SessionStatus,
    availableUntil ? new Date(availableUntil) : null,
    new Date(expiredAt),
  );

  return (
    <div tw="flex w-full gap-0 rounded-2xl border bg-white p-4 md:(w-fit gap-5)">
      <img tw="hidden sm:(block h-20 w-20 p-2)" src="/assets/people-placeholder.png" alt="skooldio" />
      <div>
        <p tw="font-medium text-2xl">{`${intervieweeName}${refId ? ` (${refId})` : ''}`}</p>
        <p tw="text-gray-700">{intervieweeEmail}</p>
        <div tw="mt-4 flex flex-col gap-1 sm:(flex-row gap-6)">
          <p tw="text-gray-700">
            <span tw="font-medium">สถานะ</span>
            <br />
            {statusText}
          </p>
          <p tw="text-gray-700">
            <span tw="font-medium">
              {t([
                'intervieweeProfiles.position.label',
                'intervieweeProfiles.position.viewerLabel',
              ])}
            </span>
            <br />
            {position}
          </p>
          {!viewer && (
            <p tw="text-gray-700">
              <span tw="font-medium">สัมภาษณ์ได้ถึงวันที่</span>
              <br />
              {availableUntil ?? expiredAt}
            </p>
          )}
        </div>
        {!viewer && (
          <>
            <div tw="pt-4 space-y-1">
              <p tw="font-medium">ลิงก์การสัมภาษณ์</p>
              <ClipboardCopyInput url={intervieweeUrl} />
            </div>
            <div tw="pt-4 space-y-1">
              <p tw="font-medium">ลิงก์ดูผลสัมภาษณ์ (Admin)</p>
              <ClipboardCopyInput url={backofficeInterviewerUrl} />
            </div>
            {
              <div tw="pt-4 space-y-1">
                <p tw="font-medium">{`ลิงก์ดูผลสัมภาษณ์ (หมดอายุ: ${viewerUrlExpiredAt})`}</p>
                <ExpiryLinkCopyInput
                  url={viewerUrl}
                  onRefreshUrl={onRequestSessionAccessToken}
                  loading={requestSessionAccessTokenLoading}
                />
              </div>
            }
          </>
        )}
        {refUrl && (
          <div tw="pt-4 space-y-1">
            <p tw="font-medium">
              {t(['intervieweeProfiles.refUrl.viewerLabel', 'intervieweeProfiles.refUrl.label'])}
            </p>
            <a tw="underline" href={`${refUrl}`}>
              {refUrl}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default IntervieweeCard;
