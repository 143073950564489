import { useMemo } from 'react';
import tw from 'twin.macro';

import { useLocation, useParams } from 'react-router-dom';

import { Sidebar } from '@/components/base';
import { useInterviewSession } from '@/providers/InterviewSession';

import { SIDEBAR_CONTENT } from './constants';

const Step = ({ isActive = false, text }: { isActive?: boolean; text: string }) => {
  return (
    <div tw="my-6 flex space-x-5 px-4">
      <div
        css={[
          tw`my-auto rounded-full`,
          isActive ? tw`h-2 w-2 bg-primary-900` : tw`h-1 w-1 bg-gray-500`,
        ]}
      />
      <div css={[isActive && tw`font-semibold`]}>{text}</div>
    </div>
  );
};

const SidebarContainer: React.FC = ({ ...props }) => {
  const location = useLocation();
  const params = useParams();

  const { interviewee } = useInterviewSession();
  const isActive = (path: string) => {
    return (
      location.pathname === path ||
      (location.pathname !== path &&
        path.includes(location.pathname.slice(0, location.pathname.indexOf(params.id ?? ' '))))
    );
  };

  const content = useMemo(
    () =>
      SIDEBAR_CONTENT.some(({ path }) => isActive(path)) &&
      SIDEBAR_CONTENT.map(({ text, path }, index) => (
        <Step key={index} isActive={isActive(path)} text={text} />
      )),
    [location.pathname],
  );

  return <Sidebar content={content} name={interviewee?.name} {...props} />;
};

export default SidebarContainer;
