import { ReactNode } from 'react';

import { ApolloProvider } from '@apollo/client';

import { GraphQL } from '@/libs';

const QueryClientProvider = ({ children }: { children?: ReactNode }) => (
  <ApolloProvider client={GraphQL.apolloClient}>{children}</ApolloProvider>
);

export default QueryClientProvider;
