export enum AUTH_ERROR {
  WRONG_PASSWORD = 'auth/wrong-password',
  USER_NOT_FOUND = 'auth/user-not-found',
  WRONG_PROVIDER = 'auth/wrong-provider',
  TOO_MANY_REQUESTS = 'auth/too-many-requests',
}

export type FormValues = {
  email: string;
  password: string;
};

export type AuthErrorObject = { code: AUTH_ERROR; message?: string };
