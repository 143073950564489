import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

import firebaseApp from './firebase';

const storage = getStorage(firebaseApp);

const getStorageRef = async (company: string, sessionId: string, fileName?: string) => {
  const destination = `videos/${company}/${sessionId}/${fileName ?? 'unknown'}.mp4`;
  return ref(storage, destination);
};

export { getDownloadURL, getStorageRef, uploadBytesResumable };

export default storage;
