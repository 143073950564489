import { useEffect } from 'react';
import tw from 'twin.macro';

import dayjs from 'dayjs';
import { UseFormRegister, useForm } from 'react-hook-form';

import { Button } from '@/components/base';
import { Checkbox, Select, TextArea, TextField } from '@/components/form';
import config from '@/utils/config';

import { FormInputType, FormValues } from '../types';

const { extendExpiryDate } = config;

type Fields = {
  name: string;
  label: string;
  options?: any;
  placeholder?: string;
  required: boolean | object;
  type: FormInputType;
  validation?: object;
  description?: React.ReactNode | string;
};

type CreateSessionFormProps = {
  fields: Fields[];
  handleSubmitForm: (data: FormValues) => void;
  shouldResetForm?: boolean;
};

const FormField = ({
  label,
  name,
  placeholder,
  description,
  type,
  options = [],
  required,
  register,
  validation,
  error,
}: {
  register: UseFormRegister<FormValues>;
  error: any;
} & Fields) => {
  let input;
  if (type === 'select') {
    input = (
      <Select
        register={register}
        name={name}
        label={label}
        options={options}
        placeholder={placeholder}
        validations={{ required, ...validation }}
      />
    );
  } else if (type === 'date') {
    const now = dayjs();
    input = (
      <TextField
        label={label}
        register={register}
        name={name}
        validations={{ required, ...validation }}
        defaultValue={now.add(extendExpiryDate, 'day').format('YYYY-MM-DDTHH:mm')}
        type="datetime-local"
        min={now.format('YYYY-MM-DDTHH:mm')}
        placeholder={placeholder}
      />
    );
  } else if (type === 'textarea') {
    input = <TextArea register={register} name={name} placeholder={placeholder} />;
  } else if (type === 'checkbox') {
    input = (
      <Checkbox
        label={label}
        register={register}
        name={name}
        validations={{ required, ...validation }}
      />
    );
  } else {
    input = (
      <TextField
        description={description}
        register={register}
        label={label}
        type={type}
        name={name}
        placeholder={placeholder}
        validations={{ required, ...validation }}
      />
    );
  }
  return (
    <div tw="flex flex-col gap-1">
      {input}
      {error ? (
        <p tw="pl-1 text-danger-500 text-sm">
          {!!error.message ? error.message : 'กรุณากรอกข้อมูลให้ครบ'}
        </p>
      ) : null}
    </div>
  );
};

const CreateSessionForm: React.FC<CreateSessionFormProps> = (props) => {
  const { handleSubmitForm: submit, fields, shouldResetForm } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>();
  const handleSubmitForm = (data: FormValues) => {
    submit(data);
  };

  useEffect(() => {
    if (shouldResetForm) {
      reset();
    }
  }, [shouldResetForm]);

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitForm)} tw="flex flex-col space-y-4">
        {fields.map((field) => {
          return (
            <FormField
              key={field.name}
              label={field.label}
              name={field.name as keyof FormValues}
              options={field?.options}
              placeholder={field.placeholder}
              register={register}
              required={field.required}
              type={field.type}
              validation={field.validation}
              error={errors[field.name as keyof FormValues]}
              description={field.description}
            />
          );
        })}

        <Button tw="w-full bg-primary! hover:bg-primary-900! sm:w-fit" type="submit">
          สร้าง
        </Button>
      </form>
    </>
  );
};

export default CreateSessionForm;
