import tw from 'twin.macro';

type ProgressbarProps = {
  current: number;
  total?: number;
};

const Progressbar: React.FC<ProgressbarProps> = (props) => {
  const { current, total } = props;
  const width = total ? (current / total) * 100 : 0;
  return (
    <div tw="flex w-full items-center gap-6">
      <div tw="h-1 w-full rounded-2xl bg-off-white">
        <div tw="h-full rounded-2xl bg-primary" style={{ width: `${width}%` }}></div>
      </div>
      <p tw="select-none rounded-full border border-primary px-2 py-0.5 font-light text-xs">
        {!total ? <span tw="text-bg">{'-/-'}</span> : <>{`${current}/${total}`}</>}
      </p>
    </div>
  );
};

export default Progressbar;
