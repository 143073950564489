import { ReactNode } from 'react';

import { I18nextProvider } from 'react-i18next';

import i18next from '@/libs/i18n';

const I18nProvider = ({ children }: { children?: ReactNode }) => (
  <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
);

export default I18nProvider;
