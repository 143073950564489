import { useEffect, useState } from 'react';

import { StatusMessages } from './useMediaRecorder';

export type SelectInput = { name: string; value: string; id: string };

export const useGetMediaDevices = ({ status }: { status?: StatusMessages }) => {
  const [audioInput, setAudioInput] = useState<SelectInput[]>([]);
  const [videoInput, setVideoInput] = useState<SelectInput[]>([]);

  const gotDevices = async () => {
    const deviceInfos = await window.navigator.mediaDevices.enumerateDevices();

    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];
      if (deviceInfo.kind === 'audioinput') {
        setAudioInput((prevAudioInput) => [
          ...prevAudioInput,
          {
            id: deviceInfo.deviceId,
            name: deviceInfo.label || `microphone ${audioInput.length + 1}`,
            value: deviceInfo.deviceId,
          },
        ]);
      } else if (deviceInfo.kind === 'videoinput') {
        setVideoInput((prevVideoInput) => [
          ...prevVideoInput,
          {
            id: deviceInfo.deviceId,
            name: deviceInfo.label || `camera ${videoInput.length + 1}`,
            value: deviceInfo.deviceId,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    if (status === StatusMessages.IDLE) {
      gotDevices();
    }
  }, [status]);

  const uniqueAudioInputs = audioInput.filter(
    (input, i, arr) => arr.findIndex((t) => t.id === input.id) === i,
  );

  const uniqueVideoInputs = videoInput.filter(
    (input, i, arr) => arr.findIndex((t) => t.id === input.id) === i,
  );

  return { audioInput: uniqueAudioInputs, videoInput: uniqueVideoInputs };
};
