import tw from 'twin.macro';

type QuestionDetailsProps = {
  number: number;
  title: string;
};

const QuestionDetails: React.FC<QuestionDetailsProps> = (props) => {
  const { number, title } = props;
  return (
    <div tw="flex flex-col items-center gap-6 rounded-lg bg-white py-6 px-6 text-center sm:py-12">
      <p tw="whitespace-nowrap rounded-full bg-gray-200 px-4 py-1 font-medium">
        {number === 0 ? 'ทดสอบการใช้ระบบ' : `คำถามข้อที่ ${number}`}
      </p>
      <h5>{title}</h5>
    </div>
  );
};

export default QuestionDetails;
