import { useMemo, useState } from 'react';
import tw from 'twin.macro';

import { ChevronDownIcon, FaceFrownIcon } from '@heroicons/react/24/outline';
import { useLocation, useParams } from 'react-router-dom';

import Accordion from '@/components/Accordion';
import AccordionSkeleton from '@/components/AccordionSkeleton';
import QuestionDetails from '@/components/BackofficeQuestionDetails';
import IntervieweeCard from '@/components/IntervieweeCard';
import IntervieweeCardSkeleton from '@/components/IntervieweeCardSkeleton';
import Video from '@/components/Video';
import { IntervieweeVideoContainer } from '@/containers';
import { useGetQuestionSnapshotBySessionIdQuery, useGetSessionQuery } from '@/domains/session';
import {
  useGetQuestionSnapshotBySessionIdWithTokenQuery,
  useGetSessionWithTokenQuery,
} from '@/libs/graphql';
import { convertIntervieweeInfo } from '@/utils/convertIntervieweeInfo';
import { useTranslation } from 'react-i18next';
import Snackbar, { SnackbarVariants } from '@/components/Snackbar';
import TranscribeContainer from '@/containers/GenerativeAI/TranscribeContainer';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const ErrorView = ({
  message = 'ไม่สามารถดูการสัมภาษณ์ได้',
  code,
}: {
  message: string;
  code?: string;
}) => {
  return (
    <div tw="flex h-full w-full flex-col items-center justify-center gap-2 rounded-2xl border bg-white p-14">
      <FaceFrownIcon tw="h-6 w-6" />
      <h5>{message}</h5>
      {code && <h5>[{code}]</h5>}
    </div>
  );
};

const IntervieweeView = () => {
  const params = useParams();
  const searchParams = useQuery();
  const { t } = useTranslation();
  const sessionId = params.intervieweeId ?? '';
  const token = searchParams.get('token');

  if (!token) {
    return (
      <ErrorView message='ไม่สามารถดูการสัมภาษณ์ได้' code='E001' />
    );
  }

  const {
    data: sessionData,
    loading: sessionLoading,
    error: sessionError,
  } = useGetSessionWithTokenQuery({
    variables: { sessionId, token },
    fetchPolicy: 'network-only',
  });

  const intervieweeDetails = sessionData?.getSessionWithToken;

  const {
    data: questionSnapshotData,
    loading: questionSnapshotLoading,
    error: questionSnapshotError,
  } = useGetQuestionSnapshotBySessionIdWithTokenQuery({
    variables: { sessionId, token },
    fetchPolicy: 'network-only',
    skip: !sessionId,
  });

  if (sessionError?.message === 'Invalid token') {
    return (
      <ErrorView message='ไม่สามารถดูการสัมภาษณ์ได้' code='E002' />
    );
  }
  if (sessionError?.message === 'Token is expired') {
    return (
      <ErrorView message='ลิงก์ของคุณหมดอายุแล้ว' />
    );
  }

  const questionSnapshot = questionSnapshotData?.getQuestionSnapshotBySessionIdWithToken ?? [];

  const warningBox = t('sessionViewer.noticeBox.label');
  const warningBoxLevel = t('sessionViewer.noticeBox.variant') as SnackbarVariants;

  const intervieweeInfo = convertIntervieweeInfo(intervieweeDetails);

  return (
    <div tw="m-auto flex min-h-screen flex-col space-y-5 px-4 py-6 lg:(px-20 py-14)">
      <h3>รายละเอียดการสัมภาษณ์</h3>
      <div tw="sticky top-0">
        <Snackbar text={warningBox} variant={warningBoxLevel} />
      </div>
      <div tw="flex flex-col gap-2">
        <h5>ผู้สมัคร</h5>
        {sessionLoading ? (
          <IntervieweeCardSkeleton />
        ) : (
          <IntervieweeCard {...intervieweeInfo} viewer />
        )}
      </div>
      <div>
        <h5 tw="mb-2">วิดีโอการสัมภาษณ์</h5>
        {questionSnapshotLoading ? (
          <AccordionSkeleton lines={8} />
        ) : (
          <>
            {Array.isArray(questionSnapshot) && questionSnapshot.length === 0 ? (
              <div tw="flex h-full w-full flex-col items-center justify-center gap-2 rounded-2xl border bg-white p-14">
                <FaceFrownIcon tw="h-6 w-6" />
                <h5>ไม่พบการสัมภาษณ์</h5>
              </div>
            ) : (
              questionSnapshot.map((snapshot, index) => {
                return (
                  <Accordion
                    index={index}
                    key={`Accordion.${index}`}
                    summary={<QuestionDetails number={index + 1} title={snapshot.title} />}
                    detail={
                      <div tw="grid gap-4 w-full lg:grid-cols-2 grid-cols-1">
                        <IntervieweeVideoContainer
                          controls
                          src={snapshot.url ?? undefined}
                          fullWidth
                        />
                        <div tw="grid gap-2">
                          <TranscribeContainer
                            transcribedText={snapshot.transcribedText ?? ''}
                            jobRole={intervieweeInfo.position}
                            question={snapshot.title}
                            transcribedData={snapshot.processedTranscribedText}
                          />
                          {/* <div tw="border border-gray-200 rounded-2xl text-xl font-jamjuree w-full p-4 overflow-scroll flex flex-col gap-3">{snapshot.transcribedText}</div> */}
                        </div>
                      </div>
                    }
                  />
                );
              })
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default IntervieweeView;
