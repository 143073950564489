import csv from 'csvtojson';

import { BatchIntervieweeInput } from '@/libs/graphql';

export const convertIntervieweeDetails = async (
  intervieweeDetails: string,
): Promise<BatchIntervieweeInput[]> => {
  return intervieweeDetails
    .trim()
    .split('\n')
    .slice(1)
    .map((details) => {
      const interviewee = details.split(',');
      return {
        intervieweeName: interviewee[0],
        intervieweeEmail: interviewee[1],
        position: interviewee[2],
        refId: interviewee[3],
        refUrl: interviewee[4],
      };
    });
};

const COLUMN_MAPPING: { [key: string]: keyof BatchIntervieweeInput } = {
  'ชื่อ-สกุล': 'intervieweeName',
  Name: 'intervieweeName',
  อีเมล: 'intervieweeEmail',
  email: 'intervieweeEmail',
  ตำแหน่ง: 'position',
  โครงการที่สมัคร: 'position',
  โครงการ: 'position',
  LCP: 'refId',
  รหัสผู้สมัคร: 'refId',
  URL: 'refUrl',
};

const convertData = (data: { [key: string]: any }): BatchIntervieweeInput => {
  const intervieweeData: Partial<BatchIntervieweeInput> = {};
  Object.entries(data).forEach(([key, value]) => {
    const keyUpper = key.toUpperCase();
    const findKey = Object.entries(COLUMN_MAPPING).find(([ckey, cvalue]) => {
      // data เป็นด้านซ้ายของ mapping
      // ใช้ column mapping เพื่อบอกว่า data ตัวนี้จะเป็นอะไรในค่าจริง
      const keyMapUpper = ckey.toUpperCase();
      if (keyUpper === keyMapUpper) {
        intervieweeData[cvalue] = value;
      }
    });
  });
  return intervieweeData as BatchIntervieweeInput;
};

export const convertIntervieweeDetails2 = async (
  intervieweeDetails: string,
): Promise<BatchIntervieweeInput[]> => {
  const data = await csv().fromString(intervieweeDetails);
  return data.map(convertData);
};
