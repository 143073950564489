import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';

import { redirect } from 'react-router-dom';

import config from '@/utils/config';

import Markdown from './Markdown';

export default function PrivacyPolicy2() {
  // fetch privacy.md from the server
  const [text, setText] = useState('');
  useEffect(() => {
    if (config.privacyPolicyUrl) {
      console.log('redirecting to', config.privacyPolicyUrl);
      window.location = config.privacyPolicyUrl;
    } else {
      fetch(config.privacyPolicyMdUrl)
        .then((res) => res.text())
        .then((text) => {
          console.log(text);
          setText(text);
        });
    }
  }, []);
  return (
    <div tw={'max-w-2xl grid grid-cols-1 content-center m-auto'}>
      <Markdown text={text} />
    </div>
  );
}
