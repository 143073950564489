import tw from 'twin.macro';

const RecordingIcon = () => (
  <span tw="relative inline-block h-5 w-5">
    <span tw="absolute inline-flex h-full w-full animate-ping rounded-full bg-danger-400 opacity-75"></span>
    <span tw="relative inline-flex h-5 w-5 rounded-full bg-danger-500"></span>
  </span>
);

export default RecordingIcon;
