import { useState } from 'react';
import tw from 'twin.macro';

import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Card from '@/components/Card';
import CardSkeleton from '@/components/CardSkeleton';
import { Button, Modal } from '@/components/base';
import { SessionStatus } from '@/libs/graphql';
import { useInterviewSession } from '@/providers/InterviewSession';
import { Browser, useDetectBrowser } from '@/utils/useDetectBrowser';

import Markdown from '../completed/components/Markdown';

const Link = tw.a`cursor-pointer text-blue-500 underline hover:text-blue-600`;

const IntervieweeDetailColumn = ({ title, value }: { title: string; value: string }) => {
  return (
    <div tw="flex justify-between gap-1">
      <p>{title}</p>
      <p tw="basis-3/5 break-normal line-clamp-2">{value}</p>
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { interviewee, loading, duration, expiredAt, totalQuestions, status, company } =
    useInterviewSession();
  const userBrowser = useDetectBrowser();
  const isSupportedBrowser = [Browser.Safari, Browser.Chrome].includes(userBrowser);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(!isSupportedBrowser);

  const toggleIsModalOpen = () => {
    setIsModalOpen((prev) => !prev);
  };

  const [acceptPdpaConsent, setAcceptPdpaConsent] = useState<boolean>(false);

  const toggleAcceptPdpaConsent = () => {
    setAcceptPdpaConsent((prev) => !prev);
  };

  const navigateToPreparation = () => {
    navigate('/interviewee/preparation');
  };

  const sessionDuration = duration < 60 ? `${duration} นาที` : `${duration / 60} ชั่วโมง`;

  const instructions = t('interviewCover.instructions', {
    totalQuestions,
    returnObjects: true,
    defaultValue: [],
  }) as string[];
  const moreinstructions = t('interviewCover.moreinstructions', {
    sessionDuration,
    returnObjects: true,
    defaultValue: [],
  }) as string[];

  return (
    <>
      {isModalOpen ? (
        <Modal
          description={
            <div tw="flex flex-col items-center space-y-4 text-center">
              <h5>แจ้งเตือนการใช้งานระบบสัมภาษณ์</h5>
              <p tw="font-jamjuree">เพื่อการใช้งานที่ลื่นไหลกรุณาเข้าใช้งานผ่าน</p>
              <div tw="flex items-center gap-2">
                <div tw="flex items-center gap-1">
                  <img src="/assets/google-chrome.svg" alt="" />
                  <p>Chrome</p>
                </div>
                <p>หรือ</p>
                <div tw="flex items-center gap-1">
                  <img src="/assets/safari.svg" alt="" />
                  <p>Safari</p>
                </div>
              </div>
            </div>
          }
          footer={
            <div tw="flex w-full justify-center pb-8 pt-4">
              <Button onClick={toggleIsModalOpen}>รับทราบ</Button>
            </div>
          }
        />
      ) : null}
      <div tw="m-auto flex flex-col items-center space-y-4 px-4 py-8 max-w-[800px] sm:py-24">
        <div tw="flex w-full flex-col items-center justify-center gap-4 text-center">
          <h2>ยินดีต้อนรับ!</h2>
          {loading ? (
            <div tw="flex w-full animate-pulse flex-col items-center">
              <div tw="my-1 h-6 w-1/3 rounded-lg bg-slate-200" />
              <div tw="my-1 h-6 w-2/3 rounded-lg bg-slate-200" />
            </div>
          ) : (
            <h5>
              ยินดีต้อนรับ <span tw="text-primary">{interviewee?.name}</span> <br />
              สู่การสัมภาษณ์รอบ Video Interview กับ{' '}
              <span tw="text-primary">
                {t('interviewCover.companyLabel', { company: interviewee?.company })}
              </span>
            </h5>
          )}
          <div tw="py-4 sm:py-12">
            <img src="/assets/home-illustration.svg" alt="" />
          </div>
        </div>
        <div tw="flex w-full flex-col gap-4 md:flex-row">
          {loading ? (
            <CardSkeleton lines={3} />
          ) : (
            <Card
              tw="w-full sm:w-1/2"
              title="ข้อมูลผู้สมัคร"
              description={
                <>
                  {interviewee?.refId && (
                    <IntervieweeDetailColumn
                      title={`${t('intervieweeProfiles.refId.label')}:`}
                      value={interviewee?.refId ?? '-'}
                    />
                  )}
                  <IntervieweeDetailColumn
                    title={`${t('intervieweeProfiles.intervieweeName.label')}:`}
                    value={interviewee?.name ?? ''}
                  />
                  <IntervieweeDetailColumn
                    title={`${t('intervieweeProfiles.position.label')}:`}
                    value={interviewee?.position ?? ''}
                  />
                </>
              }
            />
          )}
          {loading ? (
            <CardSkeleton lines={3} />
          ) : (
            <Card
              tw="w-full sm:w-1/2"
              title="วันสิ้นสุดการเข้าสัมภาษณ์"
              description={
                <div tw="flex flex-col gap-4">
                  <p tw="font-jamjuree">
                    {dayjs(expiredAt).locale('th').format('DD MMMM YYYY HH:mm')}*
                  </p>
                  <p tw="text-xs">
                    หมายเหตุ หลังจากเลยเวลาดังกล่าวแล้วจะไม่สามารถเข้าร่วมการสัมภาษณ์ได้
                  </p>
                </div>
              }
            />
          )}
        </div>
        {loading ? (
          <CardSkeleton lines={5} />
        ) : (
          <Card
            tw="w-full"
            title="คำแนะนำในการสัมภาษณ์"
            description={
              <div>
                <ul tw="font-jamjuree">
                  {instructions.map((text, index) => (
                    <li key={index} tw="list-inside list-disc">
                      {text}
                    </li>
                  ))}
                </ul>
                <div tw="my-8 flex flex-col gap-4 sm:flex-row">
                  <div tw="space-y-4 text-center">
                    <img
                      src="/assets/home-preparation.png"
                      alt="a screenshot of preparation screen before interviewing"
                    />
                    <p tw="text-xs">การแสดงผลช่วงเตรียมตัว</p>
                  </div>
                  <div tw="space-y-4 text-center">
                    <img
                      src="/assets/home-recording.png"
                      alt="a screenshot of recording status in interview session"
                    />
                    <p tw="text-xs">การแสดงผลช่วงตอบคำถาม</p>
                  </div>
                </div>
                <ul tw="font-jamjuree">
                  {moreinstructions.map((text, index) => (
                    <li key={index} tw="list-inside list-disc">
                      {text}
                    </li>
                  ))}
                </ul>
              </div>
            }
          />
        )}
        {!loading && status === SessionStatus.NotStarted ? (
          <div tw="flex flex-row items-center gap-2">
            <label htmlFor="pdpa" tw="flex gap-2">
              <input
                id="pdpa"
                checked={acceptPdpaConsent}
                onChange={toggleAcceptPdpaConsent}
                type="checkbox"
                tw="h-5 w-5 self-start accent-primary shrink-0"
              />
              <Markdown text={t('interviewCover.termsCheckBoxMarkdown')} />
            </label>
          </div>
        ) : null}
        <Button
          tw="w-full sm:w-max"
          onClick={() => {
            if (isSupportedBrowser) {
              navigateToPreparation();
            } else {
              toggleIsModalOpen();
            }
          }}
          disabled={loading || (status === SessionStatus.NotStarted && !acceptPdpaConsent)}
        >
          เข้าสู่หน้าเตรียมตัว
        </Button>
      </div>
    </>
  );
};

export default Home;
