import React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import App from '@/App';
import GlobalStyles from '@/styles/GlobalStyles';
import '@/styles/globals.css';

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: 'https://d576bc1dba2546cf9a320173e4e79c77@o1054950.ingest.sentry.io/4504535147544576',

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    integrations: [new Integrations.BrowserTracing()],
    environment: import.meta.env.MODE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
);
