import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import tw from 'twin.macro';

import * as Sentry from '@sentry/browser';
import dayjs from 'dayjs';

import { useGetMySessionQuery, useUpdateSessionMutation } from '@/domains/session';
import { useGetTotalQuestionsQuery } from '@/domains/template';
import { Firebase } from '@/libs';
import { Maybe, SessionStatus, VideoUploadStatus } from '@/libs/graphql';
import config from '@/utils/config';

import { useAuthContext } from './Auth';
import { AUTH_STATE, AUTH_TYPE } from './types';

export type Interviewee = {
  uid: string;
  name: string;
  email: string;
  position?: string;
  company?: string;
  refId?: string;
};

export type Session = {
  authState: AUTH_STATE;
  interviewee?: Interviewee | null;
  questionTemplateID?: string;
  currentQuestion?: number;
  totalQuestions?: number;
  duration: number;
  videoUploadStatus: VideoUploadStatus;
  availableUntil: Date;
  expiredAt: Date;
  company?: string;
  status: SessionStatus;
  error?: string;
  startSession: () => Promise<void>;
  startQuestion: (time: number) => Promise<void>;
  nextQuestion: () => void;
  endSession: () => void;
  loading: boolean;
  videoUploadProgress: number;
  uploadFileToFirebase: (
    blob: Blob,
    company: string,
    sessionId: string,
    fileName?: string,
  ) => Promise<void>;
  endingMessage: Maybe<string | undefined>;
};

const InterviewSessionContext = createContext<Session | undefined>(undefined);

const { enableUploadVideo } = config;

const InterviewSessionProvider = ({ children }: { children?: ReactNode }) => {
  const { authState, authType } = useAuthContext();
  const [interviewee, setInterviewee] = useState<Interviewee | null>();
  const [questionTemplateID, setQuestionTemplateID] = useState<string>('default');
  const [currentQuestion, setCurrentQuestion] = useState<number>(1);
  const [duration, setDuration] = useState<number>(0);
  const [company, setCompany] = useState<string | undefined>();
  const [totalQuestions, setTotalQuestions] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [videoUploadStatus, setVideoUploadStatus] = useState<VideoUploadStatus>(
    VideoUploadStatus.NotStarted,
  );
  const [videoUploadProgress, setVideoUploadProgress] = useState<number>(0);
  const [error, setError] = useState<string>();
  const [endingMessage, setEndingMessage] = useState<Maybe<string | undefined>>(null);

  const {
    data: mySessionData,
    loading: mySessionLoading,
    error: mySessionError,
  } = useGetMySessionQuery({
    skip: authState !== AUTH_STATE.LOGGED_IN && authType !== AUTH_TYPE.INTERVIEWEE,
  });

  const session = mySessionData?.getMySession;

  const {
    data: totalQuestionsData,
    loading: totalQuestionLoading,
    error: totalQuestionError,
  } = useGetTotalQuestionsQuery({
    variables: {
      templateId: session?.questionTemplateID as string,
    },
    skip:
      !session?.questionTemplateID ||
      session.status === SessionStatus.Completed ||
      (session?.expiredAt &&
        new Date() > new Date(session?.expiredAt) &&
        session?.status === SessionStatus.NotStarted),
  });

  const availableUntil = session?.availableUntil;
  const expiredAt = session?.expiredAt;
  const status = session?.status as SessionStatus;

  const [
    updateSession,
    { data: updateSessionData, loading: updateSessionLoading, error: updateSessionError },
  ] = useUpdateSessionMutation({
    fetchPolicy: 'network-only',
    onError: (error) => {
      console.error(error);
      setError(error.message);
    },
  });

  useEffect(() => {
    if (session) {
      console.log('set session');
      console.log('session', session);
      console.log(currentQuestion, session?.currentQuestion);
      setInterviewee({
        uid: session?.id,
        name: session?.intervieweeName,
        email: session?.intervieweeEmail,
        position: session?.position,
        company: session?.company,
        refId: session?.refId ? session.refId : undefined,
      });
      Sentry.setContext('interviewee', {
        id: session?.id,
        name: session?.intervieweeName,
        email: session?.intervieweeEmail,
      });
      setQuestionTemplateID(session?.questionTemplateID ?? 'default');
      setCurrentQuestion(session?.currentQuestion ?? 0);
      setTotalQuestions(totalQuestionsData?.getTotalQuestions ?? 0);
      setLoading(mySessionLoading || totalQuestionLoading);
      setDuration(session?.duration ?? 0);
      setCompany(session?.company);
      // setVideoUploadStatus(session?.videoUploadStatus ?? VideoUploadStatus.NotStarted);
      setEndingMessage(session?.endingMessage);
    } else {
      console.log('clear session');
      setInterviewee(null);
    }
  }, [mySessionLoading, totalQuestionLoading]);

  const startSession = async () => {
    const current = session?.currentQuestion ? session.currentQuestion : 1;
    console.log('startSession', current, session?.status, SessionStatus.NotStarted, status);

    setCurrentQuestion(current);
    setLoading(true);
    if (status === SessionStatus.NotStarted) {
      await updateSession({
        variables: {
          input: {
            sessionID: interviewee?.uid as string,
            data: {
              status: SessionStatus.InProgress,
              currentQuestion: current,
              availableUntil: current === 1 ? dayjs().add(duration, 'minutes') : undefined,
              startAt: dayjs(),
              acceptPdpaConsent: true,
            },
          },
        },
      });
    }
    setLoading(false);
  };

  const nextQuestion = () => {
    setVideoUploadStatus(VideoUploadStatus.NotStarted);
    setCurrentQuestion((prev) => {
      Firebase.functions.nextQuestion(interviewee?.uid as string, prev + 1);
      return prev + 1;
    });
  };

  const endSession = async () => {
    setCurrentQuestion(0);
    await updateSession({
      variables: {
        input: {
          sessionID: interviewee?.uid as string,
          data: {
            status: SessionStatus.Completed,
            currentQuestion: 0,
            completedAt: new Date(),
            videoUploadStatus: VideoUploadStatus.NotStarted,
          },
        },
      },
    });
  };

  const startQuestion = async (time: number) => {
    await updateSession({
      variables: {
        input: {
          sessionID: interviewee?.uid as string,
          data: {
            currentQuestion: currentQuestion,
          },
          preparationTime: time,
        },
      },
    });
  };

  const handleChangeVideoUploadStatus = (status: VideoUploadStatus) => {
    Firebase.functions.updateVideoUploadStatus(interviewee?.uid as string, status);
    setVideoUploadStatus(status);
  };

  const uploadFileToFirebase = async (
    blob: Blob,
    company: string,
    sessionId: string,
    fileName?: string,
  ) => {
    const storageRef = await Firebase.storage.getStorageRef(
      company,
      sessionId,
      fileName ?? new Date().toISOString() + ' ข้อที่ ' + currentQuestion,
    );
    if (!storageRef || !enableUploadVideo) return;
    const uploadTask = Firebase.storage.uploadBytesResumable(storageRef, blob);
    handleChangeVideoUploadStatus(VideoUploadStatus.Uploading);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const uploadProgress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setVideoUploadProgress(uploadProgress);
      },
      (error) => {
        setError(error.message);
        Sentry.captureException(error);
        console.error(error);
      },
      async () => {
        try {
          const url = await Firebase.storage.getDownloadURL(uploadTask.snapshot.ref);
          await updateSession({
            variables: {
              input: {
                sessionID: interviewee?.uid as string,
                data: { currentQuestion: currentQuestion },
                videoUrl: url,
              },
            },
          });
          handleChangeVideoUploadStatus(VideoUploadStatus.Uploaded);
        } catch (error) {
          Sentry.captureException(error);
          console.error(error);
          throw error;
        }

      },
    );
  };

  if (!interviewee) {
    return <div tw="grid h-screen w-screen place-items-center text-2xl">Loading...</div>;
  }

  return (
    <InterviewSessionContext.Provider
      value={{
        authState,
        interviewee,
        questionTemplateID,
        availableUntil,
        expiredAt,
        company,
        currentQuestion,
        totalQuestions,
        startQuestion,
        duration,
        status,
        videoUploadStatus,
        error,
        videoUploadProgress,
        uploadFileToFirebase,
        startSession,
        nextQuestion,
        endSession,
        loading,
        endingMessage,
      }}
    >
      {children}
    </InterviewSessionContext.Provider>
  );
};

const useInterviewSession = (): Session => {
  const context = useContext(InterviewSessionContext);
  if (context === undefined) {
    throw new Error('useInterviewSession must be used within InterviewSessionProvider');
  }
  return context;
};

export { InterviewSessionContext, useInterviewSession };
export default InterviewSessionProvider;
