import { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';

import { PlayIcon, VideoCameraSlashIcon } from '@heroicons/react/24/solid';

import Video from '@/components/Video';
import { StatusMessages } from '@/utils/useMediaRecorder';

type VideoInterviewProps = {
  loading?: boolean;
  blobUrl?: string;
  mediaStream: MediaStream | null;
  mirror?: boolean;
  status?: StatusMessages;
  isInterviewSession?: boolean;
};

const VideoInterview = (props: VideoInterviewProps): JSX.Element => {
  const {
    mediaStream,
    status,
    blobUrl,
    mirror = true,
    isInterviewSession = false,
    loading = false,
  } = props;
  const streamRef = useRef<HTMLVideoElement>(null);
  const previewRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const toggleIsPlaying = () => setIsPlaying((prev) => !prev);

  const toggleVideo = () => {
    if (previewRef?.current?.paused) {
      previewRef?.current?.play();
      toggleIsPlaying();
    } else {
      previewRef?.current?.pause();
      toggleIsPlaying();
    }
  };

  useEffect(() => {
    if (streamRef.current && mediaStream) {
      streamRef.current.srcObject = mediaStream;
    }
  }, [mediaStream]);

  useEffect(() => {
    setIsPlaying(false);
  }, [blobUrl]);

  if (blobUrl && status === StatusMessages.STOPPED && !isInterviewSession && !loading) {
    return (
      <div
        tw="relative flex cursor-pointer flex-col justify-center text-center"
        onClick={toggleVideo}
      >
        <Video
          mirror={mirror}
          src={blobUrl}
          ref={previewRef}
          playsInline
          onEnded={toggleIsPlaying}
        />
        {!isPlaying ? (
          <div tw="absolute z-10 h-24 w-24 place-self-center rounded-full bg-gray-100 p-6 text-gray-700">
            <PlayIcon />
          </div>
        ) : (
          <div tw="absolute top-0 z-10 m-2 rounded-xl bg-white p-2 text-black">
            <p>กำลังเล่น..</p>
          </div>
        )}
      </div>
    );
  } else if (blobUrl && status === StatusMessages.STOPPED && isInterviewSession) {
    return (
      <div tw="relative flex flex-col justify-center text-center">
        <Video mirror={mirror} src={blobUrl} ref={previewRef} />
        <div tw="absolute flex h-full w-full select-none place-items-center justify-center rounded-xl bg-black bg-opacity-60 text-white">
          <h5>จบการบันทึกวิดีโอ</h5>
        </div>
      </div>
    );
  } else if (
    status &&
    [StatusMessages.PERMISSION_DENIED, StatusMessages.INIT_FAILED].includes(status)
  ) {
    return (
      <div tw="relative flex flex-col justify-center rounded-2xl bg-black text-center aspect-[4/3] h-[450px]">
        <div tw="absolute z-10 m-2 h-20 w-20 place-self-center text-danger-500">
          <VideoCameraSlashIcon />
        </div>
      </div>
    );
  }
  return (
    <div tw="relative flex flex-col justify-center text-center">
      <Video
        css={[status === StatusMessages.RECORDING && tw`border-4 border-danger-500`]}
        autoPlay
        ref={streamRef}
        mirror={mirror}
        playsInline
      />
      {status === StatusMessages.IDLE ? (
        <div tw="absolute flex h-full w-full select-none place-items-center justify-center rounded-xl bg-black bg-opacity-60 text-white">
          <p tw="text-2xl">
            ช่วงเตรียมตัว
            <br /> ขณะนี้ยังไม่เริ่มการบันทึกวิดีโอ
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default VideoInterview;
