import tw from 'twin.macro';

const SessionNotAvailable = () => {
  return (
    <section tw="grid min-h-screen place-items-center">
      <div tw="mx-auto max-w-screen-xl py-8 px-4 lg:(py-16 px-6)">
        <div tw="mx-auto max-w-screen-sm text-center">
          <h1 tw="mb-4 font-extrabold md:tracking-tight">ไม่อยู่ในเวลาการสัมภาษณ์</h1>
          <p tw="mb-8 font-light text-lg text-gray-500">
            ไม่สามารถเข้าสู่การสัมภาษณ์รอบ Video Interview ได้
          </p>
        </div>
      </div>
    </section>
  );
};

export default SessionNotAvailable;
