import tw from 'twin.macro';

const TailwindIndicator = () => {
  if (import.meta.env.PROD) return null;

  return (
    <div tw="fixed bottom-2 left-2 z-50 flex h-6 w-6 items-center justify-center rounded-full bg-gray-800 p-3 text-xs text-white">
      <div tw="block sm:hidden">xs</div>
      <div tw="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">sm</div>
      <div tw="hidden md:block lg:hidden xl:hidden 2xl:hidden">md</div>
      <div tw="hidden lg:block xl:hidden 2xl:hidden">lg</div>
      <div tw="hidden xl:block 2xl:hidden">xl</div>
      <div tw="hidden 2xl:block">2xl</div>
    </div>
  );
};

export default TailwindIndicator;
