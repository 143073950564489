import { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';

import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import Card from '@/components/Card';
import Snackbar from '@/components/Snackbar';
import { Button, Input } from '@/components/base';
import { useAuthContext } from '@/providers/Auth';
import { AUTH_STATE } from '@/providers/types';

import { LOGIN_ERROR_MESSAGES } from './constants';
import { AUTH_ERROR, AuthErrorObject, FormValues } from './types';
import googleIcon from '/assets/google.svg';

const ErrorMessage = tw.p`mt-1 text-sm text-danger-500`;

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authState, signInWithGoogle, signInWithEmailAndPassword } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AUTH_ERROR>();

  const loginWithGoogle = async () => {
    await signInWithGoogle();
  };

  const loginWithEmailAndPassword = async ({ email, password }: FormValues) => {
    try {
      setLoading(true);
      await signInWithEmailAndPassword(email, password);
    } catch (error: unknown) {
      setLoading(false);
      const e = error as AuthErrorObject;
      setError(e.code ?? e.message);
    }
  };

  useEffect(() => {
    if (authState === AUTH_STATE.LOGGED_IN) {
      navigate(location.state?.from || '/backoffice/dashboard');
    }
    return () => {
      //
    };
  }, [authState, location, navigate]);

  return (
    <div tw="flex min-h-screen w-full flex-col items-center justify-center px-4 py-6 sm:(px-10 py-12)">
      <Card
        tw="w-full p-8 md:w-1/2 lg:w-1/3"
        title=""
        description={
          <div tw="space-y-4">
            <h5>เข้าสู่ระบบ</h5>
            {error ? (
              <Snackbar
                variant="error"
                text={LOGIN_ERROR_MESSAGES[error] ?? 'เกิดข้อผิดพลาดขึ้น กรุณาลองใหม่อีกครั้ง'}
              />
            ) : null}
            <form tw="space-y-1" onSubmit={handleSubmit(loginWithEmailAndPassword)}>
              <div>
                <label htmlFor="email" tw="block font-semibold text-gray-700">
                  อีเมล
                </label>
                <Input
                  id="email"
                  placeholder="กรุุณากรอกอีเมล"
                  autoFocus
                  css={[errors.email && tw`border-danger-500`]}
                  {...register('email', {
                    required: { message: 'กรุณากรอกอีเมล', value: true },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'กรุณากรอกอีเมลที่ถูกต้อง',
                    },
                  })}
                />
                <ErrorMessage css={[tw`invisible`, errors.email && tw`visible`]}>
                  {errors.email?.message ?? 'กรุณากรอกอีเมล'}
                </ErrorMessage>
              </div>
              <div>
                <label htmlFor="password" tw="font-semibold text-gray-700">
                  รหัสผ่าน
                </label>
                <Input
                  type="password"
                  id="password"
                  placeholder="กรุณากรอกรหัสผ่าน"
                  autoComplete="password"
                  css={[errors.password && tw`border-danger-500`]}
                  {...register('password', { required: true })}
                />
                <ErrorMessage css={[tw`invisible`, errors.password && tw`visible`]}>
                  กรุณากรอกรหัสผ่าน
                </ErrorMessage>
              </div>
              <Button
                tw="block w-full bg-primary! hover:bg-primary-900! disabled:bg-gray-300!"
                disabled={loading || error === AUTH_ERROR.TOO_MANY_REQUESTS}
                type="submit"
              >
                เข้าสู่ระบบ
              </Button>
            </form>
            <hr tw="border-gray-300" />
            <Button
              type="button"
              variant="info"
              tw="w-full border border-gray-300"
              onClick={loginWithGoogle}
            >
              <div tw="flex items-center justify-center">
                <img src={googleIcon} alt="icon of Google" tw="h-5 w-5" />
                <span tw="ml-4">เข้าสู่ระบบด้วย Google</span>
              </div>
            </Button>
          </div>
        }
      />
    </div>
  );
};

export default Login;
