import { ReactNode } from 'react';
import tw from 'twin.macro';

import {
  ArrowLeftOnRectangleIcon,
  ChevronLeftIcon,
  FaceSmileIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';

import config from '@/utils/config';

import Button from './Button';

const { helpCenterUrl, theme } = config;

const Container = tw.div`flex w-screen flex-col border-neutral border-r bg-white min-w-[240px] sm:(h-screen w-[240px]) duration-100`;

const MENU_LIST = [
  {
    text: 'รายงานปัญหา',
    icon: <QuestionMarkCircleIcon />,
    onClick: undefined,
    customProps: {
      as: 'a',
      href: helpCenterUrl,
      target: '_blank',
    },
  },
];

const Menu = ({
  icon,
  text,
  onClick,
  ...props
}: {
  icon: ReactNode;
  text: string;
  onClick?: () => void;
}) => {
  return (
    <div
      css={[tw`flex space-x-3 px-4 py-2`, onClick && tw`cursor-pointer`]}
      onClick={onClick}
      {...props}
    >
      <div tw="grid h-8 w-8 place-items-center text-gray-500">{icon}</div>
      <div
        tw="my-auto w-full overflow-hidden whitespace-nowrap text-gray-500"
        style={{
          textOverflow: 'ellipsis',
        }}
      >
        {text}
      </div>
    </div>
  );
};

type SidebarProps = {
  content?: ReactNode;
  name?: string;
  signOut?: () => void;
  show?: boolean;
  toggle?: () => void;
};

const Sidebar: React.FC<SidebarProps> = ({ content, name, signOut, show = true, toggle }) => (
  <Container css={[!show && tw`-ml-[200px]`]}>
    <div tw="border-neutral border-b p-2 pl-4 sm:p-6 relative">
      <img src={theme.logoUrl} alt="skooldio" tw="md:max-h-[220px] max-h-[100px]"/>
      {toggle ? (
        <Button
          tw="hidden lg:block p-2 rounded-full absolute -bottom-4 -right-5"
          onClick={() => toggle?.()}
        >
          <ChevronLeftIcon css={[tw`w-5 h-5 duration-100`, !show && tw`rotate-180`]} />
        </Button>
      ) : null}
    </div>
    <div tw="hidden p-4 sm:block">{content}</div>
    <div tw="mt-auto mb-4 hidden border-neutral border-t pt-2 sm:block">
      {name && <Menu icon={<FaceSmileIcon />} text={name} />}
      {MENU_LIST.map(({ text, icon, onClick, customProps }, index) => (
        <Menu key={index} icon={icon} text={text} onClick={onClick} {...customProps} />
      ))}
      {signOut && <Menu icon={<ArrowLeftOnRectangleIcon />} text="ออกจากระบบ" onClick={signOut} />}
    </div>
  </Container>
);

export default Sidebar;
