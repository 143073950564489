import tw, { css, styled, theme } from 'twin.macro';

type ButtonVariant = 'primary' | 'error' | 'info' | 'success' | 'outlined';

type ButtonProps = {
  variant?: ButtonVariant;
  isSmall?: boolean;
};

const Button = styled.button(({ variant, isSmall }: ButtonProps) => [
  /* Base Styling */
  tw`rounded-lg px-8 py-2 font-medium transition-colors duration-100`,

  /* Variant: Primary */
  (!variant || variant === 'primary') && tw`bg-primary text-white hover:bg-primary-900`,

  /* Variant: Error */
  variant === 'error' && tw`bg-red-500 text-white hover:bg-red-700`,

  /* Variant: Success */
  variant === 'success' && tw`bg-success-500 text-white hover:bg-success-900`,

  /* Variant: Info */
  variant === 'info' && tw`border bg-white text-gray-900 hover:bg-gray-100`,

  /* Variant: Outlined */
  variant === 'outlined' && tw`border-2 border-gray-500 bg-transparent text-black`,

  /* Disabled Styling */
  tw`disabled:(cursor-not-allowed bg-gray-200 text-gray-400) hover:disabled:bg-gray-200`,
]);

export default Button;
