import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import config from '@/utils/config';

import { Firebase } from '..';

// const API_URL = 'https://asia-southeast1-skooldio-video-interview.cloudfunctions.net/graphql';
// const API_URL = 'http://127.0.0.1:5001/skooldio-video-interview/asia-southeast1/graphql';
const { baseApiUrl: API_URL } = config;

const httpLink = createHttpLink({
  uri: API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token: string | undefined = undefined;
  try {
    token = await Firebase.auth.default.currentUser?.getIdToken(true);
  } catch (error) {
    // some endpoint may not require authentication
    console.error(error);
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const errorLink = new ApolloLink((operation, forward) => {
  const operationName =
    operation.operationName.charAt(0).toLowerCase() + operation.operationName.slice(1);
  return forward(operation).map((response) => {
    if (response?.data && operationName in response?.data) {
      const { code } = response.data[operationName];
      if (code && code !== 200) {
        const errorObject = { ...response.data[operationName] };
        // Sentry.captureMessage(`GRAPHQL ERROR: ${operationName} ${JSON.stringify(errorObject)}`);
      }
    }
    return response;
  });
});

// const sentryLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.map(({ message }) => Sentry.captureMessage(`GRAPHQL ERROR: ${message}`));
//   if (networkError) {
//     Sentry.captureException(networkError);
//   }
// });

const appLink = from([
  authLink,
  errorLink,
  httpLink,
  // sentryLink
]);

const client = new ApolloClient({
  link: appLink,
  cache: new InMemoryCache(),
});

export default client;
