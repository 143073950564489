import tw from 'twin.macro';

import Card from '@/components/Card';
import { useInterviewSession } from '@/providers/InterviewSession';

import Markdown from './components/Markdown';
import { useTranslation } from 'react-i18next';

const parseToMarkDown = (str: string): string => {
  return str.replace(/\\n/g, '\n');
};

const Completed = () => {
  const { endingMessage } = useInterviewSession();
  const { t } = useTranslation();

  return (
    <>
      <div tw="m-auto flex flex-col items-center space-y-4 px-4 py-8 max-w-[800px] sm:py-24">
        <h3>จบการสัมภาษณ์ 🎉</h3>
        <Card
          tw="w-full"
          title="ยินดีด้วย"
          description={<p tw="font-jamjuree">คุณตอบคำถามสัมภาษณ์ครบทุกข้อแล้ว 🎉</p>}
        />
        <Card
          tw="w-full"
          title="ขั้นตอนถัดไป"
          description={
            <>
              {endingMessage ? (
                <Markdown text={parseToMarkDown(endingMessage)} />
              ) : (
                <p tw="font-jamjuree">
                  <Markdown text={parseToMarkDown(t('interviewCover.finishInterviewNextStepMarkdown'))} />
                </p>
              )}
            </>
          }
        />
        <div tw="pt-11">
          <img src="/assets/completed-illustration.svg" alt="" />
        </div>
      </div>
    </>
  );
};

export default Completed;
