import tw from 'twin.macro';

import { EyeIcon } from '@heroicons/react/24/solid';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Session, SessionStatus } from '@/libs/graphql';

import { getSessionStatusText } from '../../../utils/getSessionStatusText';

const columnHelper = createColumnHelper<Partial<Session>>();

const SessionDataTable = ({ sessions }: { sessions?: Partial<Session>[] }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const columns = [
    columnHelper.accessor('refId', {
      header: () => (
        <span>
          {i18n.exists('intervieweeProfiles.refId.tableLabel')
            ? t('intervieweeProfiles.refId.tableLabel')
            : t('intervieweeProfiles.refId.label')}
        </span>
      ),
      cell: (props) => props.getValue() ?? '-',
    }),
    columnHelper.accessor('intervieweeName', {
      header: () => (
        <span>
          {i18n.exists('intervieweeProfiles.intervieweeName.tableLabel')
            ? t('intervieweeProfiles.intervieweeName.tableLabel')
            : t('intervieweeProfiles.intervieweeName.label')}
        </span>
      ),
      cell: (props) => props.getValue(),
    }),
    columnHelper.accessor('intervieweeEmail', {
      header: () => (
        <span>
          {i18n.exists('intervieweeProfiles.intervieweeEmail.tableLabel')
            ? t('intervieweeProfiles.intervieweeEmail.tableLabel')
            : t('intervieweeProfiles.intervieweeEmail.label')}
        </span>
      ),
      cell: (props) => props.getValue(),
    }),
    columnHelper.accessor('position', {
      header: () => (
        <span>
          {i18n.exists('intervieweeProfiles.position.tableLabel')
            ? t('intervieweeProfiles.position.tableLabel')
            : t('intervieweeProfiles.position.label')}
        </span>
      ),
      cell: (props) => props.getValue(),
    }),

    columnHelper.accessor('questionTemplateName', {
      header: () => <span>ชุดคำถาม</span>,
      cell: (props) => props.getValue(),
    }),
    columnHelper.accessor('status', {
      header: () => <span>สถานะ</span>,
      cell: ({ row }) =>
        getSessionStatusText(
          row.original.status as SessionStatus,
          row.original.availableUntil,
          row.original.expiredAt,
        ),
    }),
    columnHelper.accessor('completedAt', {
      header: () => <span>เวลาที่สัมภาษณ์เสร็จ</span>,
      cell: (props) =>
        props.getValue() ? dayjs(props.getValue()).format('DD/MM/YYYY HH:mm') : '-',
    }),
    columnHelper.accessor('id', {
      header: () => <span>ดูข้อมูล</span>,
      cell: (props) => (
        <EyeIcon
          tw="h-5 w-5 cursor-pointer"
          onClick={() => navigate(`/backoffice/interviewee/${props.getValue()}`)}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data: sessions as Partial<Session>[],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div tw="overflow-auto rounded-lg border-2 bg-white px-4">
      <table tw="table-auto w-full">
        <thead tw="border-b-2">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              css={[
                tw`border-b text-sm`,
                sessions && index === sessions?.length - 1 && tw`border-none`,
              ]}
            >
              {row.getVisibleCells().map((cell) => (
                <td tw="py-1 px-1" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SessionDataTable;
