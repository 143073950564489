import tw from 'twin.macro';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { FormValues } from '../types';

type IntervieweeDetailsProps = {
  intervieweeName?: string;
  intervieweeEmail?: string;
  position?: string;
  questionTemplateID?: string;
  duration?: number;
  expiredAt?: Date;
};

const IntervieweeDetails: React.FC<IntervieweeDetailsProps> = (props) => {
  const { intervieweeName, intervieweeEmail, position, questionTemplateID, duration, expiredAt } =
    props;
  const { t } = useTranslation();
  return (
    <div tw="mt-8 flex gap-4">
      <div>
        <img tw="h-20 w-20 sm:(h-32 w-32)" src="/assets/people-placeholder.png" alt="skooldio" />
      </div>
      <div tw="flex flex-col">
        <p tw="font-semibold text-xl">{intervieweeName}</p>
        <p tw="text-gray-600">{intervieweeEmail}</p>
        <div tw="mt-4 flex flex-col space-y-1">
          <p>
            <span tw="font-semibold">{t('intervieweeProfiles.position.label')}</span> <br /> {`${position}`}
          </p>
          <p>
            <span tw="font-semibold">ชุดคำถาม</span> <br /> {`${questionTemplateID}`}
          </p>
          <p>
            <span tw="font-semibold">สัมภาษณ์ได้ถึง</span>
            <br />
            วันที่ {dayjs(expiredAt).format('DD/MM/YYYY HH:mm')}น.
          </p>
          <p>
            <span tw="font-semibold">ระยะเวลาการสัมภาษณ์</span> <br />
            {`${duration} ชม.`}
          </p>
        </div>
      </div>
    </div>
  );
};
export default IntervieweeDetails;
