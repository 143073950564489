import { createBrowserRouter } from 'react-router-dom';

import { Layout } from '@/components/base';
import { BackofficeSidebarContainer, SidebarContainer } from '@/containers';
import {
  BO_ROUTES,
  BackofficeRoute,
  InterviewSessionRoute,
  PROTECTED_BO_ROUTES,
  PROTECTED_ROUTES,
  PROTECTED_VIEWER_ROUTES,
  PageNotFound,
  ROUTES,
  ViewerRoute,
} from '@/routes/index';

import BackofficeProvider from './providers/Backoffice';
import InterviewSessionProvider from './providers/InterviewSession';
import Logout from './routes/logout';
import PrivacyPolicy from './routes/pdpa/Privacy2';
import ViewerProvider from './providers/Viewer';

const router = createBrowserRouter([
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
    errorElement: <PageNotFound />,
  },
  {
    path: '/interviewee',
    element: (
      <InterviewSessionProvider>
        <InterviewSessionRoute>
          <Layout sidebar={<SidebarContainer />} />
        </InterviewSessionRoute>
      </InterviewSessionProvider>
    ),
    children: PROTECTED_ROUTES,
    errorElement: <PageNotFound />,
  },
  {
    path: '/backoffice',
    element: (
      <BackofficeProvider>
        <BackofficeRoute>
          <Layout sidebar={<BackofficeSidebarContainer />} />
        </BackofficeRoute>
      </BackofficeProvider>
    ),
    children: PROTECTED_BO_ROUTES,
    errorElement: <PageNotFound />,
  },
  {
    path: '/backoffice',
    element: <Layout />,
    children: BO_ROUTES,
    errorElement: <PageNotFound />,
  },
  {
    path: '/result',
    element: (
      <ViewerProvider>
        <ViewerRoute>
          <Layout sidebar={<></>} />
        </ViewerRoute>
      </ViewerProvider>
    ),
    children: PROTECTED_VIEWER_ROUTES,
    errorElement: <PageNotFound />,
  },
  {
    path: '/interviewee',
    element: <Layout />,
    children: ROUTES,
    errorElement: <PageNotFound />,
  },
  {
    path: '/logout',
    element: <Logout />,
    errorElement: <PageNotFound />,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]);

export default router;
