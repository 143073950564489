import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import tw from 'twin.macro';

import * as Sentry from '@sentry/browser';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetMyProfileQuery } from '@/domains/user';
import { Roles } from '@/libs/graphql';

import { useAuthContext } from './Auth';
import { User } from './Backoffice';
import { AUTH_STATE, AUTH_TYPE } from './types';

export type ViewerCtx = {
  authState: AUTH_STATE;
  user?: User | null;
  error?: string;
  loading: boolean;
};

const ViewerContext = createContext<ViewerCtx | undefined>(undefined);

const ViewerProvider = ({ children }: { children?: ReactNode }) => {
  const { authState, authType, authUserData } = useAuthContext();
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('checking auth state', authState, authType);
    if (authState === AUTH_STATE.LOGGED_IN) {
      if (authType !== AUTH_TYPE.BACKOFFICE) {
        console.log('not backoffice, logout');
        navigate('/logout');
      }
    } else if (authState === AUTH_STATE.NOT_LOGGED_IN) {
      console.log('not logged in, login');
      navigate('/backoffice/login', { state: { from: location.pathname + location.search } });
    }

    return () => {};
  }, [authState, authType]);

  useEffect(() => {
    if (authUserData) {
      setUser({
        name: authUserData.displayName ?? 'Anonymous',
        email: authUserData.email ?? '',
        roles: [],
      });
      Sentry.setContext('user', {
        id: authUserData.uid,
        name: authUserData.displayName,
        email: authUserData.email,
      });
      setLoading(false);
    } else {
      setUser(null);
    }
  }, [authUserData]);

  if (!user) {
    return <div tw="grid h-screen w-screen place-items-center text-2xl">Loading...</div>;
  }

  return (
    <ViewerContext.Provider
      value={{
        user,
        authState,
        error,
        loading,
      }}
    >
      {children}
    </ViewerContext.Provider>
  );
};

const useViewerContext = (): ViewerCtx => {
  const context = useContext(ViewerContext);
  if (context === undefined) {
    throw new Error('useBackofficeContext must be used within BackofficeProvider');
  }
  return context;
};

export { ViewerContext, useViewerContext };
export default ViewerProvider;
