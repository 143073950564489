import tw from 'twin.macro';

const CircularProgress = ({ value }: { value: number }) => {
  const radius = 10;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (value / 100) * circumference;
  return (
    <svg tw="h-6 w-6 -rotate-90 ease-linear">
      <circle
        tw="stroke-info-500 stroke-[5px] [stroke-linecap: round]"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx={12}
        cy={12}
      />
    </svg>
  );
};

export default CircularProgress;
