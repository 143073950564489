import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import tw from 'twin.macro';

import { Firebase } from '@/libs';

import { AUTH_STATE, AUTH_TYPE } from './types';

export type AuthContextData = {
  authState: AUTH_STATE;
  authType: AUTH_TYPE | undefined;
  authUserData: FirebaseUserData | null;
  signInWithCustomToken: (token: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<void>;
};

const AuthContext = createContext<AuthContextData | undefined>(undefined);

type FirebaseUserData = {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoURL?: string | null;
};

const AuthProvider = ({ children }: { children?: ReactNode }) => {
  const [authState, setAuthState] = useState(AUTH_STATE.INITIALIZING);
  const [authType, setAuthType] = useState<AUTH_TYPE>();
  const [userData, setUserData] = useState<FirebaseUserData|null>(null);

  useEffect(() => {
    const unsubscribe = Firebase.auth.default.onAuthStateChanged((user) => {
      if (user && user.providerData.length !== 0) {
        const firebaseUserData = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        };
        setAuthType(AUTH_TYPE.BACKOFFICE);
        setAuthState(AUTH_STATE.LOGGED_IN);
        setUserData(firebaseUserData);
      } else if (user && user.providerData.length === 0) {
        setAuthType(AUTH_TYPE.INTERVIEWEE);
        setAuthState(AUTH_STATE.LOGGED_IN);
      } else {
        setAuthState(AUTH_STATE.NOT_LOGGED_IN);
      }
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = async () => {
    await Firebase.auth.signInWithGoogle();
  };

  const signInWithEmailAndPassword = async (email: string, password: string) => {
    const providers = await Firebase.auth.fetchSignInMethodsForEmail(email);
    if (providers.length !== 0 && !providers.includes('password')) {
      throw new Error('auth/wrong-provider');
    }
    await Firebase.auth.signInWithEmailAndPassword(email, password);
  };

  const signInWithCustomToken = async (token: string) => {
    await Firebase.auth.signInWithCustomToken(token);
  };

  if (authState === AUTH_STATE.INITIALIZING) {
    return <div tw="grid h-screen w-screen place-items-center text-2xl">Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        authState,
        authType,
        authUserData: userData,
        signInWithCustomToken,
        signInWithGoogle,
        signInWithEmailAndPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = (): AuthContextData => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within AuthProvider');
  }
  return context;
};

export { AuthContext, useAuthContext };
export default AuthProvider;
