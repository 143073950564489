import { ReactNode } from 'react';
import tw from 'twin.macro';

import { Outlet } from 'react-router-dom';

type LayoutProps = {
  sidebar?: ReactNode;
};

const Container = tw.div`flex h-screen w-screen flex-col sm:flex-row`;

const Layout: React.FC<LayoutProps> = ({ sidebar }) => (
  <Container>
    {sidebar ? sidebar : null}
    <div tw="w-full overflow-y-scroll">
      <Outlet />
    </div>
  </Container>
);

export default Layout;
