import tw from 'twin.macro';

import { Navigate, useLocation } from 'react-router-dom';

import { Roles, SessionStatus } from '@/libs/graphql';
import { useBackofficeContext } from '@/providers/Backoffice';
import { useInterviewSession } from '@/providers/InterviewSession';
import { useViewerContext } from '@/providers/Viewer';
import { AUTH_STATE } from '@/providers/types';

import Completed from './completed';
import CreateSession from './create-session';
import Dashboard from './dashboard';
import Home from './home';
import Interviewee from './interviewee';
import IntervieweeView from './interviewee-view';
import Login from './login';
import LoginWithToken from './login-with-token';
import SessionNotAvailable from './not-available';
import PageNotFound from './not-found';
import Preparation from './preparation';
import Session from './session';

const PATHS = {
  HOME: '/interviewee/home',
  PREPARATION: '/interviewee/preparation',
  SESSION: '/interviewee/session/:id',
  COMPLETED: '/interviewee/completed',
  NOT_AVAILABLE: '/interviewee/session-not-available',
  LOGIN_WITH_TOKEN: '/interviewee/login/:id',
  LOGIN: '/backoffice/login',
  CREATE_SESSION: '/backoffice/create-session',
  DASHBOARD: '/backoffice/dashboard',
  INTERVIEWEE: '/backoffice/interviewee/:intervieweeId',
  INTERVIEWEE_VIEW: '/result/interviewee/:intervieweeId',
};

const ADMIN_PROTECTED_ROUTES = [PATHS.CREATE_SESSION];

const ROUTES = [
  {
    path: PATHS.NOT_AVAILABLE,
    element: <SessionNotAvailable />,
  },
  {
    path: PATHS.LOGIN_WITH_TOKEN,
    element: <LoginWithToken />,
  },
];

const BO_ROUTES = [
  {
    path: PATHS.LOGIN,
    element: <Login />,
  },
];

const PROTECTED_BO_ROUTES = [
  {
    path: PATHS.CREATE_SESSION,
    element: <CreateSession />,
  },
  {
    path: PATHS.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: PATHS.INTERVIEWEE,
    element: <Interviewee />,
  },
];

const PROTECTED_VIEWER_ROUTES = [
  {
    path: PATHS.INTERVIEWEE_VIEW,
    element: <IntervieweeView />,
  },
];

const PROTECTED_ROUTES = [
  {
    path: PATHS.HOME,
    element: <Home />,
  },
  {
    path: PATHS.PREPARATION,
    element: <Preparation />,
  },
  {
    path: PATHS.SESSION,
    element: <Session />,
  },
  {
    path: PATHS.COMPLETED,
    element: <Completed />,
  },
];

const InterviewSessionRoute = ({ children }: { children: JSX.Element }) => {
  const { authState, availableUntil, status, expiredAt } = useInterviewSession();

  const isLinkExpired =
    expiredAt && new Date() > new Date(expiredAt) && status === SessionStatus.NotStarted;
  const isInterviewSessionNotAvailable = availableUntil && new Date() > new Date(availableUntil);
  const { pathname } = useLocation();

  if (authState !== AUTH_STATE.LOGGED_IN) {
    return <Navigate to={'/not-found'} replace />;
  } else if (isLinkExpired || isInterviewSessionNotAvailable) {
    return <Navigate to={PATHS.NOT_AVAILABLE} replace />;
  } else if (status == SessionStatus.Completed && pathname !== PATHS.COMPLETED) {
    return <Navigate to={PATHS.COMPLETED} replace />;
  }
  return children;
};

const BackofficeRoute = ({ children }: { children: JSX.Element }) => {
  const { authState, user } = useBackofficeContext();
  const location = useLocation();

  if (user?.roles?.includes(Roles.ViewOnly) && ADMIN_PROTECTED_ROUTES.includes(location.pathname)) {
    return <Navigate to={PATHS.DASHBOARD} replace />;
  }
  if (authState !== AUTH_STATE.LOGGED_IN) {
    return <Navigate to={PATHS.LOGIN} replace state={{ from: location.pathname + location.search }} />;
  }
  return children;
};

const ViewerRoute = ({ children }: { children: JSX.Element }) => {
  const { authState, user } = useViewerContext();
  const location = useLocation();

  if (authState !== AUTH_STATE.LOGGED_IN) {
    return <Navigate to={PATHS.LOGIN} replace state={{ from: location.pathname + location.search }} />;
  }
  return children;
};

export {
  PATHS,
  ROUTES,
  PROTECTED_ROUTES,
  BO_ROUTES,
  PROTECTED_BO_ROUTES,
  PROTECTED_VIEWER_ROUTES,
  PageNotFound,
  BackofficeRoute,
  ViewerRoute,
  InterviewSessionRoute,
};
