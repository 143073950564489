import tw from 'twin.macro';

import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';

export type SnackbarVariants = 'error' | 'info' | 'success' | 'warning';

type SnackbarProps = {
  icon?: JSX.Element;
  text: string;
  variant: SnackbarVariants;
};

type StylesProps = {
  variant: SnackbarVariants;
};

const SnackbarBackgroundVariants = {
  error: tw`bg-danger-100`,
  info: tw`bg-info-100`,
  success: tw`bg-success-100`,
  warning: tw`bg-warning-100`,
};

const SnackbarIconVariants = {
  error: tw`text-danger-500`,
  info: tw`text-info-500`,
  success: tw`text-success-500`,
  warning: tw`text-warning-500`,
};

const SnackbarTextVariants = {
  error: tw`text-danger-900`,
  info: tw`text-info-900`,
  success: tw`text-success-900`,
  warning: tw`text-warning-900`,
};

const styles = {
  bg: ({ variant }: StylesProps) => [
    tw`flex w-full items-center gap-4 rounded-lg p-4`,
    SnackbarBackgroundVariants[variant],
  ],
  icon: ({ variant }: StylesProps) => [SnackbarIconVariants[variant]],
  text: ({ variant }: StylesProps) => [SnackbarTextVariants[variant]],
  defaultIcon: tw`h-5 w-5`,
};

const DefaultIcon = ({ variant }: { variant: SnackbarVariants }) => {
  if (variant === 'error') return <ExclamationCircleIcon css={styles.defaultIcon} />;
  else if (variant === 'info') return <InformationCircleIcon css={styles.defaultIcon} />;
  else if (variant === 'success') return <CheckCircleIcon css={styles.defaultIcon} />;
  else return <ExclamationTriangleIcon css={styles.defaultIcon} />;
};

const Snackbar: React.FC<SnackbarProps> = (props) => {
  const { icon, text, variant } = props;
  return (
    <div css={styles.bg({ variant })}>
      <div css={styles.icon({ variant })}>{icon ?? <DefaultIcon variant={variant} />}</div>
      <div css={styles.text({ variant })}>{text}</div>
    </div>
  );
};

export default Snackbar;
