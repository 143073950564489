import tw from 'twin.macro';

type CardProps = {
  title?: string;
  description: string | React.ReactNode;
};

const Container = tw.div`flex flex-col rounded-2xl bg-white p-6`;

const Card: React.FC<CardProps> = ({ title, description, ...props }) => (
  <Container {...props}>
    {title && <h5 tw="mb-4 font-medium">{title}</h5>}
    {typeof description === 'string' ? (
      <p tw="whitespace-pre-line font-jamjuree">{description}</p>
    ) : (
      description
    )}
  </Container>
);

export default Card;
