import { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';

import { MicrophoneIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import CardSkeleton from '@/components/CardSkeleton';
import Progressbar from '@/components/Progressbar';
import QuestionDetails from '@/components/QuestionDetails';
import QuestionDetailsSkeleton from '@/components/QuestionDetailsSkeleton';
import { Button, Modal } from '@/components/base';
import { InterviewSessionCard, VideoInterviewContainer } from '@/containers';
import { useInterviewSession } from '@/providers/InterviewSession';
import { useGetMediaDevices } from '@/utils/useGetMediaDevices';
import { StatusMessages, useMediaRecorder } from '@/utils/useMediaRecorder';

import InputSelect from './components/InputSelect';
import PreparationButtons from './components/PreparationButtons';

const duration = 120;

const Preparation = () => {
  const navigate = useNavigate();

  const [audioInputDeviceId, setAudioInputDeviceId] = useState<string>(() => {
    const savedValue = localStorage.getItem('audioInputDeviceId');
    return savedValue ? JSON.parse(savedValue) : '';
  });
  const [videoInputDeviceId, setVideoInputDeviceId] = useState<string>(() => {
    const savedValue = localStorage.getItem('videoInputDeviceId');
    return savedValue ? JSON.parse(savedValue) : '';
  });

  const {
    clearBlobUrl,
    initializeMediaStream,
    mediaBlobUrl,
    previewStream,
    status,
    startRecording,
    stopRecording,
  } = useMediaRecorder({
    audio: true,
    video: true,
    blobPropertyBag: { type: 'video/mp4' },
    audioInputDeviceId,
    videoInputDeviceId,
  });
  const { audioInput, videoInput } = useGetMediaDevices({ status });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  const {
    loading,
    startSession,
    totalQuestions,
    status: interviewStatus,
    interviewee,
  } = useInterviewSession();

  useEffect(() => {
    initializeMediaStream();
  }, [videoInputDeviceId, audioInputDeviceId]);

  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleStartRecording = async () => {
    await startRecording();
    timeoutRef.current = setTimeout(stopRecording, duration * 1000);
  };

  const handleStopRecording = () => {
    stopRecording();
  };

  const handleClearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const startNewSession = async () => {
    await startSession();
    handleClearTimeoutRef();
    navigate(`/interviewee/session/${interviewee?.uid}`);
  };

  const handleClearBlobUrl = () => {
    clearBlobUrl();
    handleClearTimeoutRef();
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const handleSetAudioInputDeviceId = (value: string) => {
    setAudioInputDeviceId(value);
    localStorage.setItem('audioInputDeviceId', JSON.stringify(value));
  };

  const handleSetVideoInputDeviceId = (value: string) => {
    setVideoInputDeviceId(value);
    localStorage.setItem('videoInputDeviceId', JSON.stringify(value));
  };

  return (
    <>
      {isModalOpen || status === StatusMessages.RECORDING_FAILED ? (
        <Modal
          description={
            <div tw="flex flex-col items-center space-y-4 text-center">
              <h5>ตรวจสอบการตั้งค่ากล้องและไมโครโฟน</h5>
              <p tw="font-jamjuree">
                {isModalOpen
                  ? `กรุณาตรวจสอบการตั้งค่าไมโครโฟนและกล้องของคุณ ให้ตรงกับภาพด้านล่างก่อนเริ่มสัมภาษณ์`
                  : `กรุณาตรวจสอบการตั้งค่าไมโครโฟนและกล้องของคุณให้ตรงกับภาพด้านล่าง จากนั้นรีเฟรชหน้านี้อีกครั้ง`}
              </p>
              <img src="/assets/preparation-chrome-instruction.svg" alt="" />
              <img src="/assets/preparation-safari-instruction.svg" alt="" />
            </div>
          }
          footer={
            <div tw="flex w-full justify-center pt-4 pb-8">
              <Button
                onClick={() => {
                  if (isModalOpen) {
                    toggleModal();
                  } else {
                    navigate(0);
                  }
                }}
              >
                รับทราบ
              </Button>
            </div>
          }
        />
      ) : null}
      <div tw="m-auto flex max-w-screen-xl flex-col space-y-8 px-4 py-6 lg:(px-20 py-10)">
        <Progressbar current={0} total={totalQuestions} />
        {loading ? (
          <QuestionDetailsSkeleton />
        ) : (
          <QuestionDetails
            number={0}
            title="เมื่อเข้าสู่ช่วงสัมภาษณ์ กรุณาออกเสียง 1 - 10 เพื่อทดสอบไมโครโฟนและกล้องของคุณ"
          />
        )}
        <div tw="flex w-full flex-col items-start sm:items-center gap-6 lg:flex-row sm:justify-center">
          <div tw="space-y-4">
            <VideoInterviewContainer
              loading={loading}
              status={status}
              mediaStream={previewStream}
              blobUrl={mediaBlobUrl}
            />
            {status === StatusMessages.IDLE ? (
              <div tw="flex gap-2">
                <InputSelect
                  containerStyle="w-1/2"
                  icon={<MicrophoneIcon tw="w-4 h-4" />}
                  value={
                    audioInput.length !== 0 && !audioInputDeviceId
                      ? audioInput[0].id
                      : audioInputDeviceId
                  }
                  onChange={handleSetAudioInputDeviceId}
                  availableInputs={audioInput}
                />
                <InputSelect
                  containerStyle="w-1/2"
                  icon={<VideoCameraIcon tw="w-4 h-4" />}
                  value={
                    videoInput.length !== 0 && !videoInputDeviceId
                      ? videoInput[0].id
                      : videoInputDeviceId
                  }
                  onChange={handleSetVideoInputDeviceId}
                  availableInputs={videoInput}
                />
              </div>
            ) : null}
          </div>
          <div tw="flex w-full flex-col gap-4">
            {loading ? (
              <>
                <CardSkeleton lines={4} />
                <CardSkeleton lines={2} />
              </>
            ) : (
              <InterviewSessionCard
                currentQuestion={0}
                duration={duration}
                status={status}
                startRecording={handleStartRecording}
              />
            )}
            <PreparationButtons
              duration={duration}
              startRecording={handleStartRecording}
              status={status}
              loading={loading}
              stopRecording={handleStopRecording}
              clearBlobUrl={handleClearBlobUrl}
              initializeMediaStream={initializeMediaStream}
              nextSession={startNewSession}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Preparation;
