import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** Firestore Timestamp Scalar Type */
  FirestoreTimestamp: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type BackofficeSession = {
  __typename?: 'BackofficeSession';
  accessToken?: Maybe<Scalars['String']>;
  accessTokenExpiresAt?: Maybe<Scalars['FirestoreTimestamp']>;
  availableUntil?: Maybe<Scalars['FirestoreTimestamp']>;
  company: Scalars['String'];
  completedAt?: Maybe<Scalars['FirestoreTimestamp']>;
  createdAt: Scalars['FirestoreTimestamp'];
  currentQuestion?: Maybe<Scalars['Float']>;
  duration: Scalars['Float'];
  endingMessage?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['FirestoreTimestamp']>;
  id: Scalars['ID'];
  intervieweeEmail: Scalars['String'];
  intervieweeName: Scalars['String'];
  position: Scalars['String'];
  questionTemplateID: Scalars['String'];
  questionTemplateName: Scalars['String'];
  refId?: Maybe<Scalars['String']>;
  refUrl?: Maybe<Scalars['String']>;
  shouldSendEmailToInterviewee: Scalars['Boolean'];
  startAt?: Maybe<Scalars['FirestoreTimestamp']>;
  status: SessionStatus;
  videoUploadStatus?: Maybe<VideoUploadStatus>;
};

export type BaseTemplate = {
  __typename?: 'BaseTemplate';
  duration?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<QuestionTemplate>;
  totalQuestions: Scalars['Float'];
};

export type BatchIntervieweeInput = {
  intervieweeEmail: Scalars['String'];
  intervieweeName: Scalars['String'];
  position: Scalars['String'];
  refId?: InputMaybe<Scalars['String']>;
  refUrl?: InputMaybe<Scalars['String']>;
};

export type CreateBatchSessionInput = {
  duration: Scalars['Float'];
  expiredAt: Scalars['DateTime'];
  interviewees: Array<BatchIntervieweeInput>;
  questionTemplateID: Scalars['String'];
  shouldSendEmailToInterviewees: Scalars['Boolean'];
};

export type CreateBatchSessionInputError = ResponseError & {
  __typename?: 'CreateBatchSessionInputError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type CreateBatchSessionResult = CreateBatchSessionInputError | CreateBatchSessionResultSuccess;

export type CreateBatchSessionResultSuccess = {
  __typename?: 'CreateBatchSessionResultSuccess';
  createdSession: Array<Session>;
};

export type CreateIntervieweeSessionsReportError = ResponseError & {
  __typename?: 'CreateIntervieweeSessionsReportError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type CreateIntervieweeSessionsReportResult = CreateIntervieweeSessionsReportError | CreateIntervieweeSessionsReportResultSuccess;

export type CreateIntervieweeSessionsReportResultSuccess = {
  __typename?: 'CreateIntervieweeSessionsReportResultSuccess';
  createdUrl: Scalars['String'];
};

export type CreateSessionInput = {
  company: Scalars['String'];
  duration: Scalars['Float'];
  expiredAt: Scalars['DateTime'];
  intervieweeEmail: Scalars['String'];
  intervieweeName: Scalars['String'];
  position: Scalars['String'];
  questionTemplateID: Scalars['String'];
  refId?: InputMaybe<Scalars['String']>;
  refUrl?: InputMaybe<Scalars['String']>;
  shouldSendEmailToInterviewee: Scalars['Boolean'];
};

export type CreateSessionInputError = ResponseError & {
  __typename?: 'CreateSessionInputError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type CreateSessionResult = CreateSessionInputError | CreateSessionResultSuccess;

export type CreateSessionResultSuccess = {
  __typename?: 'CreateSessionResultSuccess';
  createdSession: Session;
};

export type CreateSessionViewTokenError = ResponseError & {
  __typename?: 'CreateSessionViewTokenError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type CreateSessionViewTokenInput = {
  expiryDays?: InputMaybe<Scalars['Float']>;
  sessionId: Scalars['String'];
};

export type CreateSessionViewTokenResult = CreateSessionViewTokenError | CreateSessionViewTokenResultSuccess;

export type CreateSessionViewTokenResultSuccess = {
  __typename?: 'CreateSessionViewTokenResultSuccess';
  session: BackofficeSession;
};

export type CreateTemplateInput = {
  name: Scalars['String'];
  questions: Array<QuestionTemplateInput>;
};

export type CreateTemplateInputError = ResponseError & {
  __typename?: 'CreateTemplateInputError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type CreateTemplateResult = CreateTemplateInputError | CreateTemplateResultSuccess;

export type CreateTemplateResultSuccess = {
  __typename?: 'CreateTemplateResultSuccess';
  createdTemplate: BaseTemplate;
};

export type GetQuestionSnapshotError = ResponseError & {
  __typename?: 'GetQuestionSnapshotError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type GetQuestionSnapshotResult = GetQuestionSnapshotError | GetQuestionSnapshotResultSuccess;

export type GetQuestionSnapshotResultSuccess = {
  __typename?: 'GetQuestionSnapshotResultSuccess';
  question: QuestionTemplate;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBatchInterviewSession: CreateBatchSessionResult;
  createIntervieweeSessionsReport: CreateIntervieweeSessionsReportResult;
  createSession: CreateSessionResult;
  createSessionViewToken: CreateSessionViewTokenResult;
  createTemplate: CreateTemplateResult;
  getQuestionSnapshot?: Maybe<GetQuestionSnapshotResult>;
  updateSession: UpdateSessionResult;
  updateTemplate: UpdateTemplateResult;
};


export type MutationCreateBatchInterviewSessionArgs = {
  input: CreateBatchSessionInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationCreateSessionViewTokenArgs = {
  input: CreateSessionViewTokenInput;
};


export type MutationCreateTemplateArgs = {
  input: CreateTemplateInput;
};


export type MutationGetQuestionSnapshotArgs = {
  order: Scalars['Float'];
  sessionId: Scalars['String'];
};


export type MutationUpdateSessionArgs = {
  input: UpdateSessionInput;
};


export type MutationUpdateTemplateArgs = {
  input: UpdateTemplateInput;
};

export type ProcessTranscribedTextData = {
  __typename?: 'ProcessTranscribedTextData';
  highlight?: Maybe<Scalars['Boolean']>;
  keyword?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  getActiveSessions: Array<Session>;
  getActiveTemplates?: Maybe<Array<BaseTemplate>>;
  getAllSessions: Array<Session>;
  getCurrentQuestion?: Maybe<BaseTemplate>;
  /** This query returns an existing user */
  getMyProfile: User;
  getMySession: Session;
  getQuestionSnapshotBySessionId: Array<QuestionSnapshot>;
  getQuestionSnapshotBySessionIdWithToken: Array<QuestionSnapshot>;
  getSession: BackofficeSession;
  getSessionWithToken: BackofficeSession;
  getTemplate: BaseTemplate;
  getTemplateInfo?: Maybe<BaseTemplate>;
  getTotalQuestions?: Maybe<Scalars['Float']>;
};


export type QueryGetCurrentQuestionArgs = {
  currentQuestion: Scalars['Float'];
  templateId: Scalars['String'];
};


export type QueryGetQuestionSnapshotBySessionIdArgs = {
  sessionId: Scalars['String'];
};


export type QueryGetQuestionSnapshotBySessionIdWithTokenArgs = {
  sessionId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryGetSessionArgs = {
  uid: Scalars['String'];
};


export type QueryGetSessionWithTokenArgs = {
  token: Scalars['String'];
  uid: Scalars['String'];
};


export type QueryGetTemplateArgs = {
  templateId: Scalars['String'];
};


export type QueryGetTemplateInfoArgs = {
  templateId: Scalars['String'];
};


export type QueryGetTotalQuestionsArgs = {
  templateId: Scalars['String'];
};

export type QuestionSnapshot = {
  __typename?: 'QuestionSnapshot';
  processedTranscribedText?: Maybe<Array<ProcessTranscribedTextData>>;
  title: Scalars['String'];
  transcribedText?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type QuestionTemplate = {
  __typename?: 'QuestionTemplate';
  duration?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  questionPool?: Maybe<Scalars['String']>;
  tips?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<QuestionType>;
  url?: Maybe<Scalars['String']>;
};

export type QuestionTemplateInput = {
  duration?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  questionPool?: InputMaybe<Scalars['String']>;
  tips?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<QuestionType>;
  url?: InputMaybe<Scalars['String']>;
};

export enum QuestionType {
  Random = 'RANDOM'
}

export type ResponseError = {
  code: Scalars['Int'];
  message: Scalars['String'];
};

export enum Roles {
  Admin = 'ADMIN',
  ViewOnly = 'VIEW_ONLY'
}

export type Session = {
  __typename?: 'Session';
  availableUntil?: Maybe<Scalars['FirestoreTimestamp']>;
  company: Scalars['String'];
  completedAt?: Maybe<Scalars['FirestoreTimestamp']>;
  createdAt: Scalars['FirestoreTimestamp'];
  currentQuestion?: Maybe<Scalars['Float']>;
  duration: Scalars['Float'];
  endingMessage?: Maybe<Scalars['String']>;
  expiredAt?: Maybe<Scalars['FirestoreTimestamp']>;
  id: Scalars['ID'];
  intervieweeEmail: Scalars['String'];
  intervieweeName: Scalars['String'];
  position: Scalars['String'];
  questionTemplateID: Scalars['String'];
  questionTemplateName: Scalars['String'];
  refId?: Maybe<Scalars['String']>;
  refUrl?: Maybe<Scalars['String']>;
  shouldSendEmailToInterviewee: Scalars['Boolean'];
  startAt?: Maybe<Scalars['FirestoreTimestamp']>;
  status: SessionStatus;
  videoUploadStatus?: Maybe<VideoUploadStatus>;
};

export enum SessionStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type Template = {
  __typename?: 'Template';
  deletedAt?: Maybe<Scalars['FirestoreTimestamp']>;
  endingMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<QuestionTemplate>;
};

export type UpdateSessionInput = {
  data: Scalars['JSONObject'];
  preparationTime?: InputMaybe<Scalars['Int']>;
  sessionID: Scalars['ID'];
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateSessionInputError = ResponseError & {
  __typename?: 'UpdateSessionInputError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type UpdateSessionResult = UpdateSessionInputError | UpdateSessionResultSuccess;

export type UpdateSessionResultSuccess = {
  __typename?: 'UpdateSessionResultSuccess';
  updatedSession: Session;
};

export type UpdateTemplateInput = {
  data: Scalars['JSONObject'];
  templateID: Scalars['ID'];
};

export type UpdateTemplateInputError = ResponseError & {
  __typename?: 'UpdateTemplateInputError';
  code: Scalars['Int'];
  message: Scalars['String'];
};

export type UpdateTemplateResult = UpdateTemplateInputError | UpdateTemplateResultSuccess;

export type UpdateTemplateResultSuccess = {
  __typename?: 'UpdateTemplateResultSuccess';
  updatedTemplate: BaseTemplate;
};

export type User = {
  __typename?: 'User';
  company: Scalars['String'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  firebaseUserId: Scalars['String'];
  id: Scalars['ID'];
  roles: Array<Roles>;
  status: UserStatus;
};

export type UserRoles = {
  __typename?: 'UserRoles';
  id: Scalars['ID'];
  roles: Array<Roles>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum VideoUploadStatus {
  NotStarted = 'NOT_STARTED',
  Uploaded = 'UPLOADED',
  Uploading = 'UPLOADING'
}

export type GetCompletedSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompletedSessionsQuery = { __typename?: 'Query', getActiveSessions: Array<{ __typename?: 'Session', duration: number, id: string, intervieweeName: string, intervieweeEmail: string, availableUntil?: any | null, questionTemplateID: string, startAt?: any | null, status: SessionStatus, completedAt?: any | null, createdAt: any, expiredAt?: any | null }> };

export type GetAllSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllSessionsQuery = { __typename?: 'Query', getAllSessions: Array<{ __typename?: 'Session', id: string, intervieweeName: string, intervieweeEmail: string, position: string, refId?: string | null, questionTemplateID: string, status: SessionStatus, completedAt?: any | null, questionTemplateName: string, availableUntil?: any | null, expiredAt?: any | null }> };

export type GetMySessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMySessionQuery = { __typename?: 'Query', getMySession: { __typename?: 'Session', id: string, intervieweeEmail: string, intervieweeName: string, refId?: string | null, position: string, company: string, questionTemplateID: string, expiredAt?: any | null, status: SessionStatus, duration: number, currentQuestion?: number | null, availableUntil?: any | null, endingMessage?: string | null } };

export type GetSessionQueryVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type GetSessionQuery = { __typename?: 'Query', getSession: { __typename?: 'BackofficeSession', id: string, position: string, company: string, refId?: string | null, refUrl?: string | null, questionTemplateID: string, expiredAt?: any | null, status: SessionStatus, duration: number, currentQuestion?: number | null, intervieweeName: string, intervieweeEmail: string, accessToken?: string | null, accessTokenExpiresAt?: any | null } };

export type GetSessionWithTokenQueryVariables = Exact<{
  sessionId: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetSessionWithTokenQuery = { __typename?: 'Query', getSessionWithToken: { __typename?: 'BackofficeSession', id: string, position: string, company: string, refId?: string | null, refUrl?: string | null, questionTemplateID: string, expiredAt?: any | null, status: SessionStatus, duration: number, currentQuestion?: number | null, intervieweeName: string, intervieweeEmail: string } };

export type GetQuestionSnapshotBySessionIdQueryVariables = Exact<{
  sessionId: Scalars['String'];
}>;


export type GetQuestionSnapshotBySessionIdQuery = { __typename?: 'Query', getQuestionSnapshotBySessionId: Array<{ __typename?: 'QuestionSnapshot', title: string, url?: string | null, transcribedText?: string | null, processedTranscribedText?: Array<{ __typename?: 'ProcessTranscribedTextData', text: string, original?: string | null, highlight?: boolean | null, reason?: string | null, keyword?: string | null }> | null }> };

export type GetQuestionSnapshotBySessionIdWithTokenQueryVariables = Exact<{
  sessionId: Scalars['String'];
  token: Scalars['String'];
}>;


export type GetQuestionSnapshotBySessionIdWithTokenQuery = { __typename?: 'Query', getQuestionSnapshotBySessionIdWithToken: Array<{ __typename?: 'QuestionSnapshot', title: string, url?: string | null, transcribedText?: string | null, processedTranscribedText?: Array<{ __typename?: 'ProcessTranscribedTextData', text: string, original?: string | null, highlight?: boolean | null, reason?: string | null, keyword?: string | null }> | null }> };

export type UpdateSessionMutationVariables = Exact<{
  input: UpdateSessionInput;
}>;


export type UpdateSessionMutation = { __typename?: 'Mutation', updateSession: { __typename?: 'UpdateSessionInputError', code: number, message: string } | { __typename?: 'UpdateSessionResultSuccess', updatedSession: { __typename?: 'Session', status: SessionStatus, availableUntil?: any | null } } };

export type CreateSessionMutationVariables = Exact<{
  createSessionInput: CreateSessionInput;
}>;


export type CreateSessionMutation = { __typename?: 'Mutation', createSession: { __typename?: 'CreateSessionInputError', message: string, code: number } | { __typename?: 'CreateSessionResultSuccess', createdSession: { __typename?: 'Session', id: string } } };

export type GetQuestionSnapshotMutationVariables = Exact<{
  order: Scalars['Float'];
  sessionId: Scalars['String'];
}>;


export type GetQuestionSnapshotMutation = { __typename?: 'Mutation', getQuestionSnapshot?: { __typename?: 'GetQuestionSnapshotError', code: number, message: string } | { __typename?: 'GetQuestionSnapshotResultSuccess', question: { __typename?: 'QuestionTemplate', id: string, order?: number | null, title?: string | null, duration?: number | null, tips?: Array<string> | null } } | null };

export type CreateBatchInterviewSessionMutationVariables = Exact<{
  input: CreateBatchSessionInput;
}>;


export type CreateBatchInterviewSessionMutation = { __typename?: 'Mutation', createBatchInterviewSession: { __typename?: 'CreateBatchSessionInputError', message: string, code: number } | { __typename?: 'CreateBatchSessionResultSuccess', createdSession: Array<{ __typename?: 'Session', id: string, intervieweeEmail: string, intervieweeName: string }> } };

export type CreateIntervieweeSessionsReportMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateIntervieweeSessionsReportMutation = { __typename?: 'Mutation', createIntervieweeSessionsReport: { __typename?: 'CreateIntervieweeSessionsReportError', code: number, message: string } | { __typename?: 'CreateIntervieweeSessionsReportResultSuccess', createdUrl: string } };

export type CreateSessionViewTokenMutationVariables = Exact<{
  createSessionViewTokenInput: CreateSessionViewTokenInput;
}>;


export type CreateSessionViewTokenMutation = { __typename?: 'Mutation', createSessionViewToken: { __typename?: 'CreateSessionViewTokenError', message: string, code: number } | { __typename?: 'CreateSessionViewTokenResultSuccess', session: { __typename?: 'BackofficeSession', id: string, accessToken?: string | null, accessTokenExpiresAt?: any | null } } };

export type QuestionFragment = { __typename?: 'QuestionTemplate', id: string, order?: number | null, title?: string | null, duration?: number | null, tips?: Array<string> | null };

export type GetTemplateQueryVariables = Exact<{
  templateId: Scalars['String'];
}>;


export type GetTemplateQuery = { __typename?: 'Query', getTemplate: { __typename?: 'BaseTemplate', name: string, questions: Array<{ __typename?: 'QuestionTemplate', order?: number | null, title?: string | null, duration?: number | null, tips?: Array<string> | null }> } };

export type GetCurrentQuestionQueryVariables = Exact<{
  templateId: Scalars['String'];
  currentQuestion: Scalars['Float'];
}>;


export type GetCurrentQuestionQuery = { __typename?: 'Query', getCurrentQuestion?: { __typename?: 'BaseTemplate', id: string, questions: Array<{ __typename?: 'QuestionTemplate', id: string, order?: number | null, title?: string | null, duration?: number | null, tips?: Array<string> | null }> } | null };

export type GetActiveTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveTemplatesQuery = { __typename?: 'Query', getActiveTemplates?: Array<{ __typename?: 'BaseTemplate', id: string, name: string }> | null };

export type GetTotalQuestionsQueryVariables = Exact<{
  templateId: Scalars['String'];
}>;


export type GetTotalQuestionsQuery = { __typename?: 'Query', getTotalQuestions?: number | null };

export type CreateTemplateMutationVariables = Exact<{
  input: CreateTemplateInput;
}>;


export type CreateTemplateMutation = { __typename?: 'Mutation', createTemplate: { __typename?: 'CreateTemplateInputError', code: number, message: string } | { __typename?: 'CreateTemplateResultSuccess', createdTemplate: { __typename?: 'BaseTemplate', id: string, name: string } } };

export type UpdateTemplateMutationVariables = Exact<{
  input: UpdateTemplateInput;
}>;


export type UpdateTemplateMutation = { __typename?: 'Mutation', updateTemplate: { __typename?: 'UpdateTemplateInputError', code: number, message: string } | { __typename?: 'UpdateTemplateResultSuccess', updatedTemplate: { __typename?: 'BaseTemplate', id: string, name: string } } };

export type GetMyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyProfileQuery = { __typename?: 'Query', getMyProfile: { __typename?: 'User', id: string, roles: Array<Roles>, email: string, displayName: string } };

export const QuestionFragmentDoc = gql`
    fragment Question on QuestionTemplate {
  id
  order
  title
  duration
  tips
}
    `;
export const GetCompletedSessionsDocument = gql`
    query GetCompletedSessions {
  getActiveSessions {
    duration
    id
    intervieweeName
    intervieweeEmail
    availableUntil
    questionTemplateID
    startAt
    status
    completedAt
    createdAt
    expiredAt
  }
}
    `;

/**
 * __useGetCompletedSessionsQuery__
 *
 * To run a query within a React component, call `useGetCompletedSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompletedSessionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCompletedSessionsQuery, GetCompletedSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletedSessionsQuery, GetCompletedSessionsQueryVariables>(GetCompletedSessionsDocument, options);
      }
export function useGetCompletedSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletedSessionsQuery, GetCompletedSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletedSessionsQuery, GetCompletedSessionsQueryVariables>(GetCompletedSessionsDocument, options);
        }
export type GetCompletedSessionsQueryHookResult = ReturnType<typeof useGetCompletedSessionsQuery>;
export type GetCompletedSessionsLazyQueryHookResult = ReturnType<typeof useGetCompletedSessionsLazyQuery>;
export type GetCompletedSessionsQueryResult = Apollo.QueryResult<GetCompletedSessionsQuery, GetCompletedSessionsQueryVariables>;
export const GetAllSessionsDocument = gql`
    query GetAllSessions {
  getAllSessions {
    id
    intervieweeName
    intervieweeEmail
    position
    refId
    questionTemplateID
    status
    completedAt
    questionTemplateName
    availableUntil
    expiredAt
  }
}
    `;

/**
 * __useGetAllSessionsQuery__
 *
 * To run a query within a React component, call `useGetAllSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSessionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllSessionsQuery, GetAllSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllSessionsQuery, GetAllSessionsQueryVariables>(GetAllSessionsDocument, options);
      }
export function useGetAllSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllSessionsQuery, GetAllSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllSessionsQuery, GetAllSessionsQueryVariables>(GetAllSessionsDocument, options);
        }
export type GetAllSessionsQueryHookResult = ReturnType<typeof useGetAllSessionsQuery>;
export type GetAllSessionsLazyQueryHookResult = ReturnType<typeof useGetAllSessionsLazyQuery>;
export type GetAllSessionsQueryResult = Apollo.QueryResult<GetAllSessionsQuery, GetAllSessionsQueryVariables>;
export const GetMySessionDocument = gql`
    query GetMySession {
  getMySession {
    id
    intervieweeEmail
    intervieweeName
    refId
    position
    company
    questionTemplateID
    expiredAt
    status
    duration
    currentQuestion
    availableUntil
    endingMessage
  }
}
    `;

/**
 * __useGetMySessionQuery__
 *
 * To run a query within a React component, call `useGetMySessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMySessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMySessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMySessionQuery(baseOptions?: Apollo.QueryHookOptions<GetMySessionQuery, GetMySessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMySessionQuery, GetMySessionQueryVariables>(GetMySessionDocument, options);
      }
export function useGetMySessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMySessionQuery, GetMySessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMySessionQuery, GetMySessionQueryVariables>(GetMySessionDocument, options);
        }
export type GetMySessionQueryHookResult = ReturnType<typeof useGetMySessionQuery>;
export type GetMySessionLazyQueryHookResult = ReturnType<typeof useGetMySessionLazyQuery>;
export type GetMySessionQueryResult = Apollo.QueryResult<GetMySessionQuery, GetMySessionQueryVariables>;
export const GetSessionDocument = gql`
    query GetSession($sessionId: String!) {
  getSession(uid: $sessionId) {
    id
    position
    company
    refId
    refUrl
    questionTemplateID
    expiredAt
    status
    duration
    currentQuestion
    intervieweeName
    intervieweeEmail
    accessToken
    accessTokenExpiresAt
  }
}
    `;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetSessionQuery(baseOptions: Apollo.QueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
      }
export function useGetSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
        }
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<typeof useGetSessionLazyQuery>;
export type GetSessionQueryResult = Apollo.QueryResult<GetSessionQuery, GetSessionQueryVariables>;
export const GetSessionWithTokenDocument = gql`
    query GetSessionWithToken($sessionId: String!, $token: String!) {
  getSessionWithToken(uid: $sessionId, token: $token) {
    id
    position
    company
    refId
    refUrl
    questionTemplateID
    expiredAt
    status
    duration
    currentQuestion
    intervieweeName
    intervieweeEmail
  }
}
    `;

/**
 * __useGetSessionWithTokenQuery__
 *
 * To run a query within a React component, call `useGetSessionWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionWithTokenQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetSessionWithTokenQuery(baseOptions: Apollo.QueryHookOptions<GetSessionWithTokenQuery, GetSessionWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionWithTokenQuery, GetSessionWithTokenQueryVariables>(GetSessionWithTokenDocument, options);
      }
export function useGetSessionWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionWithTokenQuery, GetSessionWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionWithTokenQuery, GetSessionWithTokenQueryVariables>(GetSessionWithTokenDocument, options);
        }
export type GetSessionWithTokenQueryHookResult = ReturnType<typeof useGetSessionWithTokenQuery>;
export type GetSessionWithTokenLazyQueryHookResult = ReturnType<typeof useGetSessionWithTokenLazyQuery>;
export type GetSessionWithTokenQueryResult = Apollo.QueryResult<GetSessionWithTokenQuery, GetSessionWithTokenQueryVariables>;
export const GetQuestionSnapshotBySessionIdDocument = gql`
    query GetQuestionSnapshotBySessionId($sessionId: String!) {
  getQuestionSnapshotBySessionId(sessionId: $sessionId) {
    title
    url
    transcribedText
    processedTranscribedText {
      text
      original
      highlight
      reason
      keyword
    }
  }
}
    `;

/**
 * __useGetQuestionSnapshotBySessionIdQuery__
 *
 * To run a query within a React component, call `useGetQuestionSnapshotBySessionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionSnapshotBySessionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionSnapshotBySessionIdQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetQuestionSnapshotBySessionIdQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionSnapshotBySessionIdQuery, GetQuestionSnapshotBySessionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionSnapshotBySessionIdQuery, GetQuestionSnapshotBySessionIdQueryVariables>(GetQuestionSnapshotBySessionIdDocument, options);
      }
export function useGetQuestionSnapshotBySessionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionSnapshotBySessionIdQuery, GetQuestionSnapshotBySessionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionSnapshotBySessionIdQuery, GetQuestionSnapshotBySessionIdQueryVariables>(GetQuestionSnapshotBySessionIdDocument, options);
        }
export type GetQuestionSnapshotBySessionIdQueryHookResult = ReturnType<typeof useGetQuestionSnapshotBySessionIdQuery>;
export type GetQuestionSnapshotBySessionIdLazyQueryHookResult = ReturnType<typeof useGetQuestionSnapshotBySessionIdLazyQuery>;
export type GetQuestionSnapshotBySessionIdQueryResult = Apollo.QueryResult<GetQuestionSnapshotBySessionIdQuery, GetQuestionSnapshotBySessionIdQueryVariables>;
export const GetQuestionSnapshotBySessionIdWithTokenDocument = gql`
    query GetQuestionSnapshotBySessionIdWithToken($sessionId: String!, $token: String!) {
  getQuestionSnapshotBySessionIdWithToken(sessionId: $sessionId, token: $token) {
    title
    url
    transcribedText
    processedTranscribedText {
      text
      original
      highlight
      reason
      keyword
    }
  }
}
    `;

/**
 * __useGetQuestionSnapshotBySessionIdWithTokenQuery__
 *
 * To run a query within a React component, call `useGetQuestionSnapshotBySessionIdWithTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionSnapshotBySessionIdWithTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionSnapshotBySessionIdWithTokenQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetQuestionSnapshotBySessionIdWithTokenQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionSnapshotBySessionIdWithTokenQuery, GetQuestionSnapshotBySessionIdWithTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionSnapshotBySessionIdWithTokenQuery, GetQuestionSnapshotBySessionIdWithTokenQueryVariables>(GetQuestionSnapshotBySessionIdWithTokenDocument, options);
      }
export function useGetQuestionSnapshotBySessionIdWithTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionSnapshotBySessionIdWithTokenQuery, GetQuestionSnapshotBySessionIdWithTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionSnapshotBySessionIdWithTokenQuery, GetQuestionSnapshotBySessionIdWithTokenQueryVariables>(GetQuestionSnapshotBySessionIdWithTokenDocument, options);
        }
export type GetQuestionSnapshotBySessionIdWithTokenQueryHookResult = ReturnType<typeof useGetQuestionSnapshotBySessionIdWithTokenQuery>;
export type GetQuestionSnapshotBySessionIdWithTokenLazyQueryHookResult = ReturnType<typeof useGetQuestionSnapshotBySessionIdWithTokenLazyQuery>;
export type GetQuestionSnapshotBySessionIdWithTokenQueryResult = Apollo.QueryResult<GetQuestionSnapshotBySessionIdWithTokenQuery, GetQuestionSnapshotBySessionIdWithTokenQueryVariables>;
export const UpdateSessionDocument = gql`
    mutation UpdateSession($input: UpdateSessionInput!) {
  updateSession(input: $input) {
    ... on UpdateSessionResultSuccess {
      updatedSession {
        status
        availableUntil
      }
    }
    ... on UpdateSessionInputError {
      code
      message
    }
  }
}
    `;
export type UpdateSessionMutationFn = Apollo.MutationFunction<UpdateSessionMutation, UpdateSessionMutationVariables>;

/**
 * __useUpdateSessionMutation__
 *
 * To run a mutation, you first call `useUpdateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionMutation, { data, loading, error }] = useUpdateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionMutation, UpdateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionMutation, UpdateSessionMutationVariables>(UpdateSessionDocument, options);
      }
export type UpdateSessionMutationHookResult = ReturnType<typeof useUpdateSessionMutation>;
export type UpdateSessionMutationResult = Apollo.MutationResult<UpdateSessionMutation>;
export type UpdateSessionMutationOptions = Apollo.BaseMutationOptions<UpdateSessionMutation, UpdateSessionMutationVariables>;
export const CreateSessionDocument = gql`
    mutation CreateSession($createSessionInput: CreateSessionInput!) {
  createSession(input: $createSessionInput) {
    ... on CreateSessionResultSuccess {
      createdSession {
        id
      }
    }
    ... on CreateSessionInputError {
      message
      code
    }
  }
}
    `;
export type CreateSessionMutationFn = Apollo.MutationFunction<CreateSessionMutation, CreateSessionMutationVariables>;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      createSessionInput: // value for 'createSessionInput'
 *   },
 * });
 */
export function useCreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, options);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export type CreateSessionMutationResult = Apollo.MutationResult<CreateSessionMutation>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<CreateSessionMutation, CreateSessionMutationVariables>;
export const GetQuestionSnapshotDocument = gql`
    mutation GetQuestionSnapshot($order: Float!, $sessionId: String!) {
  getQuestionSnapshot(order: $order, sessionId: $sessionId) {
    ... on GetQuestionSnapshotResultSuccess {
      question {
        ...Question
      }
    }
    ... on GetQuestionSnapshotError {
      code
      message
    }
  }
}
    ${QuestionFragmentDoc}`;
export type GetQuestionSnapshotMutationFn = Apollo.MutationFunction<GetQuestionSnapshotMutation, GetQuestionSnapshotMutationVariables>;

/**
 * __useGetQuestionSnapshotMutation__
 *
 * To run a mutation, you first call `useGetQuestionSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getQuestionSnapshotMutation, { data, loading, error }] = useGetQuestionSnapshotMutation({
 *   variables: {
 *      order: // value for 'order'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useGetQuestionSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<GetQuestionSnapshotMutation, GetQuestionSnapshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetQuestionSnapshotMutation, GetQuestionSnapshotMutationVariables>(GetQuestionSnapshotDocument, options);
      }
export type GetQuestionSnapshotMutationHookResult = ReturnType<typeof useGetQuestionSnapshotMutation>;
export type GetQuestionSnapshotMutationResult = Apollo.MutationResult<GetQuestionSnapshotMutation>;
export type GetQuestionSnapshotMutationOptions = Apollo.BaseMutationOptions<GetQuestionSnapshotMutation, GetQuestionSnapshotMutationVariables>;
export const CreateBatchInterviewSessionDocument = gql`
    mutation CreateBatchInterviewSession($input: CreateBatchSessionInput!) {
  createBatchInterviewSession(input: $input) {
    ... on CreateBatchSessionResultSuccess {
      createdSession {
        id
        intervieweeEmail
        intervieweeName
      }
    }
    ... on CreateBatchSessionInputError {
      message
      code
    }
  }
}
    `;
export type CreateBatchInterviewSessionMutationFn = Apollo.MutationFunction<CreateBatchInterviewSessionMutation, CreateBatchInterviewSessionMutationVariables>;

/**
 * __useCreateBatchInterviewSessionMutation__
 *
 * To run a mutation, you first call `useCreateBatchInterviewSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchInterviewSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchInterviewSessionMutation, { data, loading, error }] = useCreateBatchInterviewSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchInterviewSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateBatchInterviewSessionMutation, CreateBatchInterviewSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBatchInterviewSessionMutation, CreateBatchInterviewSessionMutationVariables>(CreateBatchInterviewSessionDocument, options);
      }
export type CreateBatchInterviewSessionMutationHookResult = ReturnType<typeof useCreateBatchInterviewSessionMutation>;
export type CreateBatchInterviewSessionMutationResult = Apollo.MutationResult<CreateBatchInterviewSessionMutation>;
export type CreateBatchInterviewSessionMutationOptions = Apollo.BaseMutationOptions<CreateBatchInterviewSessionMutation, CreateBatchInterviewSessionMutationVariables>;
export const CreateIntervieweeSessionsReportDocument = gql`
    mutation CreateIntervieweeSessionsReport {
  createIntervieweeSessionsReport {
    ... on CreateIntervieweeSessionsReportResultSuccess {
      createdUrl
    }
    ... on CreateIntervieweeSessionsReportError {
      code
      message
    }
  }
}
    `;
export type CreateIntervieweeSessionsReportMutationFn = Apollo.MutationFunction<CreateIntervieweeSessionsReportMutation, CreateIntervieweeSessionsReportMutationVariables>;

/**
 * __useCreateIntervieweeSessionsReportMutation__
 *
 * To run a mutation, you first call `useCreateIntervieweeSessionsReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntervieweeSessionsReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntervieweeSessionsReportMutation, { data, loading, error }] = useCreateIntervieweeSessionsReportMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateIntervieweeSessionsReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateIntervieweeSessionsReportMutation, CreateIntervieweeSessionsReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIntervieweeSessionsReportMutation, CreateIntervieweeSessionsReportMutationVariables>(CreateIntervieweeSessionsReportDocument, options);
      }
export type CreateIntervieweeSessionsReportMutationHookResult = ReturnType<typeof useCreateIntervieweeSessionsReportMutation>;
export type CreateIntervieweeSessionsReportMutationResult = Apollo.MutationResult<CreateIntervieweeSessionsReportMutation>;
export type CreateIntervieweeSessionsReportMutationOptions = Apollo.BaseMutationOptions<CreateIntervieweeSessionsReportMutation, CreateIntervieweeSessionsReportMutationVariables>;
export const CreateSessionViewTokenDocument = gql`
    mutation CreateSessionViewToken($createSessionViewTokenInput: CreateSessionViewTokenInput!) {
  createSessionViewToken(input: $createSessionViewTokenInput) {
    ... on CreateSessionViewTokenResultSuccess {
      session {
        id
        accessToken
        accessTokenExpiresAt
      }
    }
    ... on CreateSessionViewTokenError {
      message
      code
    }
  }
}
    `;
export type CreateSessionViewTokenMutationFn = Apollo.MutationFunction<CreateSessionViewTokenMutation, CreateSessionViewTokenMutationVariables>;

/**
 * __useCreateSessionViewTokenMutation__
 *
 * To run a mutation, you first call `useCreateSessionViewTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionViewTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionViewTokenMutation, { data, loading, error }] = useCreateSessionViewTokenMutation({
 *   variables: {
 *      createSessionViewTokenInput: // value for 'createSessionViewTokenInput'
 *   },
 * });
 */
export function useCreateSessionViewTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionViewTokenMutation, CreateSessionViewTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionViewTokenMutation, CreateSessionViewTokenMutationVariables>(CreateSessionViewTokenDocument, options);
      }
export type CreateSessionViewTokenMutationHookResult = ReturnType<typeof useCreateSessionViewTokenMutation>;
export type CreateSessionViewTokenMutationResult = Apollo.MutationResult<CreateSessionViewTokenMutation>;
export type CreateSessionViewTokenMutationOptions = Apollo.BaseMutationOptions<CreateSessionViewTokenMutation, CreateSessionViewTokenMutationVariables>;
export const GetTemplateDocument = gql`
    query GetTemplate($templateId: String!) {
  getTemplate(templateId: $templateId) {
    name
    questions {
      order
      title
      duration
      tips
    }
  }
}
    `;

/**
 * __useGetTemplateQuery__
 *
 * To run a query within a React component, call `useGetTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, options);
      }
export function useGetTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateQuery, GetTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateQuery, GetTemplateQueryVariables>(GetTemplateDocument, options);
        }
export type GetTemplateQueryHookResult = ReturnType<typeof useGetTemplateQuery>;
export type GetTemplateLazyQueryHookResult = ReturnType<typeof useGetTemplateLazyQuery>;
export type GetTemplateQueryResult = Apollo.QueryResult<GetTemplateQuery, GetTemplateQueryVariables>;
export const GetCurrentQuestionDocument = gql`
    query GetCurrentQuestion($templateId: String!, $currentQuestion: Float!) {
  getCurrentQuestion(templateId: $templateId, currentQuestion: $currentQuestion) {
    id
    questions {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}`;

/**
 * __useGetCurrentQuestionQuery__
 *
 * To run a query within a React component, call `useGetCurrentQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentQuestionQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *      currentQuestion: // value for 'currentQuestion'
 *   },
 * });
 */
export function useGetCurrentQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>(GetCurrentQuestionDocument, options);
      }
export function useGetCurrentQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>(GetCurrentQuestionDocument, options);
        }
export type GetCurrentQuestionQueryHookResult = ReturnType<typeof useGetCurrentQuestionQuery>;
export type GetCurrentQuestionLazyQueryHookResult = ReturnType<typeof useGetCurrentQuestionLazyQuery>;
export type GetCurrentQuestionQueryResult = Apollo.QueryResult<GetCurrentQuestionQuery, GetCurrentQuestionQueryVariables>;
export const GetActiveTemplatesDocument = gql`
    query GetActiveTemplates {
  getActiveTemplates {
    id
    name
  }
}
    `;

/**
 * __useGetActiveTemplatesQuery__
 *
 * To run a query within a React component, call `useGetActiveTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetActiveTemplatesQuery, GetActiveTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveTemplatesQuery, GetActiveTemplatesQueryVariables>(GetActiveTemplatesDocument, options);
      }
export function useGetActiveTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveTemplatesQuery, GetActiveTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveTemplatesQuery, GetActiveTemplatesQueryVariables>(GetActiveTemplatesDocument, options);
        }
export type GetActiveTemplatesQueryHookResult = ReturnType<typeof useGetActiveTemplatesQuery>;
export type GetActiveTemplatesLazyQueryHookResult = ReturnType<typeof useGetActiveTemplatesLazyQuery>;
export type GetActiveTemplatesQueryResult = Apollo.QueryResult<GetActiveTemplatesQuery, GetActiveTemplatesQueryVariables>;
export const GetTotalQuestionsDocument = gql`
    query GetTotalQuestions($templateId: String!) {
  getTotalQuestions(templateId: $templateId)
}
    `;

/**
 * __useGetTotalQuestionsQuery__
 *
 * To run a query within a React component, call `useGetTotalQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalQuestionsQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetTotalQuestionsQuery(baseOptions: Apollo.QueryHookOptions<GetTotalQuestionsQuery, GetTotalQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalQuestionsQuery, GetTotalQuestionsQueryVariables>(GetTotalQuestionsDocument, options);
      }
export function useGetTotalQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalQuestionsQuery, GetTotalQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalQuestionsQuery, GetTotalQuestionsQueryVariables>(GetTotalQuestionsDocument, options);
        }
export type GetTotalQuestionsQueryHookResult = ReturnType<typeof useGetTotalQuestionsQuery>;
export type GetTotalQuestionsLazyQueryHookResult = ReturnType<typeof useGetTotalQuestionsLazyQuery>;
export type GetTotalQuestionsQueryResult = Apollo.QueryResult<GetTotalQuestionsQuery, GetTotalQuestionsQueryVariables>;
export const CreateTemplateDocument = gql`
    mutation CreateTemplate($input: CreateTemplateInput!) {
  createTemplate(input: $input) {
    ... on CreateTemplateResultSuccess {
      createdTemplate {
        id
        name
      }
    }
    ... on CreateTemplateInputError {
      code
      message
    }
  }
}
    `;
export type CreateTemplateMutationFn = Apollo.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, options);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const UpdateTemplateDocument = gql`
    mutation UpdateTemplate($input: UpdateTemplateInput!) {
  updateTemplate(input: $input) {
    ... on UpdateTemplateResultSuccess {
      updatedTemplate {
        id
        name
      }
    }
    ... on UpdateTemplateInputError {
      code
      message
    }
  }
}
    `;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const GetMyProfileDocument = gql`
    query getMyProfile {
  getMyProfile {
    id
    roles
    email
    displayName
  }
}
    `;

/**
 * __useGetMyProfileQuery__
 *
 * To run a query within a React component, call `useGetMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
      }
export function useGetMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProfileQuery, GetMyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument, options);
        }
export type GetMyProfileQueryHookResult = ReturnType<typeof useGetMyProfileQuery>;
export type GetMyProfileLazyQueryHookResult = ReturnType<typeof useGetMyProfileLazyQuery>;
export type GetMyProfileQueryResult = Apollo.QueryResult<GetMyProfileQuery, GetMyProfileQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CreateBatchSessionResult": [
      "CreateBatchSessionInputError",
      "CreateBatchSessionResultSuccess"
    ],
    "CreateIntervieweeSessionsReportResult": [
      "CreateIntervieweeSessionsReportError",
      "CreateIntervieweeSessionsReportResultSuccess"
    ],
    "CreateSessionResult": [
      "CreateSessionInputError",
      "CreateSessionResultSuccess"
    ],
    "CreateSessionViewTokenResult": [
      "CreateSessionViewTokenError",
      "CreateSessionViewTokenResultSuccess"
    ],
    "CreateTemplateResult": [
      "CreateTemplateInputError",
      "CreateTemplateResultSuccess"
    ],
    "GetQuestionSnapshotResult": [
      "GetQuestionSnapshotError",
      "GetQuestionSnapshotResultSuccess"
    ],
    "ResponseError": [
      "CreateBatchSessionInputError",
      "CreateIntervieweeSessionsReportError",
      "CreateSessionInputError",
      "CreateSessionViewTokenError",
      "CreateTemplateInputError",
      "GetQuestionSnapshotError",
      "UpdateSessionInputError",
      "UpdateTemplateInputError"
    ],
    "UpdateSessionResult": [
      "UpdateSessionInputError",
      "UpdateSessionResultSuccess"
    ],
    "UpdateTemplateResult": [
      "UpdateTemplateInputError",
      "UpdateTemplateResultSuccess"
    ]
  }
};
      export default result;
    