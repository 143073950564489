import { getFunctions, httpsCallable } from 'firebase/functions';

import firebaseApp from './firebase';

const functions = getFunctions(firebaseApp, 'asia-southeast1');

type CreateCustomTokenResponse = {
  data?: {
    token?: string;
  };
};

export const getCustomToken = async (uid: string) => {
  try {
    const res = (await httpsCallable(
      functions,
      'createCustomToken',
    )({ uid })) as CreateCustomTokenResponse;
    return res.data?.token;
  } catch (error) {
    console.error(error);
    throw new Error(error as string);
  }
};

export const nextQuestion = async (templateId: string, currentQuestion: number) => {
  try {
    const res = await httpsCallable(functions, 'nextQuestion')({ id: templateId, currentQuestion });
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error(error as string);
  }
};

export const updateVideoUploadStatus = async (templateId: string, videoUploadStatus: string) => {
  try {
    const res = await httpsCallable(
      functions,
      'updateVideoUploadStatus',
    )({ id: templateId, videoUploadStatus });
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error(error as string);
  }
};
