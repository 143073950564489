import tw from 'twin.macro';

import { Select as BaseSelect } from '../base';
import { FormElementProps } from './types';

interface SelectProps extends FormElementProps<HTMLSelectElement> {
  options: { id: string; value: string | number; text: string }[];
}

const Select: React.FC<SelectProps> = (props) => {
  const { label, name, options, register, placeholder, validations, ...selectProps } = props;
  const { ref, ...rest } = register?.(name, { ...validations }) ?? {};

  return (
    <label htmlFor={name} tw="space-y-1">
      <span tw="font-semibold">{label}</span>
      <br />
      <BaseSelect id={name} tw="w-full" {...selectProps} {...rest} ref={ref}>
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option key={`Option.${option.value}`} value={option.value}>
            {option.text}
          </option>
        ))}
      </BaseSelect>
    </label>
  );
};

export default Select;
