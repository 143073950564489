import { useEffect } from 'react';
import tw from 'twin.macro';

import { useNavigate, useParams } from 'react-router-dom';

import { Firebase } from '@/libs';
import { useAuthContext } from '@/providers/Auth';
import { AUTH_STATE } from '@/providers/types';

const LoginWithToken = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { authState, signInWithCustomToken } = useAuthContext();

  const loginWithCustomToken = async () => {
    if (!id) {
      alert('ID not provided');
      return;
    }
    try {
      const token = await Firebase.functions.getCustomToken(id);
      if (token) {
        await signInWithCustomToken(token);
        setTimeout(() => {
          navigate(`/interviewee/home`);
        }, 200);
      }
    } catch (error) {
      navigate('/not-found');
    }
  };

  useEffect(() => {
    if (authState === AUTH_STATE.LOGGED_IN) {
      navigate(`/interviewee/home`);
    }
    loginWithCustomToken();
    return () => {};
  }, []);

  return <div tw="grid h-screen w-screen place-items-center text-2xl">Loading...</div>;
};

export default LoginWithToken;
