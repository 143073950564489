import tw from 'twin.macro';

import Snackbar from '@/components/Snackbar';
import { VideoUploadStatus } from '@/libs/graphql';
import { StatusMessages } from '@/utils/useMediaRecorder';

type InterviewSnackbarContainerProps = {
  current: number;
  status?: StatusMessages;
  uploadProgress?: number;
  videoUploadStatus?: VideoUploadStatus;
};

const RecordingIcon = () => (
  <span tw="flex h-4 w-4 items-center justify-center">
    <span tw="absolute inline-flex h-4 w-4 animate-ping rounded-full bg-danger-400 opacity-75"></span>
    <span tw="relative inline-flex h-4 w-4 rounded-full bg-danger-500"></span>
  </span>
);

const CircularProgress = ({ value }: { value: number }) => {
  const radius = 8;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (value / 100) * circumference;
  return (
    <svg tw="h-5 w-5 -rotate-90 ease-linear">
      <circle
        tw="stroke-info-500 stroke-2 [stroke-linecap: round]"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        stroke="currentColor"
        fill="transparent"
        r={radius}
        cx={10}
        cy={10}
      />
    </svg>
  );
};

const InterviewSnackbarContainer = (props: InterviewSnackbarContainerProps) => {
  const { current, status, uploadProgress, videoUploadStatus } = props;
  if (
    (status === StatusMessages.STOPPED &&
      (typeof uploadProgress === 'undefined' || uploadProgress === 100)) ||
    videoUploadStatus === VideoUploadStatus.Uploaded
  ) {
    return <Snackbar text="บันทึกวิดีโอเรียบร้อย" variant="success" />;
  } else if (
    status &&
    [StatusMessages.STOPPED, StatusMessages.STOPPING].includes(status) &&
    typeof uploadProgress === 'number'
  ) {
    return (
      <Snackbar
        text="กำลังบันทึกวิดีโอ ..."
        variant="info"
        icon={<CircularProgress value={uploadProgress} />}
      />
    );
  } else if (status === StatusMessages.RECORDING) {
    return <Snackbar text="กำลังอัดวิดีโอ ..." variant="warning" icon={<RecordingIcon />} />;
  } else if (
    status &&
    [StatusMessages.INIT_FAILED, StatusMessages.PERMISSION_DENIED].includes(status)
  ) {
    return <Snackbar text="เกิดข้อผิดพลาดขึ้น" variant="error" />;
  }
  const display = current === 0 ? ' (คำถามข้อที่ 0 ไม่มีผลต่อการตัดสิน)' : `ข้อที่ ${current}`;

  return (
    <Snackbar
      text={`คุณกำลังอยู่ในโหมดการเตรียมตัว มีเวลา 2 นาทีก่อนเริ่มบันทึกการสัมภาษณ์${display}`}
      variant="info"
    />
  );
};

export { CircularProgress };

export default InterviewSnackbarContainer;
