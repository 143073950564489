import { useEffect, useRef } from 'react';
import tw from 'twin.macro';

import Video from '@/components/Video';
import { Button } from '@/components/base';
import { useToggle } from '@/utils/useToggle';

type IntervieweeVideoContainerProps = {
  src?: string;
  controls?: boolean;
  fullWidth?: boolean;
};

const VideoPlaybackSpeed = {
  NORMAL: 1,
  FAST: 1.5,
  SUPER_FAST: 2,
};

const IntervieweeVideoContainer: React.FC<IntervieweeVideoContainerProps> = (props) => {
  const { src, controls = true, fullWidth } = props;
  const videoRef = useRef<HTMLVideoElement>(null);

  const [playbackSpeed, togglePlaybackSpeed] = useToggle<number>([
    VideoPlaybackSpeed.NORMAL,
    VideoPlaybackSpeed.FAST,
    VideoPlaybackSpeed.SUPER_FAST,
  ]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackSpeed;
    }
  }, [playbackSpeed]);

  if (!src) {
    return (
      <div tw="space-y-2">
        <div tw="flex w-full justify-center">ไม่พบข้อมูลวิดิโอ</div>
      </div>
    );
  }

  return (
    <div tw="gap-2 flex flex-col items-start content-center">
      <Video ref={videoRef} src={src} controls={controls} controlsList='nodownload' fullWidth={fullWidth} />
      <div tw="flex w-full justify-center">
        <Button onClick={() => togglePlaybackSpeed()}>
          {playbackSpeed === VideoPlaybackSpeed.NORMAL && '1x'}
          {playbackSpeed === VideoPlaybackSpeed.FAST && '1.5x'}
          {playbackSpeed === VideoPlaybackSpeed.SUPER_FAST && '2x'}
        </Button>
      </div>
    </div>
  );
};

export default IntervieweeVideoContainer;
