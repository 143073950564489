import React, { useEffect, useRef, useState } from 'react';
import tw, { styled } from 'twin.macro';

import { CheckCircleIcon } from '@heroicons/react/24/solid';

import Card from '@/components/Card';
import { VideoUploadStatus } from '@/libs/graphql';
import { toMMSS } from '@/utils/getTimeFormat';
import { StatusMessages } from '@/utils/useMediaRecorder';

import CircularProgress from './components/CircularProgress';
import RecordingIcon from './components/RecordingIcon';
import TimerText from './components/TimerText';

type InterviewSessionCardProps = {
  isInterviewSession?: boolean;
  duration: number;
  status?: StatusMessages;
  startRecording: () => Promise<void>;
  currentQuestion: number;
  isLastQuestion?: boolean;
  videoUploadStatus?: VideoUploadStatus;
  uploadProgress?: number;
  startQuestion?: (time: number) => Promise<void>;
};

const preparationDuration = 30;

const InterviewSessionCard: React.FC<InterviewSessionCardProps> = (props) => {
  const {
    currentQuestion,
    duration,
    startRecording,
    status,
    videoUploadStatus,
    uploadProgress,
    isInterviewSession = false,
    startQuestion,
  } = props;
  const [time, setTime] = useState<number>(preparationDuration);

  useEffect(() => {
    let interval = setInterval(() => {
      if (status === StatusMessages.IDLE || status === StatusMessages.RECORDING) {
        setTime((prev) => {
          const next = prev - 1;
          if (next === 0) {
            clearInterval(interval);
          }
          return next;
        });
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [status, currentQuestion]);

  useEffect(() => {
    if (isInterviewSession && status === StatusMessages.RECORDING) {
      if (time === duration) {
        startQuestion?.(preparationDuration);
      } else {
        startQuestion?.(preparationDuration - time);
      }
    }
  }, [status, currentQuestion]);

  useEffect(() => {
    if (status === StatusMessages.INITIALIZING) {
      setTime(preparationDuration);
    } else if (status === StatusMessages.RECORDING) {
      setTime(duration);
    }
  }, [status, currentQuestion]);

  useEffect(() => {
    if (
      time === 0 &&
      status === StatusMessages.IDLE &&
      (currentQuestion === 0 || videoUploadStatus === VideoUploadStatus.NotStarted)
    ) {
      startRecording();
      setTime(duration);
    }
  }, [time, status]);
  if (
    status &&
    [StatusMessages.IDLE, StatusMessages.INITIALIZING, StatusMessages.RECORDING].includes(status) &&
    videoUploadStatus !== VideoUploadStatus.Uploaded
  ) {
    return (
      <>
        {status === StatusMessages.RECORDING ? (
          <Card
            description={
              <div tw="flex flex-col items-center justify-between sm:flex-row">
                <h6>ช่วงสัมภาษณ์</h6>
                <div tw="flex items-center space-x-4">
                  <RecordingIcon />
                  <h5>กำลังอัดวิดีโอ</h5>
                </div>
              </div>
            }
          />
        ) : (
          <Card
            description={
              <div tw="space-y-4">
                <h6>ช่วงเตรียมตัว</h6>
                <p tw="font-jamjuree">
                  คุณกำลังอยู่ในช่วงเตรียมตัว{' '}
                  {currentQuestion === 0 && 'ทุกคำถามจะมีเวลาเตรียมตัว 30 วินาที '}
                  หลังจากหมดเวลาเตรียมตัว หรือกดปุ่ม “เริ่มตอบคำถาม”
                  ระบบจะเริ่มทำการบันทึกวิดีโอทันที
                </p>
              </div>
            }
          />
        )}
        <Card
          tw="flex h-fit flex-col items-center justify-center gap-4"
          description={
            <>
              <p tw="font-semibold text-lg">
                {status === StatusMessages.RECORDING
                  ? 'หมดเวลาตอบคำถามในอีก'
                  : 'เหลือเวลาเตรียมตัวอีก'}
              </p>
              <TimerText time={time}>{toMMSS(time)}</TimerText>
            </>
          }
        />
      </>
    );
  } else if (
    status &&
    [StatusMessages.INIT_FAILED, StatusMessages.PERMISSION_DENIED].includes(status) &&
    videoUploadStatus !== VideoUploadStatus.Uploaded
  ) {
    return (
      <Card
        tw="flex items-center justify-center"
        title="เกิดข้อผิดพลาดขึ้น"
        description={`กรุณาให้สิทธ์การเข้าถึงกล้องและไมโครโฟนเพื่อดำเนินการสัมภาษณ์ต่อ`}
      />
    );
  }
  return (
    <Card
      tw="flex h-fit"
      description={
        <div
          css={[
            tw`flex flex-col items-center justify-center gap-4 sm:(flex-row justify-between)`,
            currentQuestion === 0 && tw`items-start sm:flex-col`,
          ]}
        >
          <h6>จบการตอบคำถาม {currentQuestion !== 0 && `ข้อที่ ${currentQuestion}`}</h6>
          {currentQuestion === 0 ? (
            <p tw="font-jamjuree">
              กรุณาตรวจสอบวิดีโอของคุณ หากพบปัญหาด้าน อุปกรณ์การบันทึกภาพหรือเสียง กรุณาแก้ไข
              อุปกรณ์ของคุณให้พร้อมก่อนการเริ่มสัมภาษณ์จริง แล้ว “ทดสอบระบบอีกครั้ง” ก่อน <br />
              <br /> หากคุณพร้อมแล้ว สามารถกดปุ่ม “เริ่มการสัมภาษณ์จริง” ได้เลย!
            </p>
          ) : (
            <div tw="flex items-center gap-4">
              {videoUploadStatus === VideoUploadStatus.Uploaded ? (
                <>
                  <CheckCircleIcon tw="h-6 w-6 text-success-500" />
                  <h5>บันทึกวิดีโอเรียบร้อย</h5>
                </>
              ) : (
                <>
                  <CircularProgress value={uploadProgress as number} />
                  <h5>กำลังบันทึกวิดีโอ</h5>
                </>
              )}
            </div>
          )}
        </div>
      }
    />
  );
};

export default InterviewSessionCard;
