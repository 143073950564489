type ConfigValue = string | null | undefined | boolean;

const isDev = import.meta.env.DEV;

export const resolveArray = (value: ConfigValue, defaultValue: string[]): string[] => {
  if (typeof value === 'string') {
    const splitted = value.split(',').map((i) => i.trim());
    if (splitted.includes(''))
      throw new Error(`Invalid Array configuration: ${value}; allow only non empty or whitespace`);
    return splitted;
  } else if (value === null || value === undefined) return defaultValue;
  throw new Error(`Invalid Array configuration: ${value}`);
};

export const resolveBoolean = (value: ConfigValue, defaultValue = false): boolean => {
  if (value === null || value === undefined) return defaultValue;
  if (value === true || value === 'true' || value === 'TRUE') return true;
  if (value === false || value === 'false' || value === 'FALSE') return false;
  throw new Error(`Invalid Boolean configuration: ${value}`);
};

export const resolveInteger = (value: ConfigValue, defaultValue = 0): number => {
  if (typeof value === 'string' && /^(-)?\d+$/.test(value)) {
    return parseInt(value, 10);
  } else if (typeof value === 'number') return value;
  else if (value === null || value === undefined) return defaultValue;
  throw new Error(`Invalid Integer configuration: ${value}`);
};

export const resolveJSON = (path: string): Record<string, unknown> => {
  try {
    return require(path);
  } catch (err) {
    return {};
  }
};

const getConfig = () => ({
  isDev,
  firebaseApiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  firebaseAuthDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  firebaseProjectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  firebaseStorageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  firebaseMessagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  firebaseAppId: import.meta.env.VITE_FIREBASE_APP_ID,
  firebaseMeasurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  baseUrl: import.meta.env.VITE_BASE_URL || 'https://skooldio-video-interview.web.app/',
  baseApiUrl:
    import.meta.env.VITE_BASE_API_URL ||
    'https://asia-southeast1-skooldio-video-interview.cloudfunctions.net/graphql',
  enableUploadVideo: resolveBoolean(import.meta.env.VITE_ENABLE_UPLOAD_VIDEO),
  helpCenterUrl: import.meta.env.VITE_HELP_CENTER_URL,
  batchInterviewCreationTemplateUrl: import.meta.env.VITE_BATCH_INTERVIEW_CREATION_TEMPLATE_URL,
  extendExpiryDate: resolveInteger(import.meta.env.VITE_EXTEND_EXPIRY_DATE, 3),
  privacyPolicyUrl: import.meta.env.VITE_PRIVACY_POLICY_URL,
  privacyPolicyMdUrl: import.meta.env.VITE_PRIVACY_POLICY_MD_URL || '/md/privacy.md',
  theme: {
    logoUrl: import.meta.env.VITE_THEME_LOGO_URL || '/theme/default/logo.svg',
    i18nLoadPath: import.meta.env.VITE_THEME_I18N_LOAD_PATH || '/locales/{{lng}}/{{ns}}.json',
  },
});

const config = getConfig();

export default config;
