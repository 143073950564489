import { SessionStatus } from '@/libs/graphql';

export const getSessionStatusText = (
  status: SessionStatus,
  availableUntil: Date | null,
  expiredAt: Date,
) => {
  const now = new Date();

  if (
    (status === SessionStatus.NotStarted || status === SessionStatus.InProgress) &&
    ((availableUntil && now > availableUntil) || now > expiredAt)
  ) {
    return 'เลยเวลาสัมภาษณ์';
  } else if (status === SessionStatus.NotStarted) {
    return 'ยังไม่เริ่มสัมภาษณ์';
  } else if (status === SessionStatus.InProgress) {
    return 'กำลังสัมภาษณ์';
  } else {
    return 'สัมภาษณ์เสร็จแล้ว';
  }
};
