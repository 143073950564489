import tw from 'twin.macro';

type CardSkeletonProps = {
  lines?: number;
};

const Container = tw.div`flex w-full animate-pulse flex-col rounded-3xl bg-white p-6`;

const CardTitleSkeleton = tw.div`mb-4 h-8 w-2/3 rounded-md bg-slate-200 md:w-1/3`;

const CardDescriptionSkeleton = tw.div`my-0.5 h-5 w-full rounded-md bg-slate-200 md:w-2/3`;

const CardSkeleton: React.FC<CardSkeletonProps> = ({ lines = 2, ...props }) => (
  <Container {...props}>
    <CardTitleSkeleton />
    {[...Array(lines)].map((_, index) => (
      <CardDescriptionSkeleton key={index} />
    ))}
  </Container>
);

export default CardSkeleton;
