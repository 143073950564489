import 'twin.macro';

import clsx from 'clsx';
import Highlighter from 'react-highlight-words';

// const tooltipStyles = [tw`bg-amber-50 rounded-2xl p-2 w-full`];

const HighlightContainer = ({
  text,
  highlights,
}: {
  text: string;
  highlights: Array<{ sentence: string; reason: string }>;
}) => {
  // Extract sentences for highlighting from the highlights prop
  const searchWords = highlights.map((h) => h.sentence);

  // Custom component for rendering highlighted words with tooltips
  const CustomHighlight = ({
    children,
    className,
    style,
  }: {
    children: string;
    className: string;
    style: any;
  }) => {
    const idx = highlights.findIndex((h) => h.sentence === children);
    console.log('idx', idx);
    if (idx === -1) console.log('children', children);
    const dataKey = `highlight-${idx}`;
    const tooltipText = highlights[idx]?.reason || '';
    return (
      <div
        key={dataKey}
        data-tip={tooltipText}
        style={style}
        className={clsx(className, 'highlight', 'group')}
        tw="relative font-bold inline flex-col items-center cursor-help"
      >
        {children}
        <div tw="transition-opacity absolute lg:bottom-[-20px] flex flex-col items-center opacity-0 pointer-events-none mt-6 group-hover:flex group-hover:opacity-100 z-10">
          <div tw="w-3 h-3 -mb-2 rotate-45 bg-black"></div>
          <span tw="relative z-10 p-2 text-xs text-white whitespace-pre-wrap bg-black shadow-lg">
            {tooltipText}
          </span>
        </div>
      </div>
    );
  };
  // const CustomHighlight = ({
  //   children,
  //   className,
  //   style,
  // }: {
  //   children: string;
  //   className: string;
  //   style: any;
  // }) => {
  //   const idx = highlights.findIndex((h) => h.sentence === children);
  //   console.log('idx', idx);
  //   if (idx === -1) console.log('children', children);
  //   const dataKey = `highlight-${idx}`;
  //   const tooltipText = highlights[idx]?.reason || '';
  //   return (
  //     <span
  //       key={dataKey}
  //       style={style}
  //       data-tip={tooltipText}
  //       className={clsx(className, 'highlight', 'group')}
  //       tw="font-bold relative"
  //       title={tooltipText}
  //     >
  //       {children}
  //       <span tw="invisible hidden absolute top-[-50%] left-[-50%] text-[12px] p-3 rounded-2xl z-10 bg-red-100 group-hover:visible group-hover:block group-hover:w-full">
  //         {tooltipText}
  //       </span>
  //     </span>
  //   );
  // };

  return (
    <Highlighter
      searchWords={searchWords}
      textToHighlight={text}
      highlightTag={CustomHighlight} // Using the custom component to render highlights
    />
  );
};

export default HighlightContainer;
