import React from 'react';
import tw from 'twin.macro';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function Markdown({ text }: { text: string }) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className="prose"
      children={text}
      components={{
        h1({ node, className, children, ...props }) {
          return (
            <h1 {...props} className={className} tw="text-xl mt-2 mb-3">
              {children}
            </h1>
          );
        },
        h2({ node, className, children, ...props }) {
          return (
            <h1 {...props} className={className} tw="text-lg mt-5 mb-3">
              {children}
            </h1>
          );
        },
        p({ node, className, children, ...props }) {
          return (
            <p {...props} className={className} tw="mt-2">
              {children}
            </p>
          );
        },
      }}
    />
  );
}
