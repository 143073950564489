import tw, { GlobalStyles as BaseStyles, theme } from 'twin.macro';

import { createGlobalStyle } from 'styled-components';

const CustomStyles = createGlobalStyle({
  body: {
    WebkitTapHighlightColor: theme`colors.transparent`,
    ...tw`bg-bg text-black antialiased`,
  },
  h1: {
    ...tw`font-semibold text-6xl`,
  },
  h2: {
    ...tw`font-semibold text-5xl`,
  },
  h3: {
    ...tw`font-semibold text-[34px]`,
  },
  h4: {
    ...tw`font-semibold text-3xl`,
  },
  h5: {
    ...tw`font-semibold text-2xl`,
  },
  h6: {
    ...tw`font-semibold text-xl`,
  },
  li: {
    ...tw`list-none`,
  },
});

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
