import tw from 'twin.macro';

import { Input } from '../base';
import { FormElementProps } from './types';

interface CheckboxProps extends FormElementProps<HTMLInputElement> {}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const { label, name, register, validations, ...checkboxProps } = props;
  const { ref, ...rest } = register?.(name, { ...validations }) ?? {};

  return (
    <label htmlFor={name} tw="flex items-center gap-2">
      <Input
        ref={ref}
        id={name}
        type="checkbox"
        tw="w-fit accent-primary"
        {...checkboxProps}
        {...rest}
      />
      <span tw="font-medium">{label}</span>
    </label>
  );
};

export default Checkbox;
