import tw from 'twin.macro';

type SessionDataTableSkeletonProps = {
  lines?: number;
};

const SessionDataTableSkeleton: React.FC<SessionDataTableSkeletonProps> = ({ lines = 2 }) => {
  return (
    <div tw="overflow-auto rounded-lg border-2 bg-white px-4">
      <div tw="flex animate-pulse flex-col">
        <div tw="flex h-7 w-full items-center justify-around border-b-2">
          <div tw="h-2 w-1/6 rounded-xl bg-slate-300" />
          <div tw="h-2 w-1/6 rounded-xl bg-slate-300" />
          <div tw="h-2 w-1/6 rounded-xl bg-slate-300" />
          <div tw="h-2 w-1/6 rounded-xl bg-slate-300" />
          <div tw="h-2 w-1/12 rounded-xl bg-slate-300" />
          <div tw="h-2 w-1/12 rounded-xl bg-slate-300" />
          <div tw="h-2 w-1/12 rounded-xl bg-slate-300" />
        </div>
        <div tw="flex w-full flex-col">
          {[...Array(lines).keys()].map((line) => (
            <div
              key={`SessionDataTableSkeleton.${line}`}
              tw="flex h-7 w-full items-center justify-around border-b"
            >
              <div tw="h-2 w-1/6 rounded-xl bg-slate-200" />
              <div tw="h-2 w-1/6 rounded-xl bg-slate-200" />
              <div tw="h-2 w-1/6 rounded-xl bg-slate-200" />
              <div tw="h-2 w-1/6 rounded-xl bg-slate-200" />
              <div tw="h-2 w-1/12 rounded-xl bg-slate-200" />
              <div tw="h-2 w-1/12 rounded-xl bg-slate-200" />
              <div tw="h-2 w-1/12 rounded-xl bg-slate-200" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SessionDataTableSkeleton;
