import { ChangeEvent, useRef, useState } from 'react';
import tw from 'twin.macro';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ClipboardCopyInput from '@/components/ClipboardCopyInput';
import { Button, Modal, Select } from '@/components/base';
import {
  useCreateBatchInterviewSessionMutation,
  useCreateSessionMutation,
} from '@/domains/session';
import { useGetActiveTemplatesQuery } from '@/domains/template';
import { BatchIntervieweeInput } from '@/libs/graphql';
import config from '@/utils/config';

import CreateSessionForm from '../components/CreateSessionForm';
import CreateSessionFormSkeleton from '../components/CreateSessionFormSkeleton';
import IntervieweeDetails from '../components/IntervieweeDetails';
import { BATCH_INTERVIEW_FORM, SINGLE_INTERVIEW_FORM } from '../constants';
import { CreateSessionMode, FormInputType, FormValues, ModalState } from '../types';
import { convertIntervieweeDetails, convertIntervieweeDetails2 } from '../utils';

const { baseUrl } = config;

const CreateSessionFormContainer = () => {
  const formData = useRef<FormValues>();
  const intervieweeRef = useRef<BatchIntervieweeInput[]>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const [url, setUrl] = useState<string>();
  const [createMode, setCreateMode] = useState<CreateSessionMode>(CreateSessionMode.SINGLE);
  const [modalState, setModalState] = useState<ModalState>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    data: activeTemplatesData,
    loading: activeTemplatesLoading,
    error: activeTemplatesError,
  } = useGetActiveTemplatesQuery();

  const [
    createSession,
    { data: createdSessionData, loading: createdSessionLoading, error: createdSessionError },
  ] = useCreateSessionMutation({
    fetchPolicy: 'network-only',
    onCompleted(data, clientOptions) {
      if (data.createSession.__typename === 'CreateSessionResultSuccess') {
        setModalState(ModalState.SUCCESS_SINGLE);
        setUrl(`${baseUrl}/interviewee/login/${data.createSession.createdSession?.id}`);
      }
    },
    onError(e) {
      setModalState(ModalState.ERROR_SINGLE);
      console.error(e);
    },
  });

  const [
    createBatchInterviewSession,
    {
      data: createBatchInterviewSessionData,
      loading: createBatchInterviewSessionLoading,
      error: createBatchInterviewSessionError,
    },
  ] = useCreateBatchInterviewSessionMutation({
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.createBatchInterviewSession.__typename === 'CreateBatchSessionResultSuccess') {
        setModalState(ModalState.SUCCESS_BATCH);
      }
    },
  });

  const templates = activeTemplatesData?.getActiveTemplates;

  const modifiedTemplates = templates?.map((template) => ({
    id: template.id,
    value: template.id,
    text: template.name,
  }));

  const onSubmit = async () => {
    const data = formData.current;
    if (!data) {
      return;
    }
    if (createMode == CreateSessionMode.SINGLE) {
      const { intervieweeDetails, ...input } = data;
      // remove empty string in input
      Object.keys(input).forEach(
        (key) => input[key as keyof typeof input] === '' && delete input[key as keyof typeof input],
      );
      await createSession({
        variables: {
          createSessionInput: {
            ...input,
            company: 'Skooldio',
            expiredAt: new Date(data.expiredAt),
          },
        },
      });
    } else {
      createBatchInterviewSession({
        variables: {
          input: {
            questionTemplateID: data.questionTemplateID,
            duration: data.duration,
            interviewees: intervieweeRef.current as BatchIntervieweeInput[],
            expiredAt: new Date(data.expiredAt),
            shouldSendEmailToInterviewees: data.shouldSendEmailToInterviewee,
          },
        },
      });
    }

    setReset(true);
  };

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const convertFilesToInterviewees = (file: Blob) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      const interviewees = await convertIntervieweeDetails2(reader.result?.toString() as string);
      intervieweeRef.current = interviewees;
    };
    reader.readAsText(file, 'UTF-8');
  };

  const handleSubmitForm = (data: FormValues) => {
    formData.current = data;
    setReset(false);
    toggleIsOpen();
    if (createMode === CreateSessionMode.SINGLE) {
      setModalState(ModalState.CONFIRM_SINGLE);
    } else {
      if (data.intervieweeDetails?.length > 0) {
        convertFilesToInterviewees(data.intervieweeDetails[0] as unknown as Blob);
      }
      setModalState(ModalState.CONFIRM_BATCH);
    }
  };

  const navigateToDashboard = () => navigate('/backoffice/dashboard');

  const handleChangeCreateMode = (e: ChangeEvent<HTMLSelectElement>) => {
    setCreateMode(e.target.value as CreateSessionMode);
  };

  const modalContent = {
    [ModalState.CONFIRM_SINGLE]: {
      loading: createdSessionLoading,
      title: 'ต้องการสร้างการสัมภาษณ์?',
      description: (
        <IntervieweeDetails
          {...{
            ...formData.current,
            questionTemplateID:
              templates?.find((template) => template.id === formData.current?.questionTemplateID)
                ?.name ?? '',
          }}
        />
      ),
      primaryButtonAction: onSubmit,
      primaryButtonText: 'สร้างการสัมภาษณ์',
      secondaryButtonAction: toggleIsOpen,
      secondaryButtonText: 'ยกเลิก',
    },
    [ModalState.SUCCESS_SINGLE]: {
      title: 'สร้างการสัมภาษณ์เรียบร้อย',
      description: (
        <div tw="flex flex-col gap-2">
          <p>คัดลอกลิงก์และส่งให้ผู้สมัครได้เลย</p>
          <ClipboardCopyInput url={url} />
        </div>
      ),
      footer: (
        <div tw="flex flex-col gap-3 bg-gray-50 px-4 py-3 sm:(flex-row-reverse px-6)">
          <Button onClick={navigateToDashboard}>กลับไปหน้าแดชบอร์ด</Button>
          <Button
            variant="info"
            onClick={() => {
              toggleIsOpen();
              setReset(false);
              setUrl('');
            }}
          >
            สร้างการสัมภาษณ์ใหม่
          </Button>
        </div>
      ),
    },
    [ModalState.CONFIRM_BATCH]: {
      loading: createBatchInterviewSessionLoading,
      title: 'ต้องการสร้างการสัมภาษณ์สำหรับหลายคน?',
      description: 'กำลังจะสร้างการสัมภาษณ์ให้กับผู้สัมภาษณ์หลาย ๆ คน',
      primaryButtonAction: onSubmit,
      primaryButtonText: 'สร้างการสัมภาษณ์',
      secondaryButtonAction: toggleIsOpen,
      secondaryButtonText: 'ยกเลิก',
    },
    [ModalState.SUCCESS_BATCH]: {
      title: 'สร้างการสัมภาษณ์หลายคนเรียบร้อย',
      description: 'สามารถกดกลับหน้าแดชบอร์ดได้เลย',
      footer: (
        <div tw="flex flex-col gap-3 bg-gray-50 px-4 py-3 sm:(flex-row-reverse px-6)">
          <Button onClick={navigateToDashboard}>กลับไปหน้าแดชบอร์ด</Button>
          <Button
            variant="info"
            onClick={() => {
              toggleIsOpen();
              setReset(false);
            }}
          >
            สร้างการสัมภาษณ์ใหม่
          </Button>
        </div>
      ),
    },
    [ModalState.ERROR_SINGLE]: {
      title: 'สร้างการสัมภาษณ์ไม่สำเร็จ',
      description: 'เกิดข้อผิดพลาดขึ้น กรุณาลองใหม่อีกครั้ง',
      footer: (
        <div tw="flex flex-col gap-3 bg-gray-50 px-4 py-3 sm:(flex-row-reverse px-6)">
          <Button variant="error" onClick={onSubmit} disabled={createdSessionLoading}>
            ลองใหม่อีกครั้ง
          </Button>
          <Button variant="info" onClick={toggleIsOpen}>
            ปิดหน้าต่างนี้
          </Button>
        </div>
      ),
    },
  };

  const FIELDS = [
    ...(createMode === CreateSessionMode.SINGLE
      ? SINGLE_INTERVIEW_FORM(t)
      : BATCH_INTERVIEW_FORM(t)),
    {
      id: 'questionTemplateID',
      name: 'questionTemplateID',
      label: 'ชุดคำถาม',
      placeholder: 'กรุณาเลือกชุดคำถาม',
      required: true,
      type: FormInputType.SELECT,
      options: modifiedTemplates,
    },
    {
      id: 'expiredAt',
      name: 'expiredAt',
      label: 'สัมภาษณ์ได้ถึง',
      placeholder: 'กรุณากรอกวันที่',
      required: true,
      type: FormInputType.DATE,
    },
    {
      id: 'duration',
      name: 'duration',
      label: 'ต้องสัมภาษณ์ให้เสร็จภายใน (ชม.)',
      placeholder: 'กรอกตัวเลข เช่น 2 3',
      required: true,
      type: FormInputType.NUMBER,
      validation: { valueAsNumber: true },
    },
    {
      id: 'shouldSendEmailToInterviewee',
      name: 'shouldSendEmailToInterviewee',
      label: 'ส่งอีเมลแจ้งผู้สมัคร',
      placeholder: '',
      required: false,
      type: FormInputType.CHECKBOX,
    },
  ];

  return (
    <>
      <div tw="mb-2 flex items-center gap-4">
        <h5>สร้างการสัมภาษณ์ใหม่</h5>
        <Select onChange={handleChangeCreateMode} disabled={activeTemplatesLoading}>
          <option value={CreateSessionMode.SINGLE}>คนเดียว</option>
          <option value={CreateSessionMode.BATCH}>หลายคน</option>
        </Select>
      </div>
      {isOpen ? <Modal {...(modalState ? modalContent[modalState] : { title: '' })} /> : null}
      {activeTemplatesLoading ? (
        <CreateSessionFormSkeleton lines={7} />
      ) : (
        <CreateSessionForm
          fields={FIELDS}
          handleSubmitForm={handleSubmitForm}
          shouldResetForm={reset}
        />
      )}
    </>
  );
};

export default CreateSessionFormContainer;
