import tw from 'twin.macro';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, MicrophoneIcon } from '@heroicons/react/24/outline';

import type { SelectInput } from '@/utils/useGetMediaDevices';

type InputSelectProps = {
  onChange: (value: string) => void;
  value?: string;
  availableInputs: SelectInput[];
  containerStyle?: string;
  icon: JSX.Element;
};

const InputSelect: React.FC<InputSelectProps> = ({
  value,
  onChange,
  availableInputs,
  containerStyle,
  icon,
}) => {
  return (
    <div css={['relative', containerStyle]}>
      <Listbox value={value} onChange={onChange}>
        <div tw="flex flex-col xl:flex-row justify-center xl:items-center gap-1">
          <Listbox.Label tw="block text-sm font-medium text-gray-700 shrink-0">
            {icon}
          </Listbox.Label>
          <Listbox.Button tw="relative cursor-default rounded-lg bg-white py-1 pl-3 pr-10 text-left border-2 focus:outline-none text-sm">
            <span tw="line-clamp-1 break-all">
              {availableInputs.find((i) => i.id === value)?.name ?? 'Default'}
            </span>
            <span tw="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon tw="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
        </div>
        <Transition
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options tw="absolute my-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base border-2 border-gray-100 focus:outline-none sm:text-sm">
            {availableInputs.map((input) => (
              <Listbox.Option
                key={input.id}
                value={input.value}
                tw="relative cursor-default select-none py-2 pl-10 pr-4"
              >
                {({ selected }) => (
                  <>
                    <span css={['block truncate', selected ? 'font-medium' : 'font-normal']}>
                      {input.name}
                    </span>
                    {selected ? (
                      <span tw="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                        <CheckIcon tw="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default InputSelect;
