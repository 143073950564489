export const SIDEBAR_CONTENT = [
  {
    text: 'หน้าแรก',
    path: '/interviewee/home',
  },
  {
    text: 'ทดสอบอุปกรณ์',
    path: '/interviewee/preparation',
  },
  {
    text: 'เริ่มสัมภาษณ์',
    path: '/interviewee/session/:id',
  },
  {
    text: 'จบการสัมภาษณ์',
    path: '/interviewee/completed',
  },
];
