import dayjs from 'dayjs';

import { BackofficeSession } from '@/libs/graphql';
import config from '@/utils/config';

const { baseUrl } = config;

export const convertIntervieweeInfo = (session?: Partial<BackofficeSession>) => {
  const id = session?.id;
  const { accessToken, accessTokenExpiresAt, availableUntil, expiredAt } = session ?? {};
  return {
    id: session?.id ?? '-',
    intervieweeName: session?.intervieweeName ?? '',
    intervieweeEmail: session?.intervieweeEmail ?? '',
    refId: session?.refId ?? undefined,
    refUrl: session?.refUrl ?? undefined,
    position: session?.position ?? '',
    company: session?.position ?? '',
    expiredAt: expiredAt ? dayjs(expiredAt).format('YYYY/MM/DD HH:mm') : '-',
    status: session?.status,
    availableUntil: availableUntil ? dayjs(availableUntil).format('YYYY/MM/DD HH:mm') : null,
    intervieweeUrl: `${baseUrl}/interviewee/login/${id}`,
    backofficeInterviewerUrl: `${baseUrl}/backoffice/interviewee/${id}`,
    viewerUrl: accessToken ? `${baseUrl}/result/interviewee/${id}?token=${accessToken}` : undefined,
    viewerUrlExpiredAt: accessTokenExpiresAt
      ? dayjs(accessTokenExpiresAt).format('YYYY/MM/DD HH:mm')
      : undefined,
  };
};
