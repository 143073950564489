import { useState } from 'react';
import tw from 'twin.macro';

import { CheckIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';

import { Button, Input } from './base';

type ClipboardCopyInputProps = {
  url?: string;
};

const ClipboardCopyInput: React.FC<ClipboardCopyInputProps> = (props) => {
  const { url } = props;

  const [copied, setCopied] = useState<boolean>(false);

  const handlecopyToClipboard = () => {
    if (!url) return;
    setCopied(true);
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div tw="flex flex-nowrap">
      <Input tw="w-full rounded-r-none border bg-gray-50 py-2 px-3" value={url} readOnly />
      <Button
        css={[
          tw`flex items-center gap-1 whitespace-nowrap rounded-l-none py-2 px-3`,
          copied && tw`cursor-default bg-success-500 hover:bg-success-500`,
        ]}
        onClick={handlecopyToClipboard}
      >
        {copied ? (
          <>
            <CheckIcon tw="h-5 w-5" />
            <span>คัดลอกแล้ว</span>
          </>
        ) : (
          <>
            <DocumentDuplicateIcon tw="h-5 w-5" />
            <span>คัดลอก</span>
          </>
        )}
      </Button>
    </div>
  );
};

export default ClipboardCopyInput;
