import tw from 'twin.macro';

import Card from '@/components/Card';

import CreateSessionFormContainer from './containers/CreateSessionFormContainer';

const CreateSession = () => {
  return (
    <div tw="m-auto flex min-h-screen max-w-screen-xl flex-col space-y-4 px-4 py-6 sm:(flex-row justify-center) lg:(px-20 py-14)">
      <Card tw="h-fit w-full lg:w-1/2" description={<CreateSessionFormContainer />} />
    </div>
  );
};

export default CreateSession;
