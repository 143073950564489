export enum AUTH_STATE {
  INITIALIZING = 'INITIALIZING',
  LOGGED_IN = 'LOGGED_IN',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
  LOGGING_IN = 'LOGGING_IN',
}

export enum AUTH_TYPE {
  BACKOFFICE = 'BACKOFFICE',
  INTERVIEWEE = 'INTERVIEWEE',
}
